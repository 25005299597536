import { ChangeEvent, FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { AnyObject } from '../../api/anyObjectTypes';
import FormField from '../FormField/FormField';
import FileUpload from '../FileUpload/FileUpload';
import localize from '../../localize';
import { useValidateFormRequiredFields } from '../../hooks/useValidateFormRequiredFields';
import { useMobile } from '../../hooks/useMobile';
import { getRandomHexSegment } from '../../utils/strings';

import { ReactComponent as InfoIconSvg } from '../../assets/info-circle.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete-item.svg';

const Container = styled('form')(({ theme }) => ({
  width: '100%',
}));

const FieldsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  background: '#FFFFFF',

  '&>div': {
    [theme.breakpoints.up('md')]: {
      width: '33%',
      minWidth: '200px',
    },
  },
}));

const SubItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',

  '& + &': {
    paddingTop: '20px',
  },
}));

const SubItemInnerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  flex: '1 1 auto',
  background: '#fff',
  borderRadius: '10px',
  padding: '30px',
  border: '1px solid #DFDDF9',
  [theme.breakpoints.down('md')]: {
    padding: '30px 10px',
  },

  '&>div': {
    [theme.breakpoints.up('md')]: {
      width: '33%',
      minWidth: '200px',
    },
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '20px',
  padding: '10px 50px 10px 10px',
  gap: '20px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '10px',
  },
}));

type ProductFormProps = {
  mainFields: AnyObject[];
  defaultMainValues?: AnyObject;
  defaultSubItemValues?: AnyObject[];
  subItemFields?: AnyObject[];
  submitting?: boolean;
  addModificationText?: string;
  onSubmit(mainItem: AnyObject, subItems: AnyObject[]): void;
};

export const ProductForm: FC<ProductFormProps> = ({
  submitting,
  mainFields,
  subItemFields = [],
  defaultMainValues = {},
  defaultSubItemValues = [{}],
  addModificationText,
  onSubmit,
}) => {
  const [mainItem, setMainItem] = useState(defaultMainValues);
  const [subItems, setSubItems] = useState(defaultSubItemValues);
  const mobile = useMobile();

  const isFormValid = useValidateFormRequiredFields(mainFields, mainItem);
  const isValidSubForm = useValidateFormRequiredFields(subItemFields, subItems);

  useEffect(() => {
    const staticMainFields = mainFields.filter(
      (field) => field.field_type === 'static'
    );
    const staticSubItemFields = subItemFields.filter(
      (field) => field.field_type === 'static'
    );

    if (staticMainFields?.length) {
      const resObj1: any = {};
      staticMainFields.forEach(
        (field) => (resObj1[field.internal_name] = field.default_value)
      );
      setMainItem({
        ...mainItem,
        ...resObj1,
      });
    }

    if (staticSubItemFields?.length) {
      const resObj2: any = {};
      staticSubItemFields.forEach(
        (field) => (resObj2[field.internal_name] = field.default_value)
      );
      setSubItems(
        JSON.parse(JSON.stringify(subItems)).map((item: any) => ({
          ...item,
          ...resObj2,
        }))
      );
    }
  }, [mainFields, subItems?.length, subItemFields]);

  const handleMainInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setMainItem({
      ...mainItem,
      [name]: value,
    });
  };

  const handleSubItemInputChange = (
    e: { target: { name: any; value: any } },
    itemIndex: number
  ) => {
    const { name, value } = e.target;
    const newSubItems = subItems.slice();
    newSubItems[itemIndex] = { ...newSubItems[itemIndex], [name]: value };
    setSubItems(newSubItems);
  };

  const onAddFileClick = (files: File[], name: string) => {
    if (files && files.length > 0) {
      const newFiles: AnyObject = {};
      Array.from(files).forEach((file: AnyObject) => {
        const apiNumber =
          getRandomHexSegment() + `.${file.name.split('.').slice(1).join('')}`;
        file.apiNumber = apiNumber;
        newFiles[file.name.split('.').shift()] = file;
      });

      handleMainInputChange({
        target: { name, value: files },
      });
    }
  };

  const deleteSubItem = (itemIndex: number) => {
    const newSubItems = subItems.slice();
    newSubItems[itemIndex]['_destroy'] = true;
    setSubItems(newSubItems);
  };

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(mainItem, subItems);
      }}
    >
      <Typography color='#0C0C0C' variant='h5'>
        {localize.products.form.requiredFields}
      </Typography>
      <FieldsContainer mt='20px' mb='10px'>
        {mainFields
          .filter((field) => field.required && field.field_type !== 'files')
          .map((mf) => (
            <FormField
              key={mf.internal_name}
              value={
                mainItem[mf.internal_name] === undefined
                  ? ''
                  : mainItem[mf.internal_name]
              }
              label={mf.label}
              name={mf.internal_name}
              onChange={handleMainInputChange}
              fieldType={mf.field_type}
              localizedField={mf.localized_field}
              options={mf.options}
              required={mf.required}
              disabled={!mf.editable}
            />
          ))}
      </FieldsContainer>
      {mainFields
        .filter((field) => field.field_type === 'files')
        .map((mf) => (
          <Box key={mf.internal_name} mb='20px'>
            <Typography color='#0C0C0C' variant='body1' mb='5px'>
              {mf.label}
            </Typography>
            <Box maxWidth='600px'>
              <FileUpload
                onChange={(files) => onAddFileClick(files, mf.internal_name)}
                accept={{ 'image/*': [] }}
                value={
                  mainItem[mf.internal_name] === undefined
                    ? []
                    : (mainItem[mf.internal_name] as string[])
                }
              />
            </Box>
          </Box>
        ))}
      <Divider sx={{ margin: '30px 0', borderColor: '#DFDDF9' }} />
      <Typography color='#0C0C0C' variant='h5' mb='30px'>
        {localize.products.form.additionalFields}
      </Typography>
      <Alert
        severity='info'
        sx={{ mb: '10px' }}
        icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
      >
        {localize.products.edit.additionalFieldsText}
        <NavLink
          to='/cp/templates/edit/product'
          data-cy='link-to-edit-product-template'
          style={{ color: '#0A7AFF', marginLeft: '5px' }}
        >
          {localize.products.edit.additionalFieldsLink}
        </NavLink>
      </Alert>
      <FieldsContainer mt='20px'>
        {mainFields
          .filter((field) => !field.required && field.field_type !== 'files')
          .map((mf) => (
            <FormField
              key={mf.internal_name}
              value={
                mainItem[mf.internal_name] === undefined
                  ? ''
                  : mainItem[mf.internal_name]
              }
              label={mf.label}
              name={mf.internal_name}
              onChange={handleMainInputChange}
              fieldType={mf.field_type}
              localizedField={mf.localized_field}
              options={mf.options}
              required={mf.required}
              disabled={!mf.editable}
            />
          ))}
      </FieldsContainer>
      <Divider sx={{ margin: '30px 0', borderColor: '#DFDDF9' }} />
      <Typography color='#0C0C0C' variant='h5' mb='30px'>
        {localize.products.form.modifications}
      </Typography>
      <Alert
        severity='info'
        sx={{ mb: '20px' }}
        icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
      >
        {localize.products.edit.modificationsText}
        <NavLink
          style={{ color: '#0A7AFF', marginLeft: '5px' }}
          to='/cp/templates/edit/product_modification'
          data-cy='link-to-edit-product_modification-template'
        >
          {localize.products.edit.modificationsLink}
        </NavLink>
      </Alert>
      {subItemFields.length ? (
        <>
          {subItems.map((item, i) => {
            if (item['_destroy']) return null;
            return (
              <SubItemContainer key={`${item.id}-${i}`}>
                <SubItemInnerContainer>
                  {subItemFields.map((mf) => (
                    <FormField
                      key={`${item.id}-${i}-${mf.internal_name}`}
                      value={item[mf.internal_name] || ''}
                      label={mf.label}
                      id={`pm_${mf.internal_name}`}
                      name={mf.internal_name}
                      required={mf.required}
                      fieldType={mf.field_type}
                      options={mf.options}
                      localizedField={mf.localized_field}
                      disabled={!mf.editable}
                      onChange={(e) => handleSubItemInputChange(e, i)}
                    />
                  ))}
                  {subItems.filter((item) => !item['_destroy']).length > 1 ? (
                    <Button
                      startIcon={<DeleteIcon fill='#fff' />}
                      data-cy='product-form-delete-subitem-btn'
                      variant='danger'
                      fullWidth={mobile}
                      sx={{ margin: 'auto 0 0 auto' }}
                      onClick={(): void => {
                        deleteSubItem(i);
                      }}
                    >
                      {localize.general.delete}
                    </Button>
                  ) : null}
                </SubItemInnerContainer>
              </SubItemContainer>
            );
          })}
          <Button
            variant='transparent'
            data-cy='product-form-add-subitem-btn'
            fullWidth={mobile}
            sx={{ mt: '20px', ml: mobile ? '0' : '10px' }}
            startIcon={<AddIcon />}
            onClick={() => setSubItems([...subItems, {}])}
          >
            {addModificationText}
          </Button>
        </>
      ) : null}
      {mobile && <Divider sx={{ mt: '20px', borderColor: '#DFDDF9' }} />}
      <Actions>
        <Button
          size='large'
          fullWidth={mobile}
          data-cy='product-form-submit-btn'
          type='submit'
          variant='rounded'
          disabled={submitting || !isFormValid || !isValidSubForm}
        >
          {submitting ? (
            <CircularProgress size={26} thickness={6} color='primary' />
          ) : (
            localize.general.submit
          )}
        </Button>
        <Button
          variant='transparent'
          fullWidth={mobile}
          data-cy='product-form-cancel-btn'
          onClick={() => {
            setMainItem(defaultMainValues);
            setSubItems(defaultSubItemValues);
          }}
          disabled={submitting}
        >
          {localize.general.cancel}
        </Button>
      </Actions>
    </Container>
  );
};

export default ProductForm;

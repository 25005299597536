import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack, Button, Typography } from '@mui/material';

import { NotificationContext } from '../../contexts/NotificationContext';
import { CommonContext } from '../../contexts/CommonContext';
import { Delivery, OrderApiClient, OrderItem } from '../../api/OrderApiClient';
import { OrderUploadApiClient } from '../../api/OrderUploadApiClient';
import { User } from '../../api/UserApiClient';
import { Customer } from '../../api/CustomerApiClient';
import { Promotion } from '../../api/PromotionApiClient';
import localize from '../../localize';
import Page from '../../components/Page/Page';
import Loading from '../../components/Loading/Loading';
import OrderItemSelect from '../../containers/order/OrderItemSelect';
import ClientCustomerFields from '../../containers/order/ClientCustomerFields';
import UploadFilesFields from '../../containers/order/UploadFilesFields';
import { uploadToAws } from '../../utils/aws';
import { templatesForTypes } from '../../utils/template';
import { useMobile } from '../../hooks/useMobile';

export const OrderCreatePage: FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const { setNotification } = useContext(NotificationContext);
  const { currentProject, templates } = useContext(CommonContext);
  const navigate = useNavigate();
  const [fixedClientDiscount, setFixedClientDiscount] = useState(0);
  const [fixedCustomerDiscount, setFixedCustomerDiscount] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [client, setClient] = useState<User>();
  const [customer, setCustomer] = useState<Customer>();
  const [promotion, setPromotion] = useState<Promotion>();
  const [delivery, setDelivery] = useState<Delivery>();
  const [files, setFiles] = useState<File[]>([]);
  const [orderItems, setOrderItems] = useState<(OrderItem | any)[]>([{}]);
  const [hasErrors, setHasErrors] = useState(true);
  const [hideBtn, setHideBtn] = useState(true);
  const mobile = useMobile();

  const changeOrderItems = (items: (OrderItem | any)[]) => {
    hideBtn && setHideBtn(false);
    setOrderItems(items);
  };

  useEffect(() => {
    if (currentStep === 3) {
      submitOrder();
    } else if (currentStep < 0) {
      navigate('/cp/orders');
    }
  }, [currentStep]);

  const submitOrder = () => {
    setSubmitting(true);
    const orderParams = {
      order_items_attributes: orderItems.filter(
        (oi) => oi.product_modification_id && oi.count
      ),
      user_id: client?.id,
      fixed_client_discount: fixedClientDiscount,
      fixed_customer_discount: fixedCustomerDiscount,
      promotion_id: promotion?.id,
      customer_attributes: customer,
      ...(delivery || {}),
    };

    OrderApiClient.create(currentProject.id, {
      order: orderParams,
    }).then((createdOrder) => {
      uploadToAws({
        projectId: currentProject.id,
        additionalParams: createdOrder.id,
        ApiClient: OrderUploadApiClient,
        files,
      }).then((response) => {
        OrderApiClient.update(
          currentProject.id,
          {
            order: {
              files: [
                ...response.errors.filter((el) => !!el),
                ...response.success,
              ],
            },
            template_id: templatesForTypes(templates, ['Order'])[0]?.id,
          },
          createdOrder.id
        ).then(() => {
          if (response.errors.length) {
            setNotification({
              severity: 'warning',
              message: localize.orders.create.warningCreateMessage,
            });
          } else {
            setNotification({
              severity: 'success',
              message: localize.orders.create.successCreateMessage,
            });
          }
          setSubmitting(false);
          navigate(`/cp/orders/${createdOrder.id}`);
        });
      });
    });
  };

  return (
    <Page title={localize.orders.create.title} actions={[]}>
      <Stack
        width='100%'
        height='fit-content'
        sx={{ position: 'relative', gap: '10px' }}
      >
        {submitting ? (
          <Loading
            text={
              <>
                <Typography variant='body1'>
                  {localize.orders.create.sendingData}
                </Typography>
                <Typography variant='caption'>
                  {localize.orders.create.sendingDataCaption}
                </Typography>
              </>
            }
          />
        ) : (
          <>
            <OrderItemSelect
              orderItems={orderItems}
              setOrderItems={changeOrderItems}
              setHasErrors={setHasErrors}
              nextStep={currentStep > 0}
            />
            {currentStep > 0 && (
              <ClientCustomerFields
                fixedClientDiscount={fixedClientDiscount}
                fixedCustomerDiscount={fixedCustomerDiscount}
                setFixedClientDiscount={setFixedClientDiscount}
                setFixedCustomerDiscount={setFixedCustomerDiscount}
                setClient={setClient}
                client={client}
                customer={customer}
                setCustomer={setCustomer}
                promotion={promotion}
                setPromotion={setPromotion}
                delivery={delivery}
                setDelivery={setDelivery}
                setHideBtn={setHideBtn}
                nextStep={currentStep > 1}
              />
            )}
            {currentStep > 1 && (
              <UploadFilesFields files={files} setFiles={setFiles} />
            )}
            {!hideBtn || currentStep === 2 ? (
              <Button
                variant='rounded'
                data-cy='order-create-next-btn'
                sx={{
                  position: 'absolute',
                  bottom: mobile ? '5px' : '30px',
                  right: '20px',
                }}
                onClick={() => {
                  setHideBtn(true);
                  setCurrentStep(currentStep + 1);
                }}
                disabled={submitting || hasErrors}
              >
                {currentStep > 1
                  ? localize.orders.create.finishButton
                  : localize.orders.create.nextStepButton}
              </Button>
            ) : null}
          </>
        )}
      </Stack>
    </Page>
  );
};

export default OrderCreatePage;

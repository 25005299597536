import { FC } from 'react';
import { DeliveryKeys, Order } from '../../api/OrderApiClient';
import { Typography, Divider } from '@mui/material';
import { StyledTypography } from './components';
import localize from '../../localize';

type DeliveryDetailsCardProps = {
  order: Order;
};

const deliveryFieldsAndLabels: { [key in DeliveryKeys]: string } =
  localize.orders.show.deliveryColumns;

export const DeliveryDetailsCard: FC<DeliveryDetailsCardProps> = ({
  order,
}) => {
  return Object.keys(deliveryFieldsAndLabels).some(
    (el) => order[el as DeliveryKeys]
  ) ? (
    <>
      <Divider sx={{ borderColor: '#DFDDF9', marginTop: '20px' }} />
      <br />
      <Typography variant='h5' color='#9B96EB' pb={1}>
        {localize.orders.show.clientDetailsCard.deliveryTitle}
      </Typography>
      {Object.keys(deliveryFieldsAndLabels).map((el) =>
        order[el as DeliveryKeys] ? (
          <StyledTypography variant='body1'>
            {deliveryFieldsAndLabels[el as DeliveryKeys]}:{' '}
            <Typography component='span' variant='subtitle2'>
              {order[el as DeliveryKeys]}
            </Typography>
          </StyledTypography>
        ) : null
      )}
    </>
  ) : null;
};

export default DeliveryDetailsCard;

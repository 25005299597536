import { Button, Link, ListItem, styled, Typography, Box } from '@mui/material';
import localize from '../../../localize';
import freeIcon from '../../../assets/landing/price/free.png';
import standardIcon from '../../../assets/landing/price/standard.png';
import businessIcon from '../../../assets/landing/price/business.png';
import ContactModalImg from '../../../assets/landing/contact-matte.svg';
import { ReactComponent as blueCloud } from '../../../assets/landing/price/blue-cloud.svg';
import { ReactComponent as pinkCloud } from '../../../assets/landing/price/pink-cloud.svg';
import ArrowFigure from '../../../assets/landing/price/arrow-figure.svg';
import { StaticProgressBar } from './StaticProgressBar';
import { useState } from 'react';
import { ContactModal } from './ContactModal';
import priceMobileBg from '../../../assets/landing/price/price-mobile-bg.svg';
import { Badge } from './Badge';
import { useMobile } from '../../../hooks/useMobile';

const PriceSectionContainer = styled('section')(() => ({
  padding: '3% 0',
  color: '#0c0c0c',
}));

const PriceSectionInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  justifyContent: 'normal',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1280px',
  minWidth: '320px',
  margin: '0 auto',

  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}));

const MostPopularWord = styled('span')(({ theme }) => ({
  fontOpticalSizing: 'auto',
  position: 'absolute',
  top: 'calc(50% - 130px)',
  left: '-40%',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#7B75CB',
  fontStyle: 'normal',
  fontWeight: '400',
  transform: 'rotate(-14deg)',
  fontFamily: '"Caveat", cursive',
  fontSize: '24px',
  lineHeight: '30px',
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    left: '-10%',
    top: '215px',
  },
  [theme.breakpoints.down('sm')]: {
    top: '245px',
  },

  '&::after': {
    content: `url(${ArrowFigure})`,
    position: 'relative',
    top: '10px',
    width: '24px',
    [theme.breakpoints.down('md')]: {
      transform: 'rotate(90deg)',
      top: '0',
    },
  },
}));

const PinkCloudSvg = styled(pinkCloud)(({ theme }) => ({
  position: 'absolute',
  bottom: '0',
  left: '1%',
  width: '411px',
  height: 'auto',
  zIndex: 0,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const BlueCloudSvg = styled(blueCloud)(({ theme }) => ({
  position: 'absolute',
  top: '10%',
  right: '15%',
  width: '413px',
  height: 'auto',
  zIndex: 0,
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NeedMoreWordGroup = styled('p')(() => ({
  fontOpticalSizing: 'auto',
  display: 'flex',
  gap: '16px',
  color: '#7B75CB',
  fontStyle: 'normal',
  fontWeight: '400',
  fontFamily: '"Caveat", cursive',
  fontSize: '24px',
  lineHeight: '30px',
}));

const PriceTitle = styled('h2')(({ theme }) => ({
  color: '#0c0c0c',
  alignSelf: 'flex-start',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  fontSize: '34px',
  fontWeight: '500',
  margin: '30px 0',
  fontFamily: '"Montserrat", sans-serif',
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    margin: '24px 0',
  },
}));

const PriceSubtitle = styled('p')(({ theme }) => ({
  color: '#53C17F',
  fontWeight: '700',
  alignSelf: 'flex-start',
  marginTop: '0',
  fontFamily: '"Montserrat", sans-serif',
  [theme.breakpoints.down('md')]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const PriceOptionListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  justifyContent: 'normal',
  alignItems: 'flex-end',
  width: 'fit-content',
  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${priceMobileBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    padding: '0 50px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
  },
  '@media (max-width: 320px)': {
    padding: '0',
  },
}));

const PriceOptionList = styled('ul')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  padding: '24px 0',
  margin: 0,
  flexDirection: 'column',
  minWidth: '410px',
  width: '100%',
  gap: '24px',
  listStyle: 'none',

  [theme.breakpoints.down('md')]: {
    minWidth: '100%',
    marginTop: '-90px',
  },
}));

const PriceOptionCard = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '20px',
  margin: '0 16px',
  padding: '20px 16px',
  backgroundColor: 'rgba(255, 255, 255, 0.3)',
  borderRadius: '24px',
  boxShadow: '0px 4px 20px 0px #C6B7FF4D',
  border: '1px solid #fff',

  '&:nth-of-type(2)': {
    margin: '0',
    border: '1px solid #DFDDF9',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      marginTop: '90px',
    },
  },
}));

const PriceOptionCardHeader = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  marginBottom: '20px',
  fontFamily: '"Montserrat", sans-serif',
  '.free-price': {
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-end',
      justifyContent: 'flex-end',
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    gap: '12px',
  },
}));

const PriceText = styled('span')(() => ({
  color: '#5BB275',
  fontSize: '16px',
  fontWeight: '700',
  margin: '0',
  fontFamily: '"Montserrat", sans-serif',
}));

const PricePeriodText = styled('span')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '6px 0',
  gap: '4px',
  justifyContent: 'space-around',
}));

const PricePeriodContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  // alignSelf: 'flex-end',
  alignItems: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    flexGrow: 1,
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const OldPriceText = styled('span')(() => ({
  textDecoration: 'line-through',
  fontWeight: '500',
}));

const PriceHeaderLeft = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '16px',
}));

const PriceHeaderRight = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignSelf: 'flex-end',
  justifyContent: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

const PriceOptionCardTitle = styled('h3')(() => ({
  color: '#7B75CB',
  textTransform: 'capitalize',
  alignSelf: 'flex-start',
  fontSize: '20px',
  fontWeight: '600',
  margin: '0',
  fontFamily: '"Montserrat", sans-serif',
}));

const PriceContent = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    gap: '32px',
  },
}));

const PriceTextContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
}));

const PlansTextGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '46px',
  alignItems: 'flex-start',
}));

const PriceTextActions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    alignItems: 'flex-start',
  },
}));

const PlansSubtitle = styled('h3')(({ theme }) => ({
  color: '#7B75CB',
  textTransform: 'capitalize',
  alignSelf: 'flex-start',
  fontSize: '24px',
  fontWeight: '600',
  marginBottom: '32px',
  fontFamily: '"Montserrat", sans-serif',
  [theme.breakpoints.down('md')]: {
    marginBottom: '0',
  },
}));

const StyledButtonLink = styled(Button)(({ theme }) => ({
  fontOpticalSizing: 'auto',
  color: '#7B75CB',
  fontStyle: 'normal',
  padding: '0',
  borderRadius: '0',
  fontWeight: '400',
  fontFamily: '"Caveat", cursive',
  fontSize: '24px',
  lineHeight: '30px',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
}));

const PlansTextList = styled('ul')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '36px',
  maxWidth: '610px',
  listStyle: 'none',
  padding: 0,
  margin: 0,
  marginBottom: '10px',

  '& li': {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  '& h4': {
    fontWeight: 700,
    fontSize: '16px',
    color: '#0c0c0c',
    fontFamily: '"Montserrat", sans-serif',
  },

  '& p': {
    fontWeight: 400,
    fontSize: '16px',
    color: '#0c0c0c',
    fontFamily: '"Montserrat", sans-serif',
  },
}));

const benefitsTextList: { title: string; description: string }[] =
  localize.landing.priceSection.benefitItems;

type OptionCardItem = {
  title: string;
  price: string;
  oldPrice?: string;
  description: string;
  period: string | null;
  progressBarPercentage: number;
  progressBarColor: string;
};

const optionsListData: OptionCardItem[] =
  localize.landing.priceSection.optionCardItems;

const optionCardIcons = [
  {
    path: freeIcon,
  },
  {
    path: standardIcon,
  },
  {
    path: businessIcon,
  },
];

const optionListItems: (OptionCardItem & { path: string })[] =
  optionsListData.map((item, index) => ({
    ...item,
    path: optionCardIcons[index].path,
  }));

export const PriceSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const mobile = useMobile();
  return (
    <PriceSectionContainer id='price'>
      <PriceSectionInner>
        <PriceTitle>
          {localize.landing.priceSection.title} <Badge>Beta</Badge>
        </PriceTitle>
        <PriceSubtitle>
          {localize.landing.pricingPage.tarrifsSectionSubtext}
        </PriceSubtitle>
        <PriceContent>
          <PinkCloudSvg />
          <BlueCloudSvg />
          <PriceTextContainer>
            <PlansTextGroup>
              <PlansSubtitle>
                {localize.landing.priceSection.subtitle}
              </PlansSubtitle>
              <PlansTextList>
                {benefitsTextList.map((item) => (
                  <ListItem
                    key={item.title}
                    sx={{ padding: 0, alignItems: 'flex-start' }}
                  >
                    <Typography variant='h4'>{item.title}</Typography>
                    <Typography
                      variant='body1'
                      sx={{ lineHeight: '20px' }}
                      component={'p'}
                    >
                      {item.description}
                    </Typography>
                  </ListItem>
                ))}
              </PlansTextList>
              <PriceTextActions>
                <Button
                  component={Link}
                  href='/price'
                  sx={{ alignSelf: 'flex-start' }}
                  fullWidth={mobile}
                  variant='rounded'
                >
                  {localize.landing.priceSection.CTAButton}
                </Button>
                <NeedMoreWordGroup>
                  {localize.landing.priceSection.needMore}{' '}
                  <StyledButtonLink
                    disableRipple
                    onClick={() => setOpenModal(true)}
                  >
                    {localize.landing.priceSection.contactUs}
                  </StyledButtonLink>
                  <ContactModal
                    open={openModal}
                    handleClose={() => setOpenModal(false)}
                    handleSubmit={() => setOpenModal(false)}
                    title={localize.landing.modal.titleContactUs}
                    imgPath={ContactModalImg}
                  />
                </NeedMoreWordGroup>
              </PriceTextActions>
            </PlansTextGroup>
          </PriceTextContainer>
          <PriceOptionListWrapper>
            <MostPopularWord>
              {localize.landing.priceSection.mostPopular}
            </MostPopularWord>
            <PriceOptionList>
              {optionListItems.map(
                (
                  {
                    title,
                    price,
                    oldPrice,
                    description,
                    period,
                    progressBarPercentage,
                    progressBarColor,
                    path,
                  },
                  index
                ) => (
                  <PriceOptionCard key={title}>
                    <PriceOptionCardHeader>
                      <PriceHeaderLeft>
                        <PriceOptionCardTitle>{title}</PriceOptionCardTitle>
                        <Typography
                          sx={
                            index === 1
                              ? { fontSize: '14px' }
                              : { fontSize: '12px' }
                          }
                          variant='body1'
                        >
                          {description}
                        </Typography>
                      </PriceHeaderLeft>
                      <PriceHeaderRight
                        className={index === 0 ? 'free-price' : ''}
                      >
                        {period ? (
                          <PricePeriodContainer>
                            <Badge>Beta</Badge>
                            {oldPrice ? (
                              <PricePeriodText>
                                <OldPriceText>
                                  <Typography component='span'>
                                    {oldPrice}
                                  </Typography>
                                  {' / '}
                                  <Typography component='span'>
                                    {period}
                                  </Typography>
                                </OldPriceText>
                                <Box sx={{ fontWeight: '400' }}>
                                  <PriceText>{price}</PriceText>
                                  {' / '}
                                  <Typography variant='body1' component='span'>
                                    {period}
                                  </Typography>
                                </Box>
                              </PricePeriodText>
                            ) : (
                              <PricePeriodText>
                                <PriceText>{price}</PriceText>
                                {' / '}
                                <Typography>{period}</Typography>
                              </PricePeriodText>
                            )}
                          </PricePeriodContainer>
                        ) : (
                          <PriceText>{price}</PriceText>
                        )}
                      </PriceHeaderRight>
                    </PriceOptionCardHeader>
                    <StaticProgressBar
                      progress={progressBarPercentage}
                      color={progressBarColor}
                      iconPath={path}
                    />
                  </PriceOptionCard>
                )
              )}
            </PriceOptionList>
          </PriceOptionListWrapper>
        </PriceContent>
      </PriceSectionInner>
    </PriceSectionContainer>
  );
};

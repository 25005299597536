import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Typography, Tabs, Tab } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { CommonContext } from '../../contexts/CommonContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { BookingApiClient } from '../../api/BookingApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import { useAsyncError } from '../../hooks/useAsyncError';
import { useFilter } from '../../hooks/useFilter';
import localize from '../../localize';
import { timeLeft } from '../../utils/time';
import Page from '../../components/Page/Page';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import Loading from '../../components/Loading/Loading';
import Filter from '../../components/Filter/Filter';
import SearchBar from '../../components/SearchBar/SearchBar';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import {
  DeleteItem,
  RedirectToItem,
  EditItem,
} from '../../components/ActionsForTable';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import HorizontalScrollWrapper from '../../components/HorizontalScrollWrapper/HorizontalScrollWrapper';
import { useMobile } from '../../hooks/useMobile';

const InnerContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

const NumberText = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '9px',
  lineHeight: '11px',
  fontWeight: 700,
  color: '#464646',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  marginLeft: '5px',
}));

export const BookingIndexPage: FC = () => {
  const { currentProject, currentUser } = useContext(CommonContext);
  const { setNotification } = useContext(NotificationContext);
  const throwError = useAsyncError();
  const navigate = useNavigate();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [itemForDelete, setItemForDelete] = useState<AnyObject | null>(null);
  const [activeStatusFilter, setActiveStatusFilter] = useState('All');
  const mobile = useMobile();

  const {
    results: rowsToShow,
    resetFilter,
    mainItems,
    subItems,
    setFilterItems,
    anyFiltersApplied,
    setInitialValue,
  } = useFilter(rows, 'bookings');

  const showActions = useMemo(
    () => currentUser.user_permissions.template?.update?.length !== 0,
    [currentUser.user_permissions, rows]
  );

  const actionsForInstance = (row: any) => {
    const actions = [];
    if (row.order_id) {
      actions.push(
        <RedirectToItem
          onClick={(row) => {
            navigate(`/cp/orders/${row.order_id}`);
          }}
          key={`redirect_item-${row.id}`}
          item={row}
        />
      );
    } else {
      actions.push(
        <EditItem
          onClick={(row) => {
            navigate(`/cp/bookings/edit/${row.id}`);
          }}
          key={`edit_item-${row.id}`}
          item={row}
        />,
        <DeleteItem
          onClick={(row) => {
            setItemForDelete(row);
          }}
          key={`delete_item-${row.id}`}
          item={row}
        />
      );
    }
    return actions;
  };

  const defaultColumns = [
    {
      field: 'product_title',
      className: 'column-sticky-left',
      align: 'left',
      headerName: localize.bookings.columns.product_title,
    },
    {
      field: 'product_modification_title',
      align: 'left',
      headerName: localize.bookings.columns.product_modification_title,
    },
    {
      field: 'count',
      headerName: localize.bookings.columns.count,
      type: 'number',
    },
    {
      field: 'user_name',
      headerName: localize.bookings.columns.user_name,
      type: 'string',
    },
    {
      field: 'comment',
      headerName: localize.bookings.columns.comment,
      type: 'string',
      valueGetter: (row: any) => (
        <Typography component='span'>
          {row.comment ? `${row.comment.slice(0, 20)}...` : ''}
        </Typography>
      ),
    },
    {
      field: 'enddate',
      headerName: localize.bookings.columns.enddate,
      type: 'string',
      width: '100px',
      valueGetter: (row: any) =>
        timeLeft(new Date(row.enddate)) !== '0' || row.status !== 'Active' ? (
          <Typography
            component='span'
            color={row.status === 'Stock updated' ? '#FFBF1C' : '#53C17F'}
          >
            {localize.bookings.status[row.status]}
          </Typography>
        ) : (
          <Typography component='span' color='#FA3B3B'>
            {localize.bookings.status['Disabled']}
          </Typography>
        ),
    },
    {
      ...(showActions
        ? {
            field: 'actions',
            headerName: localize.products.columns.actions,
            align: 'left',
            className: 'column-sticky-right',
            width: mobile ? '85px' : '150px',
            renderCell: (row: any) => actionsForInstance(row),
          }
        : {}),
    },
  ];

  const onFilterItemClick = (status: string) => {
    setActiveStatusFilter(status);
  };

  const rowsToDisplay = useMemo(
    () =>
      activeStatusFilter === 'All'
        ? rowsToShow
        : activeStatusFilter === 'Active'
          ? rowsToShow.filter(
              (item: AnyObject) =>
                item.status === activeStatusFilter &&
                timeLeft(new Date(item.enddate)) !== '0'
            )
          : activeStatusFilter === 'Disabled'
            ? rowsToShow.filter(
                (item: AnyObject) =>
                  item.status === activeStatusFilter ||
                  (item.status === 'Active' &&
                    timeLeft(new Date(item.enddate)) === '0')
              )
            : rowsToShow.filter(
                (item: AnyObject) => item.status === activeStatusFilter
              ),
    [rowsToShow, activeStatusFilter]
  );

  const actions = useMemo(() => {
    const addAction = {
      Component: (
        <NavLink
          to='/cp/bookings/create'
          data-cy='link-to-bookings-create'
          style={{
            textDecoration: 'none',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <AddIcon sx={{ fill: '#5B4D8F' }} />
          <Typography color='#5B4D8F' variant='h5' p='10px'>
            {localize.bookings.index.addButton}
          </Typography>
        </NavLink>
      ),
    };

    if (!currentUser.user_permissions.booking?.create?.length) return [];

    return [addAction];
  }, [currentUser]);

  const preparePageData = async (): Promise<void> => {
    const apiRows = await BookingApiClient.getIndex(currentProject.id).catch(
      (e) => {
        throwError(e);
      }
    );

    setRows(apiRows || []);
  };

  const deleteItem = () => {
    itemForDelete &&
      BookingApiClient.remove(currentProject.id, itemForDelete.id)
        .then(() => {
          setRows(rows.filter((row) => row.id !== itemForDelete.id));
          setNotification({
            severity: 'success',
            message: localize.general.successDeleteMessage,
          });
          setItemForDelete(null);
        })
        .catch((e) => {
          setNotification({
            severity: 'warning',
            message: localize.general.unexpectedError,
          });
        });
  };

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
  }, [currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.bookings.index.title} actions={actions}>
      <ConfirmationDialog
        header={`${localize.products.delete.title} ${itemForDelete?.product_title || 'це'}?`}
        image='/delete-image.svg'
        open={!!itemForDelete}
        description={localize.products.delete.text}
        action={() => deleteItem()}
        discard={() => setItemForDelete(null)}
        mainActionButtonText={localize.products.delete.deleteBtn}
      />
      <InnerContainer>
        <SearchBar
          onSearch={(smth: string) => setInitialValue(smth)}
          onFilterButtonClick={() => setOpenFilter((prev) => !prev)}
          anyFiltersApplied={anyFiltersApplied || openFilter}
        />
        {openFilter && (
          <Filter
            mainItems={mainItems}
            subItems={subItems}
            anyFiltersApplied={anyFiltersApplied}
            resetFilter={resetFilter}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            applyFilter={setFilterItems}
          />
        )}
        <HorizontalScrollWrapper className='MuiTabs-scroller'>
          <Tabs
            value={activeStatusFilter}
            onChange={(event, newValue) => {
              onFilterItemClick(newValue);
            }}
          >
            {['All', 'Active', 'Disabled', 'Stock updated'].map((el) => (
              <Tab
                key={el}
                value={el}
                label={
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    {localize.bookings.status[el]}
                    <NumberText
                      sx={{
                        background:
                          el === activeStatusFilter ? '#C8FFD7' : '#f2f2f2',
                      }}
                    >
                      {el === 'All'
                        ? rowsToShow.length
                        : el === 'Active'
                          ? rowsToShow.filter(
                              (item: AnyObject) =>
                                item.status === el &&
                                timeLeft(new Date(item.enddate)) !== '0'
                            ).length
                          : el === 'Disabled'
                            ? rowsToShow.filter(
                                (item: AnyObject) =>
                                  item.status === el ||
                                  (item.status === 'Active' &&
                                    timeLeft(new Date(item.enddate)) === '0')
                              ).length
                            : rowsToShow.filter(
                                (item: AnyObject) => item.status === el
                              ).length}
                    </NumberText>
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </HorizontalScrollWrapper>
        <br />
        {!!rowsToDisplay.length ? (
          <BasicTable
            rows={rowsToDisplay}
            columns={defaultColumns}
            onRowClick={(row: AnyObject) => navigate(`/cp/bookings/${row.id}`)}
          />
        ) : (
          <EmptyContent withImage='box' />
        )}
      </InnerContainer>
    </Page>
  );
};

export default BookingIndexPage;

import { ReactNode, useEffect, useRef, useState, FC } from 'react';

import { IconButton, Box } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledButton = styled(IconButton)(() => ({
  background: '#AFABEF',
  borderRadius: '2px',
  padding: '4px',
  color: '#fff',
  opacity: 0.5,
  position: 'absolute',
  right: -10,

  zIndex: 2,
  '&.left-scroll': { top: -10 },
  '&.right-scroll': { bottom: -10 },
  '&:hover, &:active, &:focus': {
    opacity: 1,
    background: '#AFABEF',
  },
}));

type HorizontalScrollWrapperProps = {
  children: ReactNode;
  className: string;
};

const HorizontalScrollWrapper: FC<HorizontalScrollWrapperProps> = ({
  children,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [element, setElement] = useState<Element | null>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const currentElement =
          containerRef.current.getElementsByClassName(className)[0];
        if (currentElement) {
          setShowScrollButton(
            currentElement.scrollWidth > currentElement.clientWidth
          );
          setElement(currentElement);
        } else {
          setElement(null);
          setShowScrollButton(false);
        }
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const scrollLeft = () => {
    element && element.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    element && element.scrollBy({ left: 200, behavior: 'smooth' });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        pr: showScrollButton ? 3 : 0,
      }}
    >
      {showScrollButton && (
        <StyledButton size='small' className='left-scroll' onClick={scrollLeft}>
          <ChevronLeft />
        </StyledButton>
      )}
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          scrollBehavior: 'smooth',
          width: '100%',
        }}
      >
        {children}
      </Box>
      {showScrollButton && (
        <StyledButton
          size='small'
          className='right-scroll'
          onClick={scrollRight}
        >
          <ChevronRight />
        </StyledButton>
      )}
    </Box>
  );
};

export default HorizontalScrollWrapper;

import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { Product, ProductApiClient } from '../../api/ProductApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import FormField from '../../components/FormField/FormField';
import { styled } from '@mui/material/styles';
import { OrderItem } from '../../api/OrderApiClient';
import { CommonContext } from '../../contexts/CommonContext';
import Loading from '../../components/Loading/Loading';
import localize from '../../localize';
import { DeleteItem } from '../../components/ActionsForTable';
import { Add as AddIcon } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMobile } from '../../hooks/useMobile';

type OrderItemSelectProps = {
  orderItems: (OrderItem | any)[];
  setOrderItems(orderItems: (OrderItem | any)[]): void;
  setHasErrors(hasErrors: boolean): void;
  nextStep?: boolean;
};

const SingleItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: '100%',
  justifyContent: 'flex-start',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    bottom: '-20px',
    left: '10px',
  },
}));

export const OrderItemSelect: FC<OrderItemSelectProps> = ({
  orderItems,
  setOrderItems,
  setHasErrors,
  nextStep,
}) => {
  const { currentProject } = useContext(CommonContext);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const [duplications, setDuplications] = useState<OrderItem[] | any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const mobile = useMobile();

  const fetchProducts = async () => {
    setLoadingProducts(true);
    const res = await ProductApiClient.getIndex(currentProject?.id);
    setLoadingProducts(false);
    setProducts(res);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (Object.keys(orderItems[0]).length) {
      const dups = orderItems.filter(
        (orderItem) =>
          orderItems.filter(
            (el) =>
              el.product_modification_id ===
                orderItem.product_modification_id && !el['_destroy']
          )?.length > 1
      );
      const emptyData = orderItems.some(
        (orderItem) =>
          !orderItem['_destroy'] &&
          (!orderItem.product_modification_id ||
            !orderItem.count ||
            Number(orderItem.count) <= 0)
      );

      setDuplications(dups);
      setHasErrors(emptyData || !!dups.length);
    } else {
      setHasErrors(true);
    }
  }, [orderItems]);

  const autocompleteOptions = useMemo(() => {
    if (!products.length) return [];

    return products
      .map((el) =>
        el.product_modifications.map((pm: AnyObject) => ({
          groupLabel: [
            el.additional_fields?.secondaryid,
            el.additional_fields?.n_collection,
            el.title,
          ]
            .filter((f) => !!f)
            .join(' - '),
          label: `${pm.parsed_title} || ціна: ${pm.price}; доступно (од): ${pm.available_count};`,
          price: pm.price,
          pm: pm,
          product: el,
          id: pm.id,
        }))
      )
      .flat();
  }, [products]);

  const handleInputChange = (
    e: { target: { name: any; value: any } },
    itemIndex: number
  ) => {
    const { name, value } = e.target;
    const newOrderItems = orderItems.slice();
    newOrderItems[itemIndex] = { ...newOrderItems[itemIndex], [name]: value };
    setOrderItems(newOrderItems);
  };

  const deleteSubItem = (itemIndex: number) => {
    let newOrderItems = orderItems.slice();
    if (newOrderItems[itemIndex]['id']) {
      newOrderItems[itemIndex]['_destroy'] = true;
    } else {
      newOrderItems.splice(itemIndex, 1);
    }
    setOrderItems(newOrderItems);
  };

  const addMoreClick = () => {
    setOrderItems([...orderItems.slice(), {}]);
  };

  if (loadingProducts) return <Loading />;

  return (
    <Card
      sx={{
        border: '1px solid #DFDDF9',
        borderRadius: '8px',
        padding: mobile ? '8px' : '8px 24px',
        overflow: 'visible',
      }}
    >
      <Accordion
        expanded={nextStep ? isOpen : true}
        onChange={() => setIsOpen((prev) => !prev)}
        sx={{
          '& .MuiCollapse-root.MuiCollapse-entered': { border: 'none' },
          '& .MuiAccordionSummary-content': { margin: '0' },
        }}
      >
        <AccordionSummary expandIcon={nextStep ? <ExpandMoreIcon /> : <></>}>
          <CardHeader
            sx={{ padding: '0' }}
            title={localize.orders.form.items.title}
          />
        </AccordionSummary>
        <AccordionDetails>
          <CardContent sx={{ padding: '0' }}>
            {orderItems.map((orderItem, i) => {
              if (orderItem['_destroy']) return null;

              return (
                <SingleItemContainer
                  key={`${orderItem.product_modification_id}-${i}`}
                >
                  <FormField
                    sx={{
                      minWidth: mobile ? '200px' : '300px',
                      flex: 1,
                      padding: mobile ? '10px 0' : '10px',
                    }}
                    value={orderItem.product_modification_id || ''}
                    label={localize.orders.form.items.modificationLabel}
                    name='product_modification_id'
                    required={true}
                    error={
                      duplications.includes(orderItem)
                        ? localize.orders.form.items.duplicateError
                        : !orderItem.product_modification_id
                          ? localize.orders.form.items.selectOrDeleteError
                          : ''
                    }
                    onChange={(e) => handleInputChange(e, i)}
                    autocomplete={{
                      options: autocompleteOptions,
                      renderOption: (props: any, option: any) => (
                        <li {...props} key={option.id}>
                          Назва:&nbsp;<b>{option.pm.parsed_title}</b>;&nbsp;
                          Ціна:&nbsp;<b>{option.pm.price}</b>;&nbsp; Доступно
                          (од.):&nbsp;<b>{option.pm.available_count}</b>;
                        </li>
                      ),
                      onChange: (_, newValue: any) => {
                        const newOrderItems = orderItems.slice();
                        const newFields = newValue?.price
                          ? {
                              product_modification_id: newValue.id,
                              fixed_price: +newValue.price,
                            }
                          : {
                              product_modification_id: newValue,
                            };
                        newOrderItems[i] = {
                          ...newOrderItems[i],
                          ...newFields,
                        };

                        setOrderItems(newOrderItems);
                      },
                    }}
                  />
                  <FormField
                    sx={{
                      minWidth: '120px',
                      width: mobile ? '100%' : '30%',
                      padding: mobile ? '10px 0' : '10px',
                    }}
                    value={orderItem.count || ''}
                    label={localize.orders.form.items.quantityLabel}
                    fieldType='number'
                    name='count'
                    error={
                      !orderItem.count
                        ? localize.orders.form.items.emptyFieldError
                        : ''
                    }
                    required={true}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      marginLeft: 'auto',
                      padding: '16px 0',
                    }}
                  >
                    {orderItems.filter((item) => !item['_destroy']).length <=
                    1 ? null : (
                      <Box sx={{ background: '#DFDDF9', borderRadius: '50%' }}>
                        <DeleteItem
                          item={{ id: i }}
                          onClick={() => {
                            deleteSubItem(i);
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                  {mobile ? <Divider sx={{ borderColor: '#DFDDF9' }} /> : null}
                </SingleItemContainer>
              );
            })}
            <Button
              variant='transparent'
              sx={{ mt: '10px' }}
              startIcon={<AddIcon />}
              onClick={addMoreClick}
            >
              {localize.orders.form.items.addMore}
            </Button>
            {mobile ? (
              <Divider sx={{ borderColor: '#DFDDF9', mb: '10px' }} />
            ) : null}
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default OrderItemSelect;

import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { styled } from '@mui/material/styles';
import {
  Alert,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Button,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { CommonContext } from '../../contexts/CommonContext';
import Page from '../../components/Page/Page';
import SearchBar from '../../components/SearchBar/SearchBar';
import Filter from '../../components/Filter/Filter';
import PageMenu from '../../components/PageMenu/PageMenu';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { Order, OrderApiClient } from '../../api/OrderApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import { post } from '../../api/request';
import { BookingApiClient } from '../../api/BookingApiClient';
import { numberToCurrency } from '../../utils/numberToCurrency';
import localize from '../../localize';
import { useMobile } from '../../hooks/useMobile';
import { useFilter } from '../../hooks/useFilter';
import SmallCardForOrder from '../../containers/order/SmallCardForOrder';
import { statusColors } from '../../containers/StatusesTimeline';
import HorizontalScrollWrapper from '../../components/HorizontalScrollWrapper/HorizontalScrollWrapper';

// import { GenerateOrdersPdfButton } from '../../components/GenerateOrdersPdfButton/GenerateOrdersPdfButton';

import { ReactComponent as InfoIconSvg } from '../../assets/info-circle.svg';

const InnerContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

const StyledGridItem = styled(Grid)(() => ({
  padding: '6px',
  '&>*': {
    width: '100%',
    height: '100%',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '4px 8px 32px 0px #5043CF14',
    background: '#fff',
    border: '1px solid #fff',
  },
}));

const NumberText = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '9px',
  lineHeight: '11px',
  fontWeight: 700,
  color: '#464646',
  borderRadius: '50%',
  width: '24px',
  height: '24px',
  marginLeft: '5px',
}));

const defaultCollapsedColumns = [
  {
    field: 'product_title',
    align: 'left',
    headerName: localize.orders.index.collapseColumns.product_title,
    valueGetter: (row: any) =>
      [
        row.product?.additional_fields?.secondaryid,
        row.product?.additional_fields?.n_collection,
        row.product?.title,
      ]
        .filter((f) => !!f)
        .join(' - '),
  },
  {
    field: 'product_modification_title',
    align: 'left',
    headerName:
      localize.orders.index.collapseColumns.product_modification_title,
  },
  {
    field: 'count',
    headerName: localize.orders.index.collapseColumns.count,
  },
  {
    field: 'fixed_price',
    headerName: localize.orders.index.collapseColumns.fixed_price,
  },
];

export const statusesToFilterBy = [
  'Pending',
  'Complete',
  'InProduction',
  'Canceled',
  'Blocked',
];

const defaultMainColumns = [
  {
    field: 'id',
    align: 'left',
    headerName: localize.orders.columns.id,
    width: '130px',
  },
  {
    field: 'user_name',
    align: 'left',
    headerName: localize.orders.columns.user_name,
  },
  { field: 'sum', headerName: localize.orders.columns.sum },
  {
    field: 'last_status',
    headerName: localize.orders.columns.last_status,
    // className: 'action_cell',
    valueGetter: (row: any) => {
      const editItem: any[] = [];

      // TODO need to add logic to change status from table
      // if (row.last_status !== 'Canceled' && row.last_status !== 'Complete') {
      //   if (row.editable_by_current_user) {
      //     let validStatuses = ['Pending'];
      //     if (
      //       ['staff', 'owner', 'manager', 'administrator'].includes(
      //         currentUser?.user_permissions?.role_name
      //       )
      //     )
      //       validStatuses = [
      //         'Pending',
      //         'Ready',
      //         'InProduction',
      //         'Complete',
      //         'Canceled',
      //         'Blocked',
      //       ];
      //     validStatuses
      //       .filter((st) => st !== row.last_status)
      //       .forEach((status) =>
      //         editItem.push({
      //           title:
      //             status === 'Pending' &&
      //             currentUser?.user_permissions?.role_name === 'client'
      //               ? localize.orders.show.clientChangeStatusToPending
      //               : localize.orders.show[`changeStatusTo${status}`],
      //           onClick: async () => {
      //             await post(
      //               `projects/${currentProject.id}/orders/${row.id}/order_statuses`,
      //               {
      //                 title: status,
      //               }
      //             );
      //           },
      //         })
      //       );
      //     if (
      //       row.last_status === 'Draft' &&
      //       !!row.order_items?.filter((oi: any) => !oi.is_booked).length
      //     ) {
      //       editItem.push({
      //         title: localize.orders.show.bookTemporary,
      //         onClick: async () =>
      //           await Promise.all(
      //             row.order_items.map((orderItem: any) =>
      //               BookingApiClient.create(currentProject.id, {
      //                 order_item_id: orderItem.id,
      //               })
      //             )
      //           ),
      //       });
      //     }
      //   }
      // }

      return editItem.length ? (
        <PageMenu
          menuItems={editItem}
          title={
            <Typography
              color={statusColors[row.last_status]}
              component='span'
              variant='h5'
            >
              {localize.order_statuses.values[row.last_status]}
            </Typography>
          }
        />
      ) : (
        <Typography
          color={statusColors[row.last_status]}
          component='span'
          variant='h5'
        >
          {localize.order_statuses.values[row.last_status]}
        </Typography>
      );
    },
  },
  {
    field: 'sent_to_manager_at',
    headerName: localize.orders.columns.sent_to_manager_at,
    valueGetter: (row: any) =>
      row.sent_to_manager_at &&
      new Date(row.sent_to_manager_at).toLocaleDateString(),
  },
  {
    field: 'updated_at',
    headerName: localize.orders.columns.updated_at,
    valueGetter: (row: any) =>
      row.updated_at && new Date(row.updated_at).toLocaleDateString(),
  },
];

export const OrderIndexPage: FC = () => {
  const { currentProject, currentUser } = useContext(CommonContext);
  const navigate = useNavigate();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const location = useLocation();
  const [activeStatusFilter, setActiveStatusFilter] = useState('All');
  const [openFilter, setOpenFilter] = useState(false);
  const {
    results: rowsToShow,
    resetFilter,
    mainItems,
    subItems,
    dateRange,
    setFilterItems,
    anyFiltersApplied,
    setInitialValue,
    onSearchByDateRange,
  } = useFilter(rows, 'orders', 'order_items');
  const [tableKey, setTableKey] = useState(322);
  const [items, setItems] = useState<Order[]>();
  const mobile = useMobile();
  const rowsToDisplay = useMemo(
    () =>
      activeStatusFilter === 'All'
        ? rowsToShow
        : rowsToShow.filter(
            (row: any) => row.last_status === activeStatusFilter
          ),
    [rowsToShow, activeStatusFilter]
  );
  const totalSum = useMemo(
    () =>
      rows
        .map((row: any) => parseFloat(row.sum))
        .reduce((a: number, b: number) => a + b, 0),
    [rows]
  );
  const totalSumComplete = useMemo(
    () =>
      rows
        .filter((row: any) => ['Complete'].includes(row.last_status))
        .map((row: any) => parseFloat(row.sum))
        .reduce((a: number, b: number) => a + b, 0),
    [rows]
  );
  const totalSumCanceled = useMemo(
    () =>
      rows
        .filter((row: any) => ['Canceled'].includes(row.last_status))
        .map((row: any) => parseFloat(row.sum))
        .reduce((a: number, b: number) => a + b, 0),
    [rows]
  );
  const totalSumPending = useMemo(
    () =>
      rows
        .filter((row: any) => ['Pending'].includes(row.last_status))
        .map((row: any) => parseFloat(row.sum))
        .reduce((a: number, b: number) => a + b, 0),
    [rows]
  );

  const pageActions = useMemo(() => {
    if (!items) return [];

    const addAction = {
      Component: mobile ? (
        <NavLink data-cy='link-to-create-order' to='/cp/orders/create'>
          <Button
            variant='rounded'
            startIcon={<AddIcon sx={{ fill: '#fff' }} />}
          >
            {localize.general.add}
          </Button>
        </NavLink>
      ) : (
        <NavLink data-cy='link-to-create-order' to='/cp/orders/create'>
          <IconButton
            sx={{
              padding: '12px 24px',
              background: '#7B75CB',
              borderRadius: '100px',
              '&:hover': { background: '#7B75CB' },
            }}
          >
            <AddIcon sx={{ fill: '#fff' }} />
          </IconButton>
        </NavLink>
      ),
    };

    return [
      // TODO create pdf from orders
      // { Component: <GenerateOrdersPdfButton orders={items} /> },
      addAction,
    ];
  }, [items]);

  const preparePageData = async (): Promise<void> => {
    const apiRows = await OrderApiClient.getIndex(currentProject.id);
    setItems(apiRows.orders);
    setRows(apiRows.orders);
  };

  const onFilterItemClick = (status: string) => {
    setActiveStatusFilter(status);
    // TODO add search param if we need it
    // searchParams.set('status', status);
    // setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    setActiveStatusFilter(
      new URLSearchParams(location.search).get('status') || 'All'
    );
    setTableKey(Math.random() * 100);
  }, []);

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));

    return () => setOpenFilter(false);
  }, [currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.orders.index.title} actions={pageActions}>
      <InnerContainer>
        {/* TODO we have Alert here in maket */}
        {/* <Alert
          severity='info'
          icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
        >
          {localize.orders.index.infoText}
          <NavLink
            to='/'
            data-cy='link-to'
            style={{
              color: '#0A7AFF',
              marginLeft: '5px',
              textDecoration: 'none',
            }}
          >
            {localize.orders.index.infoTextLink}
          </NavLink>
        </Alert> */}
        {/* <br /> */}
        <Grid container columns={mobile ? 1 : 3}>
          <StyledGridItem item xs={1}>
            <SmallCardForOrder
              type='total_pending'
              title={
                <Typography component='span' variant='h5'>
                  {localize.orders.index.dataLineTotalPending}
                </Typography>
              }
              text={
                <Typography component='span' variant='subtitle2'>
                  {numberToCurrency(totalSumPending)}
                </Typography>
              }
            />
          </StyledGridItem>
          <StyledGridItem item xs={1}>
            <SmallCardForOrder
              type='total_complete'
              title={
                <Typography component='span' variant='h5'>
                  {localize.orders.index.dataLineTotalComplete}
                </Typography>
              }
              text={
                <Typography component='span' variant='subtitle2'>
                  {numberToCurrency(totalSumComplete)}
                </Typography>
              }
            />
          </StyledGridItem>
          <StyledGridItem item xs={1}>
            <SmallCardForOrder
              type='total_canceled'
              title={
                <Typography component='span' variant='h5'>
                  {localize.orders.index.dataLineTotalCanceled}
                </Typography>
              }
              text={
                <Typography component='span' variant='subtitle2'>
                  {numberToCurrency(totalSumCanceled)}
                </Typography>
              }
            />
          </StyledGridItem>
        </Grid>
        <br />
        <br />
        <SearchBar
          onSearch={(smth) => setInitialValue(smth)}
          onFilterButtonClick={() => setOpenFilter((prev) => !prev)}
          anyFiltersApplied={anyFiltersApplied}
          onSearchByDateRange={onSearchByDateRange}
          dateRange={dateRange}
        />
        {openFilter && (
          <Filter
            mainItems={mainItems}
            subItems={subItems}
            anyFiltersApplied={anyFiltersApplied}
            resetFilter={resetFilter}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            applyFilter={setFilterItems}
          />
        )}
        <HorizontalScrollWrapper className='MuiTabs-scroller'>
          <Tabs
            value={activeStatusFilter}
            onChange={(event, newValue) => {
              onFilterItemClick(newValue);
            }}
          >
            {['All', 'Draft', ...statusesToFilterBy].map((el) => (
              <Tab
                key={el}
                value={el}
                sx={{ paddingX: '10px' }}
                label={
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    {localize.order_statuses.values[el]}
                    <NumberText
                      sx={{
                        background:
                          el === activeStatusFilter ? '#C8FFD7' : '#f2f2f2',
                      }}
                    >
                      {el === 'All'
                        ? rowsToShow.length !== rows.length
                          ? rowsToShow.length
                          : rows.length
                        : rowsToShow.length !== rows.length
                          ? rowsToShow.filter(
                              (item: any) => item.last_status === el
                            ).length
                          : rows.filter((item: any) => item.last_status === el)
                              .length}
                    </NumberText>
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </HorizontalScrollWrapper>
        {!!rowsToDisplay.length ? (
          <BasicTable
            rows={rowsToDisplay}
            key={tableKey}
            columns={defaultMainColumns}
            collapsedContentColumns={{ order_items: defaultCollapsedColumns }}
            onRowClick={(row: AnyObject) => navigate(`/cp/orders/${row.id}`)}
            OnCollapsedRowClick={(row: AnyObject) =>
              navigate(`/cp/orders/${row.order_id}`)
            }
          />
        ) : (
          <EmptyContent withImage='box' />
        )}
      </InnerContainer>
    </Page>
  );
};

export default OrderIndexPage;

import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Typography, IconButton, Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { preparedColumns } from '../../utils/table';
import { fieldsForTypes } from '../../utils/template';
import { ProductApiClient } from '../../api/ProductApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import { CommonContext } from '../../contexts/CommonContext';
import Page from '../../components/Page/Page';
import { BasicTable, Column } from '../../components/BaseTable/BaseTable';
import SearchBar from '../../components/SearchBar/SearchBar';
import Filter from '../../components/Filter/Filter';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import localize from '../../localize';
import { useFilter } from '../../hooks/useFilter';
import { useMobile } from '../../hooks/useMobile';
import { NotificationContext } from '../../contexts/NotificationContext';
import { DeleteItem, EditItem } from '../../components/ActionsForTable';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';

const InnerContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

const Heading = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',

  '&>div': {
    marginLeft: '30px',
  },

  a: {
    marginLeft: '10px',
  },
}));

export const ProductIndexPage: FC = () => {
  const defaultCollapsedColumns = [
    {
      field: 'available_count',
      align: 'left',
      headerName: localize.products.columns.availableCount,
      width: '80px',
    },
    {
      field: 'actual_count',
      align: 'left',
      headerName: localize.products.columns.actualCount,
      width: '80px',
    },
  ];
  const expandKey = 'product_modifications';
  const [columns, setColumns] = useState<Column[]>([]);
  const [collapsedContentColumns, setCollapsedContentColumns] = useState<
    AnyObject[]
  >(defaultCollapsedColumns);
  const { currentProject, currentUser, templates } = useContext(CommonContext);
  const { setNotification } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openFilter, setOpenFilter] = useState(false);
  const [isOpenCheckbox, setOpenCheckbox] = useState(false);
  const [isWithPhoto, setWithPhoto] = useState(false);
  const [itemForDelete, setItemForDelete] = useState<AnyObject | null>(null);
  const {
    results: rowsToShow,
    resetFilter,
    mainItems,
    subItems,
    setFilterItems,
    anyFiltersApplied,
    setInitialValue,
  } = useFilter(rows, 'products', expandKey);
  const [tableKey, setTableKey] = useState(322);
  const mobile = useMobile();

  const showActions = useMemo(
    () => currentUser.user_permissions.template?.update?.length !== 0,
    [currentUser.user_permissions, rows]
  );

  const actionsForInstance = (row: any) => {
    const actions = [];
    actions.push(
      <EditItem
        onClick={(row) => {
          navigate(`/cp/products/edit/${row.id}`);
        }}
        key={`edit_item-${row.id}`}
        item={row}
      />,
      <DeleteItem
        onClick={(row) => {
          setItemForDelete(row);
        }}
        key={`delete_item-${row.id}`}
        item={row}
      />
    );
    return actions;
  };

  // const numberOfModifications = useMemo(
  //   () =>
  //     rows
  //       .map((row) => row['product_modifications']?.length)
  //       .reduce((a, b) => a + b, 0),
  //   [rows]
  // );

  // const numberOfOutOfStock = useMemo(
  //   () =>
  //     rows
  //       .map(
  //         (row) =>
  //           row[expandKey]?.filter((pm: any) => !pm?.actual_count)?.length
  //       )
  //       .reduce((a, b) => a + b, 0),
  //   [rows]
  // );

  // const totalProductValue = useMemo(
  //   () =>
  //     rows
  //       .map((row) =>
  //         row[expandKey]
  //           ?.map((pm: any) => pm?.actual_count * pm.price)
  //           .reduce((a: number, b: number) => a + b, 0)
  //       )
  //       .reduce((a, b) => a + b, 0),
  //   [rows]
  // );

  const actions = useMemo(() => {
    const addAction = {
      Component: mobile ? (
        <NavLink to='/cp/products/create' data-cy='link-to-products-create'>
          <Button
            variant='rounded'
            startIcon={<AddIcon sx={{ fill: '#fff' }} />}
          >
            {localize.general.add}
          </Button>
        </NavLink>
      ) : (
        <NavLink to='/cp/products/create' data-cy='link-to-products-create'>
          <IconButton
            sx={{
              padding: '12px 24px',
              background: '#7B75CB',
              borderRadius: '100px',
              '&:hover': { background: '#7B75CB' },
            }}
          >
            <AddIcon sx={{ fill: '#fff' }} />
          </IconButton>
        </NavLink>
      ),
    };
    const editTemplateAction = {
      Component: (
        <NavLink
          to='/cp/templates/edit/product'
          data-cy='link-to-template-edit'
          style={{
            textDecoration: 'none',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: mobile ? '100%' : 'fit-content',
          }}
        >
          <Typography color='#5B4D8F' variant='h5' p='10px'>
            {localize.general.edit}
          </Typography>
        </NavLink>
      ),
    };

    if (!currentUser.user_permissions.product?.create?.length) return [];

    if (currentUser.user_permissions.template?.update?.length)
      return [editTemplateAction, addAction];

    return [addAction];
  }, [currentUser]);

  const preparePageData = async (): Promise<void> => {
    const apiRows = await ProductApiClient.getIndex(currentProject.id);
    setRows(apiRows);

    setColumns([
      ...columns,
      ...preparedColumns(fieldsForTypes(templates, ['Product']) || []),
      {
        ...(showActions
          ? {
              field: 'actions',
              headerName: localize.products.columns.actions,
              align: 'left',
              className: 'column-sticky-right',
              width: mobile ? '85px' : '150px',
              renderCell: (row: any) => actionsForInstance(row),
            }
          : {}),
      },
    ]);

    setCollapsedContentColumns([
      ...collapsedContentColumns,
      ...preparedColumns(
        fieldsForTypes(templates, ['Product modification']) || []
      ),
    ]);
  };

  const deleteItem = () => {
    itemForDelete &&
      ProductApiClient.remove(currentProject.id, itemForDelete.id)
        .then(() => {
          setRows(rows.filter((row) => row.id !== itemForDelete.id));
          setNotification({
            severity: 'success',
            message: localize.general.successDeleteMessage,
          });
          setItemForDelete(null);
        })
        .catch((e) => {
          setNotification({
            severity: 'warning',
            message: localize.general.unexpectedError,
          });
        });
  };

  useEffect(() => {
    if (!templates?.length) return;

    setLoading(true);

    preparePageData().finally(() => setLoading(false));

    return () => {
      setOpenFilter(false);
    };
  }, [templates, currentProject]);

  useEffect(() => {
    setTableKey(Math.random() * 100);
  }, [rowsToShow]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page
      title={
        <Heading>
          <Typography variant='h2'>{localize.products.index.title}</Typography>
        </Heading>
      }
      actions={actions}
    >
      <ConfirmationDialog
        header={`${localize.products.delete.title} ${itemForDelete?.title || 'це'}?`}
        image='/delete-image.svg'
        open={!!itemForDelete}
        description={localize.products.delete.text}
        action={() => deleteItem()}
        discard={() => setItemForDelete(null)}
        mainActionButtonText={localize.products.delete.deleteBtn}
      />
      <InnerContainer>
        <SearchBar
          onSearch={(smth) => setInitialValue(smth)}
          onFilterButtonClick={() => setOpenFilter((prev) => !prev)}
          onCheckboxButtonClick={() => setOpenCheckbox((prev) => !prev)}
          isCheckbox={isOpenCheckbox}
          anyFiltersApplied={anyFiltersApplied || openFilter}
          isWithPhoto={isWithPhoto}
          onChangeWithPhoto={setWithPhoto}
        />
        {openFilter && (
          <Filter
            mainItems={mainItems}
            subItems={subItems}
            anyFiltersApplied={anyFiltersApplied}
            resetFilter={resetFilter}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            applyFilter={setFilterItems}
          />
        )}
        {!!rowsToShow.length ? (
          <BasicTable
            rows={rowsToShow}
            columns={columns}
            key={tableKey}
            isCheckbox={isOpenCheckbox}
            isWithPhoto={isWithPhoto}
            collapsedContentColumns={
              expandKey ? { [expandKey]: collapsedContentColumns } : undefined
            }
            onRowClick={(row: AnyObject) => navigate(`/cp/products/${row.id}`)}
            OnCollapsedRowClick={(row: AnyObject) =>
              navigate(`/cp/products/${row.product_id}`)
            }
          />
        ) : (
          <EmptyContent withImage='box' />
        )}
      </InnerContainer>
    </Page>
  );
};

export default ProductIndexPage;

import { Button, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@mui/material';
import localize from '../../../localize';
import { ReactComponent as LogoTextSvg } from '../../../assets/logoText.svg';
import { ReactComponent as LogoIconSvg } from '../../../assets/logoIcon.svg';
import { Navigation } from './Navigation';
import { Badge } from './Badge';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SeaBlob } from '../../../assets/landing/seaBlob.svg';

export const ContainerInner = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 20px',
  width: '100%',
  maxWidth: '1280px',
  minWidth: '320px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    padding: '0',
    position: 'static',
  },
}));

export const HeaderWrapper = styled('header')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  paddingTop: '25px',
  paddingBottom: '45px',
  [theme.breakpoints.down('lg')]: {
    padding: '20px 0',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0',
  },
}));

export const Logo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  paddingRight: '20px',
  cursor: 'pointer',
  [theme.breakpoints.down('lg')]: {
    paddingRight: '14px',
  },

  '&>svg+svg': {
    marginLeft: '15px',
    width: '72px',
    height: 'auto',
  },
}));

const LogoMainWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}));

const LogoAndHamburgerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  alignItems: 'center',
  transition: 'background-color 0.3s ease',
  position: 'relative',
  zIndex: 10,
  '&.menu-open': {
    backgroundColor: '#fff',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px 10px',
  },
}));

const MenuAndLinksWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    right: 0,
    left: 0,
    bottom: 0,
    top: 'auto',
    width: '100%',
    height: '100vh',
    zIndex: 3,
    backgroundColor: '#DFDDF9',
    backdropFilter: 'blur(8px)',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '95px 0 20px',
    opacity: 0,
    visibility: 'hidden',
    transition: 'all 0.3s ease',
    '&.menu-open': {
      opacity: 1,
      visibility: 'visible',
    },
  },
}));

export const Menu = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'normal',
  gap: '20px',
  [theme.breakpoints.down('lg')]: {
    gap: '10px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '0 40px',
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 20px',
  },
  a: {
    [theme.breakpoints.down('lg')]: {
      padding: '10px 24px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export const MenuBurger = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    width: '24px',
    height: '24px',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    gap: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 3px',
    position: 'relative',
    zIndex: 1000,
    '&.menu-open': {
      '> div': {
        '&:nth-of-type(1)': {
          transform: 'translateY(7px) rotate(45deg)',
        },
        '&:nth-of-type(2)': {
          opacity: 0,
        },
        '&:nth-of-type(3)': {
          transform: 'translateY(-7px) rotate(-45deg)',
        },
      },
    },
  },
}));

export const MenuBurgerLine = styled('div')(({ theme }) => ({
  transition: 'all 0.3s ease',
  display: 'block',
  width: '100%',
  height: '3px',
  position: 'relative',
  borderRadius: '4px',
  backgroundColor: '#464646',
}));

export const SeaBlobSvg = styled(SeaBlob)(({ theme }) => ({
  width: '413px',
  height: '385px',
  position: 'absolute',
  right: 0,
  top: 0,
  transform: 'translate(15%, -40%)',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const navLinks = [
  {
    name: localize.landing.navLinks.about,
    path: '/#about',
  },
  {
    name: localize.landing.navLinks.advantages,
    path: '/#features',
  },
  {
    name: localize.landing.navLinks.price,
    path: '/price',
  },
];
export const Header = () => {
  const location = useLocation();
  const menuRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const handleMenuClick = () => {
    setMenuIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.document) {
      window.document.body.style.overflow = menuIsOpen ? 'hidden' : 'auto';
    }
  }, [menuIsOpen]);

  useEffect(() => {
    let resizeTimeout: ReturnType<typeof setTimeout>;
    const disableTransitionOnResize = () => {
      if (menuRef.current) {
        menuRef.current.style.transition = 'none';
      }
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if (menuRef.current) {
          menuRef.current.style.transition = 'all 0.3s ease';
        }
      }, 300);
    };

    window.addEventListener('resize', disableTransitionOnResize);
    return () =>
      window.removeEventListener('resize', disableTransitionOnResize);
  }, []);

  return (
    <HeaderWrapper>
      <ContainerInner>
        {location.pathname !== '/' && <SeaBlobSvg />}
        <LogoAndHamburgerWrapper className={menuIsOpen ? 'menu-open' : ''}>
          <LogoMainWrapper>
            <Logo onClick={() => navigate('/')}>
              <LogoIconSvg />
              <LogoTextSvg className='logo-text' />
            </Logo>
            <Badge>Beta</Badge>
          </LogoMainWrapper>
          <MenuBurger
            onClick={handleMenuClick}
            className={menuIsOpen ? 'menu-open' : ''}
          >
            <MenuBurgerLine />
            <MenuBurgerLine />
            <MenuBurgerLine />
          </MenuBurger>
        </LogoAndHamburgerWrapper>
        <MenuAndLinksWrapper
          ref={menuRef}
          className={menuIsOpen ? 'menu-open' : ''}
        >
          <Navigation
            links={navLinks}
            menuIsOpen={menuIsOpen}
            setMenuIsOpen={setMenuIsOpen}
          />
          <Menu>
            <Button component={Link} href='/cp' variant='roundedSecondary'>
              {localize.landing.loginButton}
            </Button>
            <Button
              component={Link}
              href='/cp'
              variant='rounded'
              sx={{
                color: '#0c0c0c',
                backgroundColor: '#fff',
                boxShadow: 'none',
              }}
            >
              {localize.landing.signUpButton}
            </Button>
          </Menu>
        </MenuAndLinksWrapper>
      </ContainerInner>
    </HeaderWrapper>
  );
};

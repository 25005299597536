import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from '@mui/material';
import React, { useState } from 'react';
import localize from '../../localize';

type FAQItem = {
  id: string;
  title: string;
  content: string;
};
export const FaqAccordion = ({ data }: { data: FAQItem[] }) => {
  const [expanded, setExpanded] = useState<string | false>(
    localize.profile.edit.support.faq.accordionData[0].id
  );
  const handleAccordionItemChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box>
      {data.map((faq) => (
        <Accordion
          sx={{
            border: 'none',
            fontSize: '12px',
            '&::before': {
              display: 'none',
            },
            '& .MuiCollapse-root': {
              borderBottom: 'none',
            },
          }}
          disableGutters
          elevation={0}
          key={faq.id}
          expanded={expanded === faq.id}
          onChange={handleAccordionItemChange(faq.id)}
        >
          <AccordionSummary
            expandIcon={<></>}
            aria-controls={`${faq.id}-content`}
            id={`${faq.id}-header`}
            sx={{
              border: 'none',
              padding: '0',
              minHeight: '28px',

              '& .MuiAccordionSummary-content': {
                margin: '0',
              },
            }}
          >
            <Typography
              sx={{
                color: expanded === faq.id ? '#0A7AFF' : 'inherit',
                fontWeight: '500',
                fontSize: '12px',
              }}
            >
              {faq.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: '0' }}>
            <Typography
              variant='body1'
              sx={{ fontSize: '12px' }}
              component={'p'}
            >
              {faq.content}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

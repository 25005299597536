import { FC, Suspense, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Box, Tabs, Tab } from '@mui/material';

import Page from '../../components/Page/Page';
import Loading from '../../components/Loading/Loading';
import localize from '../../localize';
import HorizontalScrollWrapper from '../../components/HorizontalScrollWrapper/HorizontalScrollWrapper';

export const ApiKeysIndexPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [value, setValue] = useState(
    location.pathname === '/cp/integrations/api'
      ? '/cp/integrations/api/static-data'
      : location.pathname
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <Page title={localize.api_integration.title}>
      <Box width='100%'>
        <HorizontalScrollWrapper className='MuiTabs-scroller'>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              value='/cp/integrations/api/static-data'
              data-cy='link-to-api-static-data'
              label={localize.api_integration.nav_links.static_data}
            />
            <Tab
              value='/cp/integrations/api/request-form'
              data-cy='link-to-api-request-form'
              label={localize.api_integration.nav_links.request_form}
            />
            <Tab
              value='/cp/integrations/api/manage'
              data-cy='link-to-api-manage-keys'
              label={localize.api_integration.nav_links.manage}
            />
          </Tabs>
        </HorizontalScrollWrapper>
        <Box padding='30px 0 20px'>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </Page>
  );
};

export default ApiKeysIndexPage;

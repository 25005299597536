import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FC, ReactNode } from 'react';
import localize from '../../localize';
import { Divider, IconButton, Button, MenuItem } from '@mui/material';
import { ReactComponent as MoreItemIconSvg } from '../../assets/more-item.svg';
import CloseIcon from '@mui/icons-material/Close';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    data-cy='page-menu'
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

type PageMenuItems = {
  title?: ReactNode;
  onClick?(): void;
  divider?: boolean;
  Component?: ReactNode;
};

type PageMenuProps = {
  title?: ReactNode;
  menuItems: PageMenuItems[];
  isRounded?: boolean;
  isIconBtn?: boolean;
};

const PageMenu: FC<PageMenuProps> = ({
  title,
  menuItems,
  isRounded,
  isIconBtn = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!menuItems?.length) return null;

  return (
    <div>
      {isIconBtn ? (
        <IconButton
          id='demo-customized-icon-button'
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            background: '#AFABEF',
            color: '#fff',
            borderRadius: '2px',
            padding: '4px',
            '&:hover, &:active, &:focus': { background: '#AFABEF' },
            '&>*': { width: '24px', height: '24px' },
          }}
        >
          {open ? (
            <CloseIcon sx={{ border: '1px solid #fff', borderRadius: '50%' }} />
          ) : (
            <MoreItemIconSvg fill='#fff' />
          )}
        </IconButton>
      ) : (
        <Button
          id='demo-customized-button'
          data-cy='demo-customized-button-menu'
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          variant={isRounded ? 'rounded' : 'outlined'}
          fullWidth={isRounded}
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon sx={{ fill: '#fff' }} />}
        >
          {title || localize.pageMenu.options}
        </Button>
      )}
      <StyledMenu
        id='demo-customized-menu'
        data-cy='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.map((menuItem, i) => {
          if (menuItem.divider)
            return <Divider sx={{ my: 0.5 }} key={`divider-${i}`} />;

          return menuItem.Component ? (
            <MenuItem sx={{ '&>*': { width: '100%' } }} key={`component-${i}`}>
              {menuItem.Component}
            </MenuItem>
          ) : (
            <MenuItem
              key={`${menuItem.title}-${i}`}
              data-cy={`${menuItem.title ? menuItem.title : ''}-menu-item`}
              onClick={(e) => {
                handleClose();
                menuItem?.onClick && menuItem?.onClick();
              }}
              disableRipple
            >
              {menuItem.title}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
};

export default PageMenu;

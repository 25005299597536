import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Page from '../../components/Page/Page';
import { useNavigate } from 'react-router';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { CustomerApiClient } from '../../api/CustomerApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import { useAsyncError } from '../../hooks/useAsyncError';
import localize from '../../localize';
import { baseMainFields } from './ClientShowPage';

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

export const ClientIndexPage: FC = () => {
  const { currentProject, currentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const navigate = useNavigate();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const actions = useMemo(() => {
    const addAction = { title: 'Додати клієнта', link: `/cp/clients/create` };

    return [addAction];
  }, [currentUser]);

  const preparePageData = async (): Promise<void> => {
    const apiRows = await CustomerApiClient.getIndex(currentProject.id).catch(
      (e) => {
        throwError(e);
      }
    );

    setRows(apiRows || []);
  };

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
  }, [currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title='Клієнти' actions={actions}>
      <InnerContainer>
        {!!rows.length ? (
          <BasicTable
            rows={rows}
            columns={baseMainFields}
            onRowClick={(row: AnyObject) => navigate(`/cp/clients/${row.id}`)}
            OnCollapsedRowClick={(row: AnyObject) =>
              navigate(`/cp/clients/${row.id}`)
            }
          />
        ) : (
          <EmptyContent withImage='box' />
        )}
      </InnerContainer>
    </Page>
  );
};

export default ClientIndexPage;

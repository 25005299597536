import { FC, useContext, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Alert,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Divider,
} from '@mui/material';
import FormField from '../../components/FormField/FormField';
import { User, UserApiClient } from '../../api/UserApiClient';
import { CommonContext } from '../../contexts/CommonContext';
import { Customer, CustomerApiClient } from '../../api/CustomerApiClient';
import { Promotion, PromotionApiClient } from '../../api/PromotionApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import Loading from '../../components/Loading/Loading';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import DeliveryFields from './DeliveryFields';
import { Delivery } from '../../api/OrderApiClient';
import NotRequiredLabel from './NotRequiredLabel';
import localize from '../../localize';
import { useMobile } from '../../hooks/useMobile';

type ClientCustomerFieldsProps = {
  fixedClientDiscount: number;
  fixedCustomerDiscount: number;
  setFixedCustomerDiscount: (num: number) => void;
  setFixedClientDiscount: (num: number) => void;
  client?: User;
  setClient: (user: User) => void;
  customer?: Customer;
  setCustomer: (customer: Customer | undefined) => void;
  promotion?: Promotion;
  setPromotion: (promotion: Promotion | undefined) => void;
  delivery?: Delivery;
  setDelivery: (user: Delivery) => void;
  setHideBtn?: (hideBtn: boolean) => void;
  nextStep?: boolean;
};

const DataFillContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: '100%',
  justifyContent: 'flex-start',

  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    bottom: '-20px',
    left: '10px',
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '&>*': {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

export const ClientCustomerFields: FC<ClientCustomerFieldsProps> = ({
  client,
  setClient,
  customer,
  setCustomer,
  promotion,
  setPromotion,
  fixedClientDiscount,
  setFixedClientDiscount,
  fixedCustomerDiscount,
  setFixedCustomerDiscount,
  delivery,
  setDelivery,
  setHideBtn,
  nextStep,
}) => {
  const { currentUser, currentProject } = useContext(CommonContext);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingPromotions, setLoadingPromotions] = useState(true);
  const [users, setUsers] = useState<User[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const [isNewCustomer, setIsNewCustomer] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const mobile = useMobile();

  const fetchUsers = async () => {
    setLoadingUsers(true);
    const res = await UserApiClient.getIndex(currentProject?.id);
    setUsers(res);
    setLoadingUsers(false);
    setHideBtn && setHideBtn(false);
  };

  const fetchCustomers = async (params?: AnyObject) => {
    setLoadingCustomers(true);
    const res = await CustomerApiClient.getIndex(currentProject?.id, params);
    setCustomers(res);
    setLoadingCustomers(false);
  };

  const fetchPromotions = async (params?: AnyObject) => {
    setLoadingPromotions(true);
    const res = await PromotionApiClient.getIndex(currentProject?.id, params);
    setPromotions(res);
    setLoadingPromotions(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const params: AnyObject = {};
    !client?.manager_id
      ? (params.user_id = client?.id)
      : (params.visible_for_all = true);

    fetchCustomers(params);
    fetchPromotions(params);
  }, [client?.id]);

  useEffect(() => {
    if (loadingCustomers) return;

    if (customer?.id && !customers.map((c) => c.id).includes(customer?.id)) {
      setCustomer(undefined);
    }
  }, [loadingCustomers, customers]);

  useEffect(() => {
    if (loadingPromotions) return;

    if (!promotions.map((c) => c.id).includes(promotion?.id || '')) {
      setPromotion(undefined);
    }
  }, [loadingPromotions, promotions]);

  const clientIsCurrentUser = useMemo(
    () => !client?.auth0_id || client?.auth0_id === currentUser?.auth0_id,
    [client, currentUser]
  );
  const clientRoleIsNotClient = useMemo(
    () =>
      !client?.auth0_id
        ? currentUser?.role !== 'Client'
        : client?.role !== 'Client',
    [client, currentUser]
  );
  const discountApplied = useMemo(() => {
    const res = [];
    customer?.personal_discount && res.push(`"Особиста знижка"`);
    promotion && res.push(`промо-акція "${promotion.title}"`);
    return res;
  }, [customer?.personal_discount, promotion]);

  const autocompleteOptions = useMemo(() => {
    if (!users.length) return [];

    return users.map((user) => ({
      label: `${user.name}${currentUser?.auth0_id === user.auth0_id ? ' (Ви)' : ''}`,
      id: user.auth0_id,
      user,
    }));
  }, [users]);

  const customersAutocompleteOptions = useMemo(() => {
    if (!customers.length) return [];

    return customers.map((customer) => ({
      label: `${customer.name} (${customer.personal_discount}%) - ${customer.phone_number} - ${customer.email}`,
      id: customer.id,
      customer,
    }));
  }, [customers]);

  const promotionsAutocompleteOptions = useMemo(() => {
    if (!promotions.length) return [];

    return promotions.map((promotion) => ({
      label: `${promotion.title}`,
      id: promotion.id,
      promotion,
    }));
  }, [promotions]);

  useEffect(() => {
    setFixedClientDiscount(client?.discount || 0);
  }, [client]);

  useEffect(() => {
    setFixedCustomerDiscount(customer?.personal_discount || 0);
  }, [customer]);

  if (loadingUsers) return <Loading />;

  return (
    <Card
      sx={{
        border: '1px solid #DFDDF9',
        borderRadius: '8px',
        padding: mobile ? '8px' : '8px 24px',
      }}
    >
      <Accordion
        expanded={nextStep ? isOpen : true}
        onChange={() => setIsOpen((prev) => !prev)}
        sx={{
          '& .MuiCollapse-root.MuiCollapse-entered': { border: 'none' },
          '& .MuiAccordionSummary-content': { margin: '0' },
        }}
      >
        <AccordionSummary expandIcon={nextStep ? <ExpandMoreIcon /> : <></>}>
          <CardHeader
            sx={{ padding: '0' }}
            title={localize.orders.form.customerCard.buyer}
          />
        </AccordionSummary>
        <AccordionDetails>
          <CardContent sx={{ padding: '0' }}>
            <Stack width='100%' pb='30px'>
              {currentUser?.role === 'Client' ? (
                <Box sx={{ display: 'flex', minWidth: '100%', p: 1 }}>
                  <Typography variant='body1'>
                    {localize.orders.form.customerCard.buyerDiscount}{' '}
                    <b>{currentUser.discount}%</b>
                  </Typography>
                </Box>
              ) : (
                users.length > 1 && (
                  <DataFillContainer>
                    <FormField
                      sx={mobile ? { width: '100%' } : { flex: 1 }}
                      value={client?.auth0_id || ''}
                      label={
                        localize.orders.form.customerCard.selectDropShipper
                      }
                      name='client_id'
                      required={true}
                      onChange={(e) => {}}
                      loading={loadingUsers}
                      autocomplete={{
                        options: autocompleteOptions,
                        onChange: (_, newValue: any) => {
                          setClient(newValue?.user || undefined);
                        },
                      }}
                    />
                    <FormField
                      sx={{ width: mobile ? '100%' : '40%' }}
                      value={(fixedClientDiscount as unknown as string) || ''}
                      label={
                        localize.orders.form.customerCard.dropShipperDiscount
                      }
                      loading={loadingUsers}
                      fieldType='number'
                      name='fixed_client_discount'
                      onChange={(e) => setFixedClientDiscount(e.target.value)}
                    />
                  </DataFillContainer>
                )
              )}
              <Divider sx={{ borderColor: '#DFDDF9', margin: '20px 0' }} />
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                mb='10px'
              >
                <CustomSwitch
                  checkedOption={
                    localize.orders.form.customerCard.existingBuyer
                  }
                  option={localize.orders.form.customerCard.newBuyer}
                  onChange={(checked) => {
                    setIsNewCustomer(!checked);
                  }}
                  checked={!isNewCustomer}
                  firstOptionWidth={150}
                />
                {!mobile && <NotRequiredLabel />}
              </Stack>
              {!isNewCustomer ? (
                <DataFillContainer>
                  <FormField
                    sx={mobile ? { width: '100%' } : { flex: 1 }}
                    loading={loadingCustomers}
                    value={customer?.id || ''}
                    label={localize.orders.form.customerCard.selectBuyer}
                    name='customer_id'
                    required={true}
                    onChange={(e) => {}}
                    autocomplete={{
                      options: customersAutocompleteOptions,
                      onChange: (_, newValue: any) => {
                        setCustomer(newValue?.customer || undefined);
                      },
                    }}
                  />
                  <FormField
                    sx={{ width: mobile ? '100%' : '40%' }}
                    value={(fixedCustomerDiscount as unknown as string) || ''}
                    loading={loadingCustomers}
                    label={localize.orders.form.customerCard.customerDiscount}
                    fieldType='number'
                    name='personal_discount'
                    onChange={(e) => setFixedCustomerDiscount(e.target.value)}
                  />
                </DataFillContainer>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    minWidth: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <DataFillContainer>
                    <FormField
                      sx={mobile ? { width: '100%' } : { flex: 1 }}
                      value={customer?.name || ''}
                      label={localize.orders.form.customerCard.selectBuyer}
                      name='name'
                      onChange={(e) =>
                        setCustomer({
                          ...(customer || {}),
                          name: e.target.value,
                        })
                      }
                    />
                    <FormField
                      sx={{ width: mobile ? '100%' : '40%' }}
                      value={
                        (customer?.personal_discount as unknown as string) || ''
                      }
                      label={localize.orders.form.customerCard.customerDiscount}
                      fieldType='number'
                      name='personal_discount'
                      onChange={(e) =>
                        setCustomer({
                          ...(customer || {}),
                          personal_discount: e.target.value,
                        })
                      }
                    />
                  </DataFillContainer>
                  <DataFillContainer>
                    <FormField
                      sx={mobile ? { width: '100%' } : { flex: 1 }}
                      value={customer?.email || ''}
                      label={localize.orders.form.customerCard.buyerEmail}
                      name='email'
                      onChange={(e) =>
                        setCustomer({
                          ...(customer || {}),
                          email: e.target.value,
                        })
                      }
                    />
                    <FormField
                      sx={{ width: mobile ? '100%' : '40%' }}
                      value={customer?.phone_number || ''}
                      label={localize.orders.form.customerCard.buyerPhone}
                      name='phone_number'
                      onChange={(e) =>
                        setCustomer({
                          ...(customer || {}),
                          phone_number: e.target.value,
                        })
                      }
                    />
                  </DataFillContainer>
                </Box>
              )}
              {promotionsAutocompleteOptions.length ? (
                <Box
                  sx={{
                    display: 'flex',
                    minWidth: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <Divider sx={{ borderColor: '#DFDDF9', margin: '20px 0' }} />
                  <Stack flexDirection='row' justifyContent='space-between'>
                    <Typography variant='h5' color='#7B75CB'>
                      {localize.orders.form.customerCard.additionalDiscount}
                    </Typography>
                    {!mobile && <NotRequiredLabel />}
                  </Stack>
                  <Box sx={{ display: 'flex', minWidth: '100%' }}>
                    <FormField
                      sx={mobile ? { width: '100%' } : { flex: 1 }}
                      value={promotion?.id || ''}
                      loading={loadingPromotions}
                      label={localize.orders.form.customerCard.selectPromotion}
                      name='promotion_id'
                      onChange={(e) => {}}
                      autocomplete={{
                        options: promotionsAutocompleteOptions,
                        onChange: (_, newValue: any) => {
                          setPromotion(newValue?.promotion || undefined);
                        },
                      }}
                    />
                  </Box>
                </Box>
              ) : null}
              {discountApplied.length ? (
                <Alert severity='info'>
                  {clientRoleIsNotClient
                    ? clientIsCurrentUser
                      ? localize.orders.form.customerCard.discountAppliedSelf
                      : localize.orders.form.customerCard
                          .discountAppliedOtherManager
                    : clientIsCurrentUser
                      ? localize.orders.form.customerCard.discountNotAppliedSelf
                      : localize.orders.form.customerCard
                          .discountNotAppliedOtherUser}
                </Alert>
              ) : null}
              <Divider sx={{ borderColor: '#DFDDF9', margin: '20px 0' }} />
              <DeliveryFields
                delivery={delivery}
                setDelivery={setDelivery}
                customer={customer}
              />
            </Stack>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default ClientCustomerFields;

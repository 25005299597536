import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import { Tab, Tabs, Typography, Button } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { CommonContext } from '../../contexts/CommonContext';
import { ManufactureRequestApiClient } from '../../api/ManufactureRequestApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import Page from '../../components/Page/Page';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import Loading from '../../components/Loading/Loading';
import ManufactureCreateForm from '../../components/ManufactureCreateForm/ManufactureCreateForm';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import SearchBar from '../../components/SearchBar/SearchBar';
import Filter from '../../components/Filter/Filter';
import { useAsyncError } from '../../hooks/useAsyncError';
import localize from '../../localize';
import { formatDateTime } from '../../utils/time';
import { useFilter } from '../../hooks/useFilter';
import HorizontalScrollWrapper from '../../components/HorizontalScrollWrapper/HorizontalScrollWrapper';

const InnerContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

const NumberText = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '9px',
  lineHeight: '11px',
  fontWeight: 700,
  color: '#464646',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  marginLeft: '5px',
}));

export const ManufactureRequestIndexPage: FC = () => {
  const defaultColumns = [
    {
      field: 'created_at',
      align: 'right',
      headerName: localize.manufacture_requests.columns.created_at,
      valueGetter: (row: any) =>
        row.created_at && formatDateTime(row.created_at),
    },
    {
      field: 'user_name',
      align: 'left',
      headerName: localize.manufacture_requests.columns.user_name,
      type: 'string',
    },
    {
      field: 'warehouse_flow',
      align: 'left',
      headerName: localize.manufacture_requests.columns.warehouse_flow,
      valueGetter: (row: any) => (
        <Typography
          component='span'
          variant='h5'
          color={row.warehouse_flow === 'income' ? '#53C17F' : '#FFBF1C'}
        >
          {localize.manufacture_requests.index.status[row.warehouse_flow]}
        </Typography>
      ),
    },
    {
      field: 'comment',
      align: 'left',
      headerName: localize.manufacture_requests.columns.comment,
    },
  ];
  const defaultCollapsedColumns = [
    {
      field: 'product_title',
      align: 'left',
      headerName: localize.manufacture_requests.columns.product_title,
    },
    {
      field: 'product_modification_title',
      align: 'left',
      headerName:
        localize.manufacture_requests.columns.product_modification_title,
    },

    {
      field: 'count',
      align: 'left',
      headerName: localize.manufacture_requests.columns.count,
    },
  ];

  const expandKey = 'product_modifications';
  const [collapsedContentColumns, setCollapsedContentColumns] = useState<
    AnyObject[]
  >(defaultCollapsedColumns);
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState(false);

  const { currentProject, currentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const [activeStatusFilter, setActiveStatusFilter] = useState('all');

  const actions = useMemo(() => {
    const addAction = {
      Component: (
        <Button
          variant='transparent'
          data-cy='manufacture-open-form-btn'
          startIcon={<AddIcon sx={{ fill: '#5B4D8F' }} />}
          onClick={() => setOpenModal(true)}
        >
          {localize.manufacture_requests.index.addButton}
        </Button>
      ),
    };

    if (!currentUser.user_permissions.manufacturerequest?.create?.length)
      return [];

    return [addAction];
  }, [currentUser]);

  const {
    results: rowsToShow,
    resetFilter,
    mainItems,
    subItems,
    dateRange,
    setFilterItems,
    anyFiltersApplied,
    setInitialValue,
    onSearchByDateRange,
  } = useFilter(rows, 'manufacture');

  const rowsToDisplay = useMemo(
    () =>
      activeStatusFilter === 'all'
        ? rowsToShow
        : rowsToShow.filter(
            (item: AnyObject) => item.warehouse_flow === activeStatusFilter
          ),
    [rowsToShow, activeStatusFilter]
  );

  const preparePageData = async (): Promise<void> => {
    const apiRows = await ManufactureRequestApiClient.getIndex(
      currentProject.id
    ).catch((e) => {
      throwError(e);
    });

    setRows(apiRows || []);
  };

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
    setCollapsedContentColumns([...collapsedContentColumns]);
  }, [currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.manufacture_requests.index.title} actions={actions}>
      <ManufactureCreateForm
        open={openModal}
        discard={() => setOpenModal(false)}
      />
      <InnerContainer>
        <SearchBar
          onSearch={(smth: string) => setInitialValue(smth)}
          onFilterButtonClick={() => setOpenFilter((prev) => !prev)}
          anyFiltersApplied={anyFiltersApplied || openFilter}
          onSearchByDateRange={onSearchByDateRange}
          dateRange={dateRange}
        />
        {openFilter && (
          <Filter
            mainItems={mainItems}
            subItems={subItems}
            anyFiltersApplied={anyFiltersApplied}
            resetFilter={resetFilter}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            applyFilter={setFilterItems}
          />
        )}
        <HorizontalScrollWrapper className='MuiTabs-scroller'>
          <Tabs
            value={activeStatusFilter}
            onChange={(event, newValue) => {
              setActiveStatusFilter(newValue);
            }}
          >
            {['all', 'income', 'outcome'].map((el) => (
              <Tab
                key={el}
                value={el}
                label={
                  <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                    {localize.manufacture_requests.index.status[el]}
                    <NumberText
                      sx={{
                        background:
                          el === activeStatusFilter ? '#C8FFD7' : '#f2f2f2',
                      }}
                    >
                      {el === 'all'
                        ? rowsToShow.length
                        : rowsToShow.filter(
                            (item: AnyObject) => item.warehouse_flow === el
                          ).length}
                    </NumberText>
                  </Typography>
                }
              />
            ))}
          </Tabs>
        </HorizontalScrollWrapper>
        <br />
        {!!rows.length ? (
          <BasicTable
            rows={rowsToDisplay}
            columns={defaultColumns}
            collapsedContentColumns={
              expandKey ? { [expandKey]: collapsedContentColumns } : undefined
            }
          />
        ) : (
          <EmptyContent withImage='box' />
        )}
      </InnerContainer>
    </Page>
  );
};

export default ManufactureRequestIndexPage;

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ProjectApiClient } from '../../../api/ProjectApiClient';
import { StyledInput } from '../../../components/StyledInput';
import { CommonContext } from '../../../contexts/CommonContext';
import { useOnboardingMultiStep } from '../../../contexts/OnboardingContext';
import { useAsyncError } from '../../../hooks/useAsyncError';
import localize from '../../../localize';

import {
  ButtonsBox,
  MainDescription,
  MainTitle,
  OnboaringPageContainer,
} from '../OnboardingPage';
import ShowStepNumbers from '../ShowStepNumbers';

const PageInnerContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: '751px',
  width: '100%',
  margin: '0 auto',
  flexDirection: 'column',
  padding: '0 20px',
  alignItems: 'center',
  justifyContent: 'center',
}));

const HeadingBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
  padding: '0 32px',
}));

const UserForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '54px',
}));

const MainFieldsBox = styled('div')(() => ({
  marginTop: '20px',
}));

const InputsGroup = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '24px',
  rowGap: '12px',
  alignItems: 'center',
  marginTop: '20px',
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
  },
}));

type LegalType = 'ФОП' | 'ТОВ';

type LegalData = {
  contactName: string;
  legalName: string;
  edrpou: string;
  iban: string;
};

export const ManufacturerLegalInfo = () => {
  const isValidEdrpou = useCallback((edrpou: string) => {
    const edrpouRegex = /^[0-9]{8}$/;
    return edrpouRegex.test(edrpou);
  }, []);

  const isValidIban = useCallback((iban: string) => {
    const ibanRegex = /^UA\d{2}\d{25}$/;
    return ibanRegex.test(iban);
  }, []);

  const [legalType, setLegalType] = useState<LegalType>('ФОП');
  const [isFormValid, setIsFormValid] = useState(false);
  const [legalData, setLegalData] = useState<LegalData>({
    contactName: '',
    legalName: '',
    edrpou: '',
    iban: '',
  });

  const { step, getTotalSteps, nextStep, prevStep } = useOnboardingMultiStep();
  const { currentProject } = useContext(CommonContext);
  const throwError = useAsyncError();
  const theme = useTheme();

  const legalTypeData = useMemo(
    () => ({ ...legalData, legalType: legalType === 'ФОП' ? 'ФОП' : 'ТОВ' }),
    [legalType, legalData]
  );

  useEffect(() => {
    if (currentProject?.id) {
      setLegalType(currentProject.legal_type);
      setLegalData({
        legalName: currentProject.legal_name,
        contactName: currentProject.contact_person,
        edrpou: currentProject.edropoy,
        iban: currentProject.iban,
      });
    }
  }, [currentProject]);

  useEffect(() => {
    const isValidForm =
      !!legalTypeData.legalName &&
      !!legalTypeData.contactName &&
      (!!legalTypeData.edrpou ? isValidEdrpou(legalTypeData.edrpou) : true) &&
      (!!legalTypeData.iban ? isValidIban(legalTypeData.iban) : true);
    setIsFormValid(isValidForm);
  }, [
    isValidEdrpou,
    isValidIban,
    legalTypeData.contactName,
    legalTypeData.edrpou,
    legalTypeData.iban,
    legalTypeData.legalName,
  ]);

  const onSubmit = async () => {
    try {
      await ProjectApiClient.update(currentProject.id, {
        project: {
          legal_type: !!legalTypeData.legalType
            ? legalTypeData.legalType
            : undefined,
          legal_name: !!legalTypeData.legalName
            ? legalTypeData.legalName
            : undefined,
          contact_person: !!legalTypeData.contactName
            ? legalTypeData.contactName
            : undefined,
          edropoy: !!legalTypeData.edrpou ? legalTypeData.edrpou : undefined,
          iban: !!legalTypeData.iban ? legalTypeData.iban : undefined,
        },
      });

      nextStep();
    } catch (e) {
      throwError(e);
    }
  };
  const onLegalTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLegalType((event.target as HTMLInputElement).value as LegalType);
  };

  return (
    <OnboaringPageContainer>
      <PageInnerContainer>
        <ShowStepNumbers currentStep={step} totalSteps={getTotalSteps()} />
        <HeadingBox>
          <MainTitle>{localize.onboarding.companyInfoTitle}</MainTitle>
          <MainDescription sx={{ margin: '0' }}>
            {localize.onboarding.fopTovDescription}
          </MainDescription>
          <UserForm>
            <FormControl sx={{ width: '100%' }}>
              <RadioGroup
                color='primary'
                aria-labelledby='legal-type-group-label'
                value={legalType}
                onChange={onLegalTypeChange}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  columnGap: '56px',
                  width: '100%',
                  justifyContent: 'center',
                }}
                name='legal-type-radio-buttons-group'
              >
                <FormControlLabel
                  value='ФОП'
                  control={<Radio color='info' />}
                  label='ФОП'
                />
                <FormControlLabel
                  value='ТОВ'
                  control={<Radio color='info' />}
                  label='ТОВ'
                />
              </RadioGroup>

              <MainFieldsBox>
                <InputsGroup>
                  <LabelGroup>
                    <label htmlFor='legalName'>
                      {legalType === 'ФОП'
                        ? localize.onboarding.formLabels.fopLegalName
                        : localize.onboarding.formLabels.tovLegalName}
                      <span className='red-star'>*</span>
                    </label>
                    <StyledInput
                      id='legalName'
                      value={legalData.legalName}
                      placeholder='Введіть текст'
                      helperText={
                        legalData.legalName === '' ? "Поле обов'язкове" : ' '
                      }
                      name='legalName'
                      onChange={(event) =>
                        setLegalData({
                          ...legalData,
                          legalName: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                  <LabelGroup>
                    <label htmlFor='contactName'>
                      {localize.onboarding.formLabels.contactName}
                      <span className='red-star'>*</span>
                    </label>
                    <StyledInput
                      id='contactName'
                      value={legalData.contactName}
                      placeholder='Введіть Прізвище'
                      name='contactName'
                      helperText={
                        legalData.contactName === '' ? "Поле обов'язкове" : ' '
                      }
                      onChange={(event) =>
                        setLegalData({
                          ...legalData,
                          contactName: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>

                  <LabelGroup>
                    <label htmlFor='edrpou'>
                      {localize.onboarding.formLabels.edrpou}
                      <span className='grey-text'>
                        {localize.onboarding.formLabels.notRequired}
                      </span>
                    </label>
                    <StyledInput
                      id='edrpou'
                      value={legalData.edrpou}
                      name='edrpou'
                      placeholder='Введіть ЄДРПОУ'
                      error={
                        !isValidEdrpou(legalData.edrpou) &&
                        legalData.edrpou !== ''
                      }
                      helperText={
                        legalData.edrpou === ''
                          ? ' '
                          : isValidEdrpou(legalData.edrpou)
                            ? ' '
                            : 'ЄДРПОУ має містити 8 цифр'
                      }
                      onChange={(event) =>
                        setLegalData({
                          ...legalData,
                          edrpou: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                  <LabelGroup>
                    <label htmlFor='iban'>
                      IBAN <span className='grey-text'>(Не обов'язково)</span>
                    </label>
                    <StyledInput
                      id='iban'
                      value={legalData.iban}
                      placeholder='Рахунок складається з 27 цифр'
                      InputProps={{
                        sx: {
                          fontSize: '16px',
                          '&::placeholder': {
                            opacity: '1',
                            fontSize: '16px',
                          },
                        },
                      }}
                      helperText={
                        legalData.iban === ''
                          ? ' '
                          : isValidIban(legalData.iban)
                            ? ' '
                            : 'Має починатися з UA та містити 27 цифр'
                      }
                      error={
                        !isValidIban(legalData.iban) && legalData.iban !== ''
                      }
                      name='iban'
                      onChange={(event) =>
                        setLegalData({
                          ...legalData,
                          iban: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                </InputsGroup>
                <Typography
                  variant='body2'
                  sx={{ fontFamily: '"Montserrat", sans-serif' }}
                  color={theme.palette.info.main}
                >
                  {localize.onboarding.formBottomText}
                </Typography>
              </MainFieldsBox>
            </FormControl>
          </UserForm>
        </HeadingBox>

        <ButtonsBox>
          <Button
            variant='rounded'
            data-cy='manufacturer-legal-info-next-btn'
            onClick={onSubmit}
            disabled={!isFormValid}
            sx={{
              padding: '14px 44px',
            }}
          >
            {localize.onboarding.indexPage.nextButton}
          </Button>
          <Button
            variant='transparent'
            data-cy='manufacturer-legal-info-prev-btn'
            onClick={prevStep}
          >
            {localize.onboarding.indexPage.prevButton}
          </Button>
        </ButtonsBox>
      </PageInnerContainer>
    </OnboaringPageContainer>
  );
};

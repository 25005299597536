import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import localize from '../../localize';
import { CustomAlert } from '../../components/CustomAlert';
import { CommonContext } from '../../contexts/CommonContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useAsyncError } from '../../hooks/useAsyncError';
import { UserApiClient } from '../../api/UserApiClient';
import { TextField } from '@mui/material';

const DropshipperForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  flexDirection: 'column',
  margin: '53px 0',
}));

const InputsGroup = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'center',
  marginTop: '20px',
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
    '&.purple-text-label': {
      color: '#8D83FE',
    },
    '': {
      padding: '0',
    },
  },
}));

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '53px',
}));

export const ButtonsBox = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-start',
}));

type LegalType = 'ФОП' | 'Фізична особа';

type FormData = {
  legalName: string;
  coopTerms: string;
  edrpou: string;
  iban: string;
};

export const DropshipperProfile = () => {
  const { currentUser, setCurrentUser } = useContext(CommonContext);
  const [submitting, setSubmitting] = useState(false);
  const throwError = useAsyncError();
  const { setNotification } = useContext(NotificationContext);

  const initFormData = {
    legalName: currentUser?.drop_legal_name || '',
    coopTerms: currentUser?.drop_description || '',
    edrpou: currentUser?.edropoy_code || '',
    iban: currentUser?.iban || '',
  };
  const [formData, setFormData] = useState<FormData>(initFormData);
  const [legalType, setLegalType] = useState<LegalType>('ФОП');
  const isValidEdrpou = useCallback((edrpou: string) => {
    const edrpouRegex = /^[0-9]{8}$/;
    return edrpouRegex.test(edrpou);
  }, []);

  const isValidIban = useCallback((iban: string) => {
    const ibanRegex = /^UA\d{2}\d{25}$/;
    return ibanRegex.test(iban);
  }, []);

  const onLegalTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLegalType((event.target as HTMLInputElement).value as LegalType);
  };
  const legalTypeData = useMemo(() => {
    return legalType === 'ФОП'
      ? { ...formData, legalType: 'ФОП' }
      : { ...formData, legalType: 'Фізична особа' };
  }, [legalType, formData]);

  const [isFormValid, setIsFormValid] = useState(false);

  const setInitFormData = useCallback(() => {
    setLegalType(currentUser?.drop_legal_type || 'ФОП');
    setFormData({
      legalName: currentUser?.drop_legal_name || '',
      coopTerms: currentUser?.drop_description || '',
      edrpou: currentUser?.edropoy_code || '',
      iban: currentUser?.iban || '',
    });
  }, [currentUser]);

  const hasFormChanged = useMemo(() => {
    return (
      JSON.stringify(formData) !== JSON.stringify(initFormData) ||
      legalType !== currentUser?.drop_legal_type
    );
  }, [currentUser?.drop_legal_type, formData, initFormData, legalType]);

  useEffect(() => {
    if (currentUser?.auth0_id) {
      setInitFormData();
    }
  }, [currentUser?.auth0_id, setInitFormData]);

  useEffect(() => {
    const validForm =
      formData.legalName !== '' &&
      (formData.edrpou === '' || isValidEdrpou(formData.edrpou)) &&
      (formData.iban === '' || isValidIban(formData.iban));
    setIsFormValid(validForm);
  }, [
    formData.edrpou,
    formData.iban,
    formData.legalName,
    isValidEdrpou,
    isValidIban,
  ]);

  const onSubmit = async () => {
    setSubmitting(true);
    try {
      const updatedUser = (await UserApiClient.updateSelf(
        {
          user: {
            drop_legal_type: legalTypeData.legalType,
            drop_description: legalTypeData.coopTerms,
            edropoy_code: legalTypeData.edrpou,
            iban: legalTypeData.iban,
            drop_legal_name:
              legalTypeData.legalType === 'ФОП' ? legalTypeData.legalName : '',
          },
        },
        currentUser?.auth0_id as string
      )) as any;

      setCurrentUser({
        ...currentUser,
        drop_legal_type: updatedUser.drop_legal_type,
        drop_description: updatedUser.drop_description,
        edropoy_code: updatedUser.edropoy_code,
        iban: updatedUser.iban,
        drop_legal_name: updatedUser.drop_legal_name,
      });

      setNotification({
        severity: 'success',
        message: localize.general.successUpdateMessage,
      });
    } catch (error) {
      throwError(error);
      setNotification({
        severity: 'error',
        message: localize.general.unexpectedError,
      });
    } finally {
      setSubmitting(false);
      window.location.reload();
    }
  };
  return (
    <Grid item xs={12}>
      <CustomAlert>
        <Typography fontSize={'12px'} color='#0C0C0C'>
          {localize.profile.edit.dropshipperProfile.alert.firstLine} <br />
          {localize.profile.edit.dropshipperProfile.alert.secondLine}
          {/* TODO додати лінк на документацію */}
          {/* <Link href='#' color={theme.palette.info.main}>
            {localize.profile.edit.dropshipperProfile.alert.documentation}
          </Link> */}
        </Typography>
      </CustomAlert>
      <DropshipperForm>
        <StyledFormControl sx={{ maxWidth: '512px', rowGap: '12px' }}>
          <RadioGroup
            color='primary'
            aria-labelledby='legal-type-group-label'
            value={legalType}
            onChange={onLegalTypeChange}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '56px',
              width: '100%',
              justifyContent: 'flex-start',
            }}
            name='legal-type-radio-buttons-group'
          >
            <FormControlLabel
              value='ФОП'
              control={<Radio color='info' />}
              label={localize.profile.edit.dropshipperProfile.labels.fop}
            />
            <FormControlLabel
              value='Фізична особа'
              control={<Radio color='info' />}
              label={localize.profile.edit.dropshipperProfile.labels.individual}
            />
          </RadioGroup>
          <InputsGroup>
            {legalType === 'ФОП' && (
              <LabelGroup>
                <label htmlFor='legalName'>
                  {localize.profile.edit.dropshipperProfile.labels.fopName}
                </label>
                <TextField
                  id='legalName'
                  value={formData.legalName}
                  placeholder={localize.profile.edit.placeholderText}
                  helperText={' '}
                  name='legalName'
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      legalName: event.target.value,
                    })
                  }
                />
              </LabelGroup>
            )}
            <LabelGroup>
              <label htmlFor='edrpou'>
                {localize.profile.edit.paymentSettings.labels.edrpou}{' '}
                <span className='grey-text'>
                  {localize.profile.edit.paymentSettings.labels.notRequired}
                </span>
              </label>
              <TextField
                id='edrpou'
                value={formData.edrpou}
                name='edrpou'
                placeholder={
                  localize.profile.edit.paymentSettings.labels.edrpouPlaceholder
                }
                error={
                  !isValidEdrpou(formData.edrpou) && formData.edrpou !== ''
                }
                helperText={
                  formData.edrpou === ''
                    ? ' '
                    : isValidEdrpou(formData.edrpou)
                      ? ' '
                      : localize.profile.edit.paymentSettings.labels.edrpouError
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    edrpou: event.target.value,
                  })
                }
              />
            </LabelGroup>
            <LabelGroup>
              <label htmlFor='iban'>
                {localize.profile.edit.paymentSettings.labels.iban}{' '}
                <span className='grey-text'>
                  {localize.profile.edit.paymentSettings.labels.notRequired}
                </span>
              </label>
              <TextField
                id='iban'
                value={formData.iban}
                placeholder={
                  localize.profile.edit.paymentSettings.labels.ibanPlaceholder
                }
                InputProps={{
                  sx: {
                    fontSize: '16px',
                    '&::placeholder': {
                      opacity: '1',
                      fontSize: '16px',
                    },
                  },
                }}
                helperText={
                  formData.iban === ''
                    ? ' '
                    : isValidIban(formData.iban)
                      ? ' '
                      : localize.profile.edit.paymentSettings.labels.ibanError
                }
                error={!isValidIban(formData.iban) && formData.iban !== ''}
                name='iban'
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    iban: event.target.value,
                  })
                }
              />
            </LabelGroup>
            <LabelGroup>
              <label htmlFor='coopTerms'>
                {localize.profile.edit.dropshipperProfile.labels.coopTerms}
              </label>
              <TextField
                id='coopTerms'
                value={formData.coopTerms}
                placeholder={localize.profile.edit.placeholderText}
                name='coopTerms'
                multiline
                rows={4}
                helperText={' '}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    coopTerms: event.target.value,
                  })
                }
              />
            </LabelGroup>
          </InputsGroup>
          <ButtonsBox>
            <Button
              variant='rounded'
              data-cy='dropshipper-profile-apply-btn'
              disabled={!isFormValid || submitting || !hasFormChanged}
              onClick={() => onSubmit()}
              endIcon={
                submitting ? (
                  <CircularProgress size={16} color='inherit' />
                ) : null
              }
            >
              {localize.profile.edit.buttonApply}
            </Button>
            <Button
              variant='transparent'
              data-cy='dropshipper-profile-clear-btn'
              disabled={submitting || !hasFormChanged}
              onClick={() => setInitFormData()}
            >
              {localize.profile.edit.buttonClear}
            </Button>
          </ButtonsBox>
        </StyledFormControl>
      </DropshipperForm>
    </Grid>
  );
};

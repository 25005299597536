import { FC, useState, useEffect } from 'react';

import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import './styles.css';

import { ReactComponent as CalendarIconSvg } from '../../assets/calendar.svg';

const Container = styled(Box)(() => ({
  width: 'fit-content',
  marginLeft: 'auto',
}));

type SingleInputDateRangePickerProps = {
  onCheckDateRange: (value: { from?: number; to?: number }) => void;
  dateRange: { from?: number; to?: number; internal_name?: string };
};

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const minDate = new Date('2024-01-01');
const maxDate = new Date();

const SingleInputDateRangePicker: FC<SingleInputDateRangePickerProps> = ({
  onCheckDateRange,
  dateRange,
}) => {
  const [value, setValue] = useState<Value>([null, null]);

  const clearDates = () => {
    onCheckDateRange({});
    setValue([null, null]);
  };

  useEffect(() => {
    if (dateRange.from && dateRange.to) {
      const start = new Date(dateRange.from);
      const end = new Date(dateRange.to);
      setValue([start, end]);
    }

    return () => {
      clearDates();
    };
  }, []);

  useEffect(() => {
    if (value && Array.isArray(value) && value[0] && value[1]) {
      onCheckDateRange({
        from: Date.parse(value[0].toString()),
        to: Date.parse(value[1].toString()),
      });
    }
    if (!value) {
      onCheckDateRange({});
    }
  }, [value]);

  return (
    <Container>
      <DateRangePicker
        onChange={(value) => {
          setValue(value);
        }}
        value={value}
        maxDate={maxDate}
        minDate={minDate}
        calendarIcon={
          <CalendarIconSvg style={{ width: '20px', height: '20px' }} />
        }
        clearIcon={
          <CloseIcon
            sx={{ color: '#777777', '&:hover': { color: '#464646' } }}
          />
        }
        dayPlaceholder='DD'
        monthPlaceholder='MM'
        yearPlaceholder='YYYY'
      />
    </Container>
  );
};

export default SingleInputDateRangePicker;

import { FC } from 'react';
import { IconButton } from '@mui/material';

import { AnyObject } from '../../api/anyObjectTypes';
import { ReactComponent as ExportIconSvg } from '../../assets/export.svg';

type RedirectToItemProps = {
  onClick: (row: AnyObject) => void;
  item: AnyObject;
};

export const RedirectToItem: FC<RedirectToItemProps> = ({ onClick, item }) => {
  return (
    <IconButton
      data-cy={`table-redirect-to-item-btn-${item.id}`}
      sx={{
        width: 40,
        height: 40,
      }}
      onClick={() => {
        onClick(item);
      }}
    >
      <ExportIconSvg fill='#777777' width='16px' height='16px' />{' '}
    </IconButton>
  );
};

import { styled } from '@mui/material';
import { AdvantagesSectionList } from './AdvantagesSectionList';

const AdvantagesSectionContainer = styled('section')(({ theme }) => ({
  backgroundColor: '#AFABEF',
  padding: '3% 0',
  color: '#fff',
}));

const AdvantagesSectionInner = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '0 20px',
  justifyContent: 'normal',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1280px',
  minWidth: '320px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}));

export const AdvantagesSection = () => {
  return (
    <AdvantagesSectionContainer>
      <AdvantagesSectionInner>
        <AdvantagesSectionList />
      </AdvantagesSectionInner>
    </AdvantagesSectionContainer>
  );
};

import { Button, FormControl, styled } from '@mui/material';
import { matchIsValidTel } from 'mui-tel-input';
import { useContext, useEffect, useState } from 'react';
import { UserApiClient } from '../../../api/UserApiClient';
import { CommonContext } from '../../../contexts/CommonContext';
import { useOnboardingMultiStep } from '../../../contexts/OnboardingContext';
import { useAsyncError } from '../../../hooks/useAsyncError';
import localize from '../../../localize';
import { StyledInput, StyledTelInput } from '../../../components/StyledInput';
import {
  ButtonsBox,
  MainDescription,
  MainTitle,
  OnboaringPageContainer,
} from '../OnboardingPage';
import ShowStepNumbers from '../ShowStepNumbers';

const PageInnerContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: '751px',
  width: '100%',
  margin: '0 auto',
  flexDirection: 'column',
  padding: '0 20px',
  alignItems: 'center',
  justifyContent: 'center',
}));

const HeadingBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '24px',
  padding: '0 32px',
}));

const UserForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '54px',
}));

const MainFieldsBox = styled('div')(() => ({
  marginTop: '20px',
}));

const GroupInputs = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '24px',
  rowGap: '12px',
  alignItems: 'center',
  marginTop: '20px',
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
  },
}));

type FormData = {
  name: string;
  surname: string;
  phoneNumber: string;
};

export const ManufacturerWelcomeInfo = () => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const { step, getTotalSteps, nextStep, prevStep } = useOnboardingMultiStep();
  const { currentUser, setCurrentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const [formData, setFormData] = useState<FormData>({
    name: '',
    surname: '',
    phoneNumber: '',
  });
  useEffect(() => {
    if (currentUser?.name)
      setFormData({
        name: `${currentUser?.name?.split(' ')?.[0]}`,
        surname: `${currentUser?.name?.split(' ')?.[1]}`,
        phoneNumber: currentUser?.phone_number || '',
      });
  }, [currentUser]);

  useEffect(() => {
    const isValidForm = !!formData.name && !!formData.surname && !!phoneIsValid;
    setIsFormValid(isValidForm);
  }, [formData.name, formData.surname, phoneIsValid]);

  useEffect(() => {
    setPhoneIsValid(matchIsValidTel(formData.phoneNumber));
  }, [formData.phoneNumber]);

  const onSubmit = async () => {
    try {
      const res = await UserApiClient.updateSelf(
        {
          user: {
            name: `${formData.name} ${formData.surname}`,
            phone_number: formData.phoneNumber,
          },
        },
        currentUser.auth0_id
      );
      setCurrentUser({
        ...currentUser,
        name: res.name,
        phone_number: res.phone_number,
      });

      nextStep();
    } catch (e) {
      throwError(e);
    }
  };

  return (
    <OnboaringPageContainer>
      <PageInnerContainer>
        <ShowStepNumbers currentStep={step} totalSteps={getTotalSteps()} />
        <HeadingBox>
          <MainTitle>{localize.onboarding.welcomeTitle}</MainTitle>
          <MainDescription sx={{ margin: '0' }}>
            {localize.onboarding.welcomeDescription}
          </MainDescription>
          <UserForm>
            <FormControl sx={{ width: '100%' }}>
              <MainFieldsBox>
                <GroupInputs>
                  <LabelGroup>
                    <label htmlFor='name'>
                      {localize.onboarding.formLabels.name}
                      <span className='red-star'>*</span>
                    </label>
                    <StyledInput
                      id='name'
                      value={formData.name}
                      placeholder='Введіть Ім`я'
                      helperText={!formData.name ? "Поле обов'язкове" : ' '}
                      name='name'
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          name: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>
                  <LabelGroup>
                    <label htmlFor='surname'>
                      {localize.onboarding.formLabels.surname}
                      <span className='red-star'>*</span>
                    </label>
                    <StyledInput
                      id='surname'
                      value={formData.surname}
                      placeholder='Введіть Прізвище'
                      name='surname'
                      helperText={!formData.surname ? "Поле обов'язкове" : ' '}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          surname: event.target.value,
                        })
                      }
                    />
                  </LabelGroup>

                  <LabelGroup>
                    <label htmlFor='phone'>
                      {localize.onboarding.formLabels.phoneNumber}
                      <span className='red-star'>*</span>
                    </label>
                    <StyledTelInput
                      id='phone'
                      defaultCountry={'UA'}
                      onlyCountries={['UA']}
                      required
                      forceCallingCode
                      langOfCountryName={'ua'}
                      InputProps={{ sx: { color: '#333' } }}
                      value={formData.phoneNumber}
                      error={!phoneIsValid && formData.phoneNumber !== ''}
                      helperText={
                        !phoneIsValid
                          ? formData.phoneNumber === ''
                            ? 'Поле обов`язкове'
                            : 'Невірний формат'
                          : ' '
                      }
                      name='phone'
                      onChange={(value) => {
                        setFormData({ ...formData, phoneNumber: value });
                        setPhoneIsValid(matchIsValidTel(value));
                      }}
                    />
                  </LabelGroup>
                </GroupInputs>
              </MainFieldsBox>
            </FormControl>
          </UserForm>
        </HeadingBox>

        <ButtonsBox>
          <Button
            variant='rounded'
            data-cy='manufacturer-welcome-info-next-btn'
            onClick={onSubmit}
            disabled={!isFormValid}
            sx={{
              padding: '14px 44px',
            }}
          >
            {localize.onboarding.indexPage.nextButton}
          </Button>
          <Button
            variant='transparent'
            data-cy='manufacturer-welcome-info-prev-btn'
            onClick={prevStep}
          >
            {localize.onboarding.indexPage.prevButton}
          </Button>
        </ButtonsBox>
      </PageInnerContainer>
    </OnboaringPageContainer>
  );
};

import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NotificationContext } from '../../contexts/NotificationContext';
import { CommonContext } from '../../contexts/CommonContext';
import { AnyObject } from '../../api/anyObjectTypes';
import { BookingApiClient } from '../../api/BookingApiClient';
import localize from '../../localize';
import { preparedRequestBody } from '../../utils/form';
import Page from '../../components/Page/Page';
import BookingsForm from '../../components/BookingsForm/BookingsForm';

export const BookingCreatePage: FC = () => {
  const { setNotification } = useContext(NotificationContext);
  const [submitting, setSubmitting] = useState(false);
  const { currentProject } = useContext(CommonContext);
  const navigate = useNavigate();

  const onSubmit = ({
    mainItem,
    mainFields,
    basicMainLabels,
  }: {
    mainItem: AnyObject;
    basicMainLabels: AnyObject;
    mainFields: AnyObject[];
  }) => {
    setSubmitting(true);

    BookingApiClient.create(currentProject.id, {
      booking: preparedRequestBody({
        mainItem,
        mainFields,
        basicMainLabels,
      }),
    })
      .then((res) => {
        setNotification({
          severity: 'success',
          message: localize.general.successCreateMessage,
        });
        setSubmitting(false);
        navigate(`/cp/bookings/${res.id}`);
      })
      .catch((err) => {
        setNotification({
          severity: 'error',
          message: localize.general.unexpectedError,
        });
        setSubmitting(false);
        navigate(`/cp/bookings`);
      });
  };

  return (
    <Page title={localize.bookings.create.title} actions={[]}>
      <BookingsForm onSubmit={onSubmit} submitting={submitting} />
    </Page>
  );
};

export default BookingCreatePage;

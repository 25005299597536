import { FC, useContext, useEffect, useMemo, useState } from 'react';
import Page from '../../components/Page/Page';
import { CommonContext } from '../../contexts/CommonContext';
import { Grid, Tab, Tabs, Box } from '@mui/material';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import HorizontalScrollWrapper from '../../components/HorizontalScrollWrapper/HorizontalScrollWrapper';

type ProfilePageProps = {};

export const ProfilePage: FC<ProfilePageProps> = () => {
  const [values, setValues] = useState<AnyObject>({});
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(CommonContext);
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = useMemo(() => {
    const lastItem = structuredClone(location.pathname.split('/')).pop();
    return lastItem === 'profile' ? '' : lastItem;
  }, [location]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/cp/profile/${newValue}`);
  };

  const prepareData = async (): Promise<void> => {
    setValues({
      name: currentUser.name,
      email: currentUser.email,
      locale: currentUser.locale,
    });
  };

  useEffect(() => {
    setLoading(true);
    prepareData().finally(() => setLoading(false));
  }, []);

  if (loading || !Object.keys(values).length) return null;

  return (
    <Page title={localize.profile.edit.title} actions={[]}>
      <Grid
        container
        spacing={1}
        alignItems='flex-start'
        alignSelf='flex-start'
      >
        <Grid item xs={12} paddingTop={0} container spacing={3}>
          <Grid item xs={12}>
            <Box>
              <HorizontalScrollWrapper className='MuiTabs-scroller'>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label='Settings tabs'
                >
                  {Object.keys(localize.profile.edit.tabs as AnyObject).map(
                    (key, index) => (
                      <Tab
                        key={key}
                        label={localize.profile.edit.tabs[key]?.title}
                        value={localize.profile.edit.tabs[key]?.path}
                        id={`tab-${index}`}
                        aria-label={`tab-${index}`}
                      />
                    )
                  )}
                </Tabs>
              </HorizontalScrollWrapper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default ProfilePage;

import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Divider, Box, Typography, Button, Stack } from '@mui/material';
import FormField from '../../components/FormField/FormField';
import { Customer } from '../../api/CustomerApiClient';
import { Delivery } from '../../api/OrderApiClient';
import NotRequiredLabel from './NotRequiredLabel';
import localize from '../../localize';
import { useMobile } from '../../hooks/useMobile';

type DeliveryFieldsProps = {
  customer?: Customer;
  delivery?: Delivery;
  setDelivery(user: Delivery): void;
};

const DataFillContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: '100%',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '&>*': {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

export const DeliveryFields: FC<DeliveryFieldsProps> = ({
  customer,
  delivery,
  setDelivery,
}) => {
  const mobile = useMobile();
  const bigInput = {
    minWidth: mobile ? '200px' : '300px',
    flex: 1,
    padding: mobile ? '10px 0' : '10px',
  };
  const smallInput = {
    minWidth: '120px',
    width: mobile ? '100%' : '40%',
    padding: mobile ? '10px 0' : '10px',
  };

  const fillFromCustomerData = () => {
    if (!customer?.name && !customer?.phone_number) return;

    setDelivery({
      ...delivery,
      delivery_recipient_name: customer?.name,
      delivery_recipient_phone: customer?.phone_number,
    });
  };

  return (
    <Stack width='100%'>
      <Stack width='100%'>
        <Stack flexDirection='row' justifyContent='space-between'>
          <Typography variant='h5' color='#7B75CB'>
            {localize.orders.form.deliveryCard.recipientData}
          </Typography>
          {!mobile && <NotRequiredLabel />}
        </Stack>
        {customer?.name || customer?.phone_number ? (
          <Button
            variant='roundedSecondary'
            sx={{
              borderColor: '#AFABEF',
              width: 'fit-content',
              color: '#7B75CB',
              padding: '4px 8px',
              mt: '10px',
            }}
            onClick={() => {
              fillFromCustomerData();
            }}
          >
            {localize.orders.form.deliveryCard.fillFromCustomerData}
          </Button>
        ) : null}
      </Stack>
      <DataFillContainer>
        <FormField
          sx={bigInput}
          value={delivery?.delivery_recipient_name || ''}
          label={localize.orders.form.deliveryCard.recipientName}
          fieldType='string'
          name='delivery_recipient_name'
          onChange={(e) =>
            setDelivery({
              ...(delivery || {}),
              delivery_recipient_name: e.target.value,
            })
          }
        />
        <FormField
          sx={smallInput}
          value={delivery?.delivery_recipient_phone || ''}
          label={localize.orders.form.deliveryCard.recipientPhone}
          fieldType='string'
          name='delivery_recipient_phone'
          onChange={(e) =>
            setDelivery({
              ...(delivery || {}),
              delivery_recipient_phone: e.target.value,
            })
          }
        />
      </DataFillContainer>
      <Divider sx={{ borderColor: '#DFDDF9', margin: '20px 0' }} />
      <Stack flexDirection='row' justifyContent='space-between'>
        <Typography variant='h5' color='#7B75CB'>
          {localize.orders.form.deliveryCard.deliveryData}
        </Typography>
        {!mobile && <NotRequiredLabel />}
      </Stack>
      <Box sx={{ display: 'flex', minWidth: '100%' }}>
        <FormField
          sx={bigInput}
          value={delivery?.delivery_operator || ''}
          options={['Нова Пошта', 'Інший']}
          label={localize.orders.form.deliveryCard.deliveryOperator}
          name='delivery_operator'
          onChange={(e) =>
            setDelivery({
              ...(delivery || {}),
              delivery_operator: e.target.value,
            })
          }
        />
      </Box>
      <DataFillContainer>
        <FormField
          sx={bigInput}
          value={delivery?.delivery_address || ''}
          label={localize.orders.form.deliveryCard.deliveryAddress}
          fieldType='string'
          name='delivery_address'
          onChange={(e) =>
            setDelivery({
              ...(delivery || {}),
              delivery_address: e.target.value,
            })
          }
        />
        <FormField
          sx={smallInput}
          value={delivery?.delivery_ttn || ''}
          label={localize.orders.form.deliveryCard.deliveryTTN}
          fieldType='string'
          name='delivery_ttn'
          onChange={(e) =>
            setDelivery({
              ...(delivery || {}),
              delivery_ttn: e.target.value,
            })
          }
        />
      </DataFillContainer>
    </Stack>
  );
};

export default DeliveryFields;

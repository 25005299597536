import { styled, TextField } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';

export const StyledInput = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    borderColor: '#D9D9D9',
    minHeight: '40px',
    marginBottom: '-10px',
  },

  '& .MuiInputBase-input': {
    padding: '9px 14px',
  },
  '& textarea.MuiInputBase-input': {
    padding: '0',
  },
}));
export const StyledTelInput = styled(MuiTelInput)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    marginTop: '4px',
    padding: '0 0 5px',
  },

  '& .MuiInputBase-root .MuiTypography-root': {
    position: 'relative',
    marginLeft: '4px',
    top: '-4px',
  },
  '& textarea.MuiInputBase-input': {
    padding: '0',
  },

  '& .MuiTelInput-FlagImg': {
    borderRadius: '20px',
    width: '15px',
    height: '15px',
  },
}));

import { FC, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { Badge, Button, IconButton, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import localize from '../../localize';

import { ReactComponent as DeleteIcon } from '../../assets/delete-item.svg';

const getColor = (props: {
  isFocused: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
}) => {
  if (props.isDragAccept) {
    return '#53C17F';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#9B96EB';
  }
  return '#9B96EB';
};

const Container = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const DropZoneContainer = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px 0 30px',
  borderWidth: '2px',
  borderRadius: '12px',
  borderStyle: 'dashed',
  backgroundColor: '#DFDDF966',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}));

const FileListContainer = styled('div')(({ theme }) => ({
  flex: 1,
  width: '100%',
  color: '#464646',
  marginTop: '5px',
}));

const FileItem = styled('div')(({ theme }) => ({
  width: '250px',
  padding: '2px 0',
  color: '#464646',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px dashed #9B96EB',
}));

interface FileWithUrl extends File {
  url?: string;
}

type FileUploadProps = {
  label?: string;
  onChange(files: File[]): void;
  compact?: boolean;
  value?: string[];
  maxFiles?: number;
  accept?: Accept;
};

export const FileUpload: FC<FileUploadProps> = ({
  label,
  value,
  onChange,
  maxFiles,
  compact = false,
  accept = { 'application/pdf': [] },
}) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: maxFiles !== 1,
    accept,
    maxFiles,
  });
  const [selectedFiles, setSelectedFiles] = useState<FileWithUrl[]>(
    ((Array.isArray(value)
      ? value
      : value
        ? [value]
        : undefined) as unknown as FileWithUrl[]) || []
  );

  const onFileDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    i: number
  ) => {
    e.stopPropagation();
    setSelectedFiles([...selectedFiles.filter((_, index) => index !== i)]);
  };
  useEffect(() => {
    setSelectedFiles([...acceptedFiles, ...selectedFiles]);
  }, [acceptedFiles]);

  useEffect(() => {
    onChange(selectedFiles);
  }, [selectedFiles]);

  return (
    <Container>
      <DropZoneContainer
        {...getRootProps()}
        style={{
          borderColor: getColor({ isFocused, isDragAccept, isDragReject }),
        }}
      >
        <input {...getInputProps()} />
        <Badge color='primary' badgeContent={selectedFiles?.length}>
          <Button
            variant='transparent'
            sx={{
              textDecoration: 'underline',
              padding: '5px',
              '&:hover, &:focus': {
                background: 'none',
                textDecoration: 'underline',
              },
            }}
            component='span'
            onClick={open}
          >
            {label || localize.general.files}
          </Button>
        </Badge>
        {!compact ? (
          <>
            <Typography color='#777777' variant='h5'>
              {localize.general.files_dropzone}
            </Typography>
          </>
        ) : null}
      </DropZoneContainer>
      {selectedFiles.length ? (
        <FileListContainer>
          {selectedFiles.map((file, i) => (
            <FileItem key={`${file.name}${Math.random()}`}>
              {file?.url ? (
                <Link href={file.url} target='_blank'>
                  {file.name.length > 25
                    ? `...${file.name.slice(-24)}`
                    : file.name}
                </Link>
              ) : (
                <span>{file.name}</span>
              )}
              <IconButton
                size='small'
                sx={{
                  background: '#fa3b3b81',
                  '&:hover': { background: '#FA3B3B' },
                }}
                onClick={(e): void => onFileDelete(e, i)}
              >
                <DeleteIcon fill='#fff' />
              </IconButton>
            </FileItem>
          ))}
        </FileListContainer>
      ) : null}
    </Container>
  );
};

export default FileUpload;

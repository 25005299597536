import { FC } from 'react';
import { CustomerKeys, Order } from '../../api/OrderApiClient';
import { Typography } from '@mui/material';
import IconWithInfo from '../../components/IconWithInfo';
import { StyledTypography } from './components';
import localize from '../../localize';

type CustomerDetailsCardProps = {
  order: Order;
};

const customerFieldsAndLabels: { [key in CustomerKeys]: string } =
  localize.orders.show.customerFields;

export const CustomerDetailsCard: FC<CustomerDetailsCardProps> = ({
  order,
}) => {
  return Object.keys(customerFieldsAndLabels).some(
    (el) => order[el as CustomerKeys]
  ) ? (
    <>
      <br />
      <Typography variant='h5' color='#9B96EB' pb={1}>
        {localize.orders.show.clientDetailsCard.customerDetailsTitle}
      </Typography>
      {Object.keys(customerFieldsAndLabels).map((el, ind) =>
        order[el as CustomerKeys] ? (
          <StyledTypography variant='body1' key={`order-customer-${ind}`}>
            {el == 'fixed_customer_discount' ? (
              <>
                {customerFieldsAndLabels[el as CustomerKeys]}{' '}
                <IconWithInfo
                  text={
                    localize.orders.show.clientDetailsCard.customerDetailsText
                  }
                />
                :{' '}
                <Typography component='span' variant='subtitle2' ml='auto'>
                  {order.fixed_customer_discount || 0}%
                </Typography>
              </>
            ) : (
              <>
                {customerFieldsAndLabels[el as CustomerKeys]}:{' '}
                <Typography component='span' variant='subtitle2'>
                  {order[el as CustomerKeys]}
                </Typography>
              </>
            )}
          </StyledTypography>
        ) : null
      )}
    </>
  ) : null;
};

export default CustomerDetailsCard;

import { List, ListItem, styled, Typography } from '@mui/material';
import React from 'react';
import localize from '../../../localize';

export const StyledParagraph = styled(Typography)(({ theme }) => ({
  maxWidth: '343px',
  fontFamily: '"Montserrat", sans-serif',
  fontSize: '12px',
  [theme.breakpoints.down('md')]: {
    maxWidth: '75%',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    fontSize: '14px',
  },
}));

export const AdvantagesSectionList = () => {
  return (
    <AdvList>
      {localize.landing.advantagesSection.map(
        ({ title, description }: { title: string; description: string }) => (
          <ListItem key={title}>
            <Typography variant='h4'>{title}</Typography>
            <StyledParagraph variant='body1'>{description}</StyledParagraph>
          </ListItem>
        )
      )}
    </AdvList>
  );
};

const AdvList = styled(List)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  h4: {
    fontWeight: 700,
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  '& li': {
    marginBottom: theme.spacing(1),
    padding: '0 20px',
    paddingLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderLeft: '1px solid #fff',
    gap: '25px',

    '&:first-of-type': {
      paddingLeft: 0,
      borderLeft: 'none',
    },
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid #fff',
      borderLeft: 'none',
      paddingTop: '20px',
      paddingBottom: '20px',
      '&:first-of-type': {
        paddingLeft: '5%',
        borderTop: 'none',
        borderLeft: 'none',
      },
    },
  },
}));

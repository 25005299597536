import { CssBaseline, styled, Typography } from '@mui/material';
import React from 'react';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { ConsultSection } from './components/pricing/ConsultSection';
import { FAQSection } from './components/pricing/FAQSection';
import { PlanTextSection } from './components/pricing/PlanTextSection';
import { Tarrifs } from './components/pricing/Tarrifs';
import { RootLandingWrapper } from './Landing';

export const MainTitle = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '29px',
  fontFamily: '"Montserrat", sans-serif',
  textAlign: 'center',
  marginBottom: '16px',
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '19.5px',
    textTransform: 'initial',
  },
}));

export const MainText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  display: 'block',
  fontWeight: 400,
  fontFamily: '"Montserrat", sans-serif',
  marginBottom: '10px',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '17px',
  },
}));

export const ContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'space-between',
  margin: '0 auto',
  alignItems: 'center',
  padding: '0 20px',
  width: '100%',
  maxWidth: '1280px',
  minWidth: '320px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
    maxWidth: '100%',
  },
}));

export const ColorSection = styled('section')(({ theme }) => ({
  backgroundColor: '#AFABEF',
  padding: '56px 0 34px 0',
  position: 'relative',
  color: '#fff',
}));

export const Price = () => {
  return (
    <RootLandingWrapper>
      <CssBaseline />
      <Header />
      <Tarrifs />
      <PlanTextSection />
      <FAQSection />
      <ConsultSection />
      <Footer />
    </RootLandingWrapper>
  );
};

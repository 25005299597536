import { Divider, styled, Typography } from '@mui/material';
import React from 'react';
import { EventsForm } from './EventsForm';
import { TelegramBotPanel } from './TelegramBotPanel';

export const MainHeadingText = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: 500,
  color: '#0C0C0C',
  fontFamily: '"Montserrat", sans-serif',
}));

export const Notifications = () => {
  return (
    <>
      <TelegramBotPanel />
      {/* <Divider color='#DFDDF9' /> */}
      {/* <EventsForm /> */}
    </>
  );
};

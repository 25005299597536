import { styled, Typography } from '@mui/material';
import localize from '../../../localize';
import bookIcon from '../../../assets/landing/features/book.svg';
import chatIcon from '../../../assets/landing/features/chat.svg';
import cartIcon from '../../../assets/landing/features/shopping-cart.svg';
import packageIcon from '../../../assets/landing/features/package.svg';

import mockGeneral from '../../../assets/landing/features/mock-general.svg';
import sallyHand from '../../../assets/landing/features/sally-hand.png';

const FeaturesSectionContainer = styled('section')(({ theme }) => ({
  padding: '3% 0',
  color: '#fff',
}));

const FeaturesSectionInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '20px',
  justifyContent: 'normal',
  alignItems: 'center',
  width: 'calc(1280px + (50% - 1280px / 2))',
  maxWidth: '100%',
  overflow: 'hidden',
  minWidth: '320px',
  marginLeft: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: '100%',
    marginLeft: '0',
    padding: '0 20px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '1280px',
    marginLeft: '0',
    padding: '0 10px',
  },
}));

const FeaturesWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'normal',
  minHeight: '518px',
  minWidth: '100%',
  gap: '24px',
  [theme.breakpoints.down('lg')]: {
    gap: '16px',
    minHeight: '400px',
  },
}));

const FeaturesList = styled('ul')(({ theme }) => ({
  display: 'grid',
  padding: 0,
  margin: 0,
  gridTemplateColumns: 'repeat(2, 1fr)',
  width: '50%',
  gap: '24px',
  listStyle: 'none',
  [theme.breakpoints.down('lg')]: {
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  '& li': {
    padding: '24px 20px 48px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '24px',
    backgroundColor: '#9B96EB',
    borderRadius: '24px',
  },

  '& h4': {
    marginBottom: '24px',
    fontWeight: 700,
    fontSize: '16px',
    fontFamily: '"Montserrat", sans-serif',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '8px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
}));

const FeaturesMockupContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifySelf: 'flex-end',
  justifyContent: 'center',
  borderTopLeftRadius: '24px',
  borderBottomLeftRadius: '24px',
  backgroundColor: 'rgba(198, 183, 255, 0.4)',
  width: '50%',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    width: '40%',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },

  '& #mockup-general': {
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '600px',
    height: 'auto',
  },

  '& #sally-hand': {
    width: '386px',
    height: 'auto',
    position: 'absolute',
    bottom: '0',
    right: '0%',
  },
}));

const FeatureIcon = styled('img')(({ theme }) => ({
  width: '40px',
  height: '40px',
}));

const FeaturesTitle = styled('h2')(({ theme }) => ({
  color: '#0c0c0c',
  alignSelf: 'flex-start',
  fontSize: '34px',
  fontWeight: '500',
  margin: '30px 0',
  fontFamily: '"Montserrat", sans-serif',
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    margin: '24px 0',
  },
}));

const featuresIcons = [
  {
    path: packageIcon,
  },
  {
    path: cartIcon,
  },
  {
    path: chatIcon,
  },
  {
    path: bookIcon,
  },
];

const featuresSectionLoc: { title: string; description: string }[] =
  localize.landing.featuresSection.items;

const featuresSectionData = featuresSectionLoc.map((item, index) => ({
  ...item,
  path: featuresIcons[index].path,
}));

export const FeaturesSection = () => {
  return (
    <FeaturesSectionContainer id='features'>
      <FeaturesSectionInner>
        <FeaturesTitle>{localize.landing.featuresSection.title}</FeaturesTitle>
        <FeaturesWrapper>
          <FeaturesList>
            {featuresSectionData.map(({ title, description, path }) => (
              <li key={title}>
                <FeatureIcon src={path} alt={title} />
                <Typography variant='h4'>{title}</Typography>
                <Typography
                  sx={{ color: '#F3F2F7' }}
                  variant='body1'
                  component={'p'}
                >
                  {description}
                </Typography>
              </li>
            ))}
          </FeaturesList>
          <FeaturesMockupContainer>
            <img src={mockGeneral} id='mockup-general' alt='General mockup' />
            <img src={sallyHand} id='sally-hand' alt='Sally hand' />
          </FeaturesMockupContainer>
        </FeaturesWrapper>
      </FeaturesSectionInner>
    </FeaturesSectionContainer>
  );
};

import { FC } from 'react';
import { IconButton } from '@mui/material';

import { AnyObject } from '../../api/anyObjectTypes';
import { ReactComponent as DoneIconSvg } from '../../assets/done-check-mark.svg';

type DoneForItemProps = {
  onClick: (row: AnyObject) => void;
  item: AnyObject;
};

export const DoneForItem: FC<DoneForItemProps> = ({ onClick, item }) => {
  return (
    <IconButton
      data-cy={`table-done-status-btn-${item.id}`}
      sx={{
        width: 40,
        height: 40,
        '&:hover': { background: '#53c17f6f' },
      }}
      onClick={() => {
        onClick(item);
      }}
    >
      <DoneIconSvg fill='#777777' width='16px' height='16px' />
    </IconButton>
  );
};

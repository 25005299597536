import { AnyObject } from '../api/anyObjectTypes';

export const isDeepEqual = (a: AnyObject, b: AnyObject): boolean => {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) return false;
    return a.every((value, index) => isDeepEqual(value, b[index]));
  }

  if (a === b) return true;

  if (
    typeof a !== 'object' ||
    typeof b !== 'object' ||
    a === null ||
    b === null
  ) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (let key of keysA) {
    if (!keysB.includes(key)) return false;

    if (!isDeepEqual(a[key], b[key])) return false;
  }

  return true;
};

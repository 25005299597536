import { FC } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormField from '../../components/FormField/FormField';
import { Customer } from '../../api/CustomerApiClient';
import { Delivery } from '../../api/OrderApiClient';
import NotRequiredLabel from './NotRequiredLabel';
import localize from '../../localize';
import { useMobile } from '../../hooks/useMobile';

type UploadFilesFieldsProps = {
  files?: File[];
  setFiles(files: File[]): void;
};

export const UploadFilesFields: FC<UploadFilesFieldsProps> = ({
  files,
  setFiles,
}) => {
  const mobile = useMobile();
  return (
    <Card
      sx={{
        border: '1px solid #DFDDF9',
        borderRadius: '8px',
        padding: mobile ? '8px' : '8px 24px',
      }}
    >
      <Stack flexDirection='row' justifyContent='space-between'>
        <CardHeader
          sx={{ padding: '0' }}
          title={localize.orders.form.addFiles.title}
        />
        {!mobile && <NotRequiredLabel />}
      </Stack>
      <CardContent>
        <Box
          sx={{ display: 'flex', width: mobile ? '100%' : '80%', pb: '30px' }}
        >
          <FormField
            value={files || ''}
            label=''
            fieldType='file'
            name='files'
            onChange={(e) => setFiles(e.target.value)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default UploadFilesFields;

import { FC } from 'react';
import { IconButton } from '@mui/material';

import { AnyObject } from '../../api/anyObjectTypes';
import { ReactComponent as DeleteItemIconSvg } from '../../assets/delete-item.svg';

type DeleteItemProps = {
  onClick: (row: AnyObject) => void;
  item: AnyObject;
};

export const DeleteItem: FC<DeleteItemProps> = ({ onClick, item }) => {
  return (
    <IconButton
      data-cy={`table-delete-item-btn-${item.id}`}
      sx={{
        width: 40,
        height: 40,
        '&:hover': { background: '#fa3b3b73' },
      }}
      onClick={() => {
        onClick(item);
      }}
    >
      <DeleteItemIconSvg fill='#777777' />
    </IconButton>
  );
};

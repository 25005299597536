import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Page from '../../components/Page/Page';
import { useNavigate } from 'react-router';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import { CommonContext } from '../../contexts/CommonContext';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { UserApiClient } from '../../api/UserApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import { useAsyncError } from '../../hooks/useAsyncError';
import localize from '../../localize';
import { baseMainFields } from './UserShowPage';
import { useParams } from 'react-router-dom';

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
}));

export const UsersIndexPage: FC = () => {
  const { currentProject, currentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const navigate = useNavigate();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { userType } = useParams();

  const actions = useMemo(() => {
    const addAction = {
      title: localize.users.index.addButton,
      link: `/cp/users/${userType}/create`,
    };

    if (!currentUser.user_permissions.user?.create?.length) return [];

    return [addAction];
  }, [currentUser, userType]);

  const preparePageData = async (): Promise<void> => {
    const apiRows = await UserApiClient.getIndex(currentProject.id).catch(
      (e) => {
        throwError(e);
      }
    );

    setRows(
      apiRows
        ?.filter((el) => el.auth0_id !== currentUser.auth0_id)
        ?.filter((el) =>
          userType === 'dropshipers' ? el.role == 'Client' : el.role != 'Client'
        ) || []
    );
  };

  useEffect(() => {
    if (userType?.startsWith('auth0')) {
      navigate(`/cp/users/unknown/${userType}`);
      return;
    }
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
  }, [userType, currentProject, userType]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page
      title={userType === 'dropshipers' ? 'Дропшипери' : 'Адміністратори'}
      actions={actions}
    >
      <InnerContainer>
        {!!rows.length ? (
          <BasicTable
            rows={rows}
            columns={baseMainFields}
            onRowClick={(row: AnyObject) =>
              navigate(`/cp/users/${userType}/${row.auth0_id}`)
            }
            OnCollapsedRowClick={(row: AnyObject) =>
              navigate(`/cp/users/${userType}/${row.auth0_id}`)
            }
          />
        ) : (
          <EmptyContent withImage='box' />
        )}
      </InnerContainer>
    </Page>
  );
};

export default UsersIndexPage;

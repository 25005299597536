import { Button, Link, styled } from '@mui/material';
import React from 'react';
import { ButtonsBox, MainDescription, MainTitle } from '../OnboardingPage';
import { OnboaringPageContainer } from '../OnboardingPage';
import checkImg from '../../../assets/onboarding/coin-check.png';
import localize from '../../../localize';

const InnerContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: '686px',
  width: '100%',
  margin: '0 auto',
  flexDirection: 'column',
  padding: '0 20px',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '54px',
}));

const CheckImg = styled('img')(() => ({
  maxWidth: '100%',
  width: '115px',
}));

const HeadingBox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
}));

export const FinalScreenDropshipper = () => {
  return (
    <OnboaringPageContainer>
      <InnerContainer>
        <CheckImg src={checkImg} alt='check' />

        <HeadingBox>
          <MainTitle>{localize.onboarding.finalScreen.title}</MainTitle>
          <MainDescription sx={{ margin: '0' }}>
            {localize.onboarding.finalScreen.description}
          </MainDescription>
        </HeadingBox>
        <ButtonsBox>
          <Button
            variant='rounded'
            data-cy='dropshipper-final-manufacturer-btn'
            component={Link}
            href='/cp'
            sx={{
              padding: '14px 44px',
            }}
          >
            {localize.onboarding.finalScreen.manufacturerButton}
          </Button>
          <Button
            variant='transparent'
            data-cy='dropshipper-final-btn'
            component={Link}
            href='/cp'
          >
            {localize.onboarding.finalScreen.finishButton}
          </Button>
        </ButtonsBox>
      </InnerContainer>
    </OnboaringPageContainer>
  );
};

import { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import {
  checkNumberValue,
  checkOptionValue,
  checkStringValue,
  checkDateValue,
} from '../utils/filter';
import { FilterContext } from '../contexts/FilterContext';

export const useFilter = (
  rows: any[],
  area: 'products' | 'orders' | 'bookings' | 'manufacture',
  subItemsKey?: string
) => {
  const [results, setResults] = useState<any>([]);
  const {
    filterData,
    setMainItems: setMainItemsInContext,
    setDateRange: setDateRangeInContext,
    setItems,
  } = useContext(FilterContext);
  const mainItems = useMemo(
    () => filterData[area].mainItems,
    [filterData[area]]
  );
  const subItems = useMemo(() => filterData[area].subItems, [filterData[area]]);
  const dateRange = useMemo(
    () => filterData[area].dateRange,
    [filterData[area]]
  );

  const setMainItems = (newItems: any) => setMainItemsInContext(area, newItems);
  const setDateRange = (newDateRange: {
    from?: number;
    to?: number;
    internal_name?: string;
  }) => setDateRangeInContext(area, newDateRange);

  const anyFiltersApplied = useMemo(
    () =>
      !!mainItems.filter(
        (el: any) =>
          el.value !== undefined && el.value !== '' && el.value !== null
      ).length ||
      !!subItems.filter(
        (el: any) =>
          el.value !== undefined && el.value !== '' && el.value !== null
      ).length,
    [filterData[area]]
  );

  const filterItem = (row: any, item: any): boolean => {
    if (!row) return false;
    if (!item.value?.length) return true;
    if (item.options?.length || item.field_type === 'boolean')
      return checkOptionValue(row, item.internal_name, item.value);
    if (item.field_type === 'string')
      return checkStringValue(row, item.internal_name, item.value);
    if (item.field_type === 'number')
      return !!checkNumberValue(row, item.internal_name, item.value);
    if (item.field_type === 'date')
      return !!checkDateValue(row, item.internal_name, item.value);

    return true;
  };

  const filterRows = useCallback(() => {
    const res = JSON.parse(JSON.stringify(rows))
      .map((row: any) => {
        if (
          dateRange.from &&
          dateRange.to &&
          dateRange.internal_name &&
          (Date.parse(row[dateRange.internal_name]) < dateRange.from ||
            Date.parse(row[dateRange.internal_name]) > dateRange.to)
        ) {
          return null;
        }

        const mainItemIsAcceptable = mainItems
          .map((item: any) => filterItem(row, item))
          .every((value: any) => !!value);

        if (!subItemsKey) return mainItemIsAcceptable ? row : null;

        const rowSubItems = row[subItemsKey].filter((innerRow: any) =>
          subItems
            .map((item: any) => filterItem(innerRow, item))
            .every((value: any) => !!value)
        );
        row[subItemsKey] = rowSubItems;

        return mainItemIsAcceptable && rowSubItems.length ? row : null;
      })
      .filter((val: any) => {
        return !!val;
      });

    setResults(res);
  }, [filterData[area], rows]);

  const resetFilter = () => {
    setItems(
      area,
      mainItems.map((item: any) => {
        delete item.value;
        return item;
      }),
      subItems.map((item: any) => {
        delete item.value;
        return item;
      }),
      { ...dateRange }
    );
  };

  const setInitialValue = (value: any) => {
    setMainItems(
      JSON.parse(JSON.stringify(mainItems)).map((item: any, index: number) => {
        if (index === 0) item.value = value;
        return item;
      })
    );
  };

  const onSearchByDateRange = (value: { from?: number; to?: number }) => {
    setDateRange({ ...dateRange, from: value.from, to: value.to });
  };

  const setTemplateFields = (
    mainTemplateFields: any[],
    subTemplateFields?: any[]
  ) => {
    setItems(area, mainTemplateFields, subTemplateFields || [], {});
  };

  useEffect(() => {
    if (!mainItems.length && !dateRange.from) return;

    const timeoutDelayMS = !!mainItems[0].value ? 500 : 0;

    const delayDebounceFn = setTimeout(() => {
      filterRows();
    }, timeoutDelayMS);

    return () => clearTimeout(delayDebounceFn);
  }, [filterData[area], rows]);

  useEffect(() => {
    setResults(rows);
  }, [rows]);

  return {
    results,
    anyFiltersApplied,
    setTemplateFields,
    mainItems,
    subItems,
    dateRange,
    resetFilter,
    setFilterItems: (newMainItems: any[], newSubItems: any[]) =>
      setItems(area, newMainItems, newSubItems, dateRange),
    setInitialValue,
    onSearchByDateRange,
  };
};

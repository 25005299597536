import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Order } from '../../api/OrderApiClient';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
  Box,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/material';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { CommonContext } from '../../contexts/CommonContext';
import {
  OrderComment,
  OrderCommentApiClient,
} from '../../api/OrderCommentApiClient';
import FormField from '../../components/FormField/FormField';
import { formatDateTime } from '../../utils/time';
import {
  MoreHoriz as MoreHorizIcon,
  Visibility as VisibilityIcon,
  VisibilityTwoTone as VisibilitTwoToneIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import IconWithInfo from '../../components/IconWithInfo';
import OptionsMenu from '../../components/OptionsMenu/OptionsMenu';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import Loading from '../../components/Loading/Loading';
import localize from '../../localize';
import { useMobile } from '../../hooks/useMobile';

type CommentsCardProps = {
  order: Order;
};

const visibilityInfo = {
  self: {
    text: localize.orders.show.commentCard.forMeText,
    icon: VisibilityOffIcon,
  },
  all: {
    text: localize.orders.show.commentCard.forAllText,
    icon: VisibilityIcon,
  },
  management: {
    text: localize.orders.show.commentCard.forManagersText,
    icon: VisibilitTwoToneIcon,
  },
};

export const CommentsCard: FC<CommentsCardProps> = ({ order }) => {
  const { currentProject, currentUser } = useContext(CommonContext);
  const [comments, setComments] = useState<OrderComment[]>();
  const [commentText, setCommentText] = useState<string>('');
  const [visibility, setVisibility] = useState<string>('self');
  const [commentToDelete, setCommentToDelete] = useState<
    OrderComment | undefined
  >();
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const mobile = useMobile();

  const visibilityOptions = useMemo(() => {
    if (currentUser?.user_permissions?.role_name === 'client')
      return ['self', 'all'];

    return ['self', 'all', 'management'];
  }, [currentUser]);

  const preparePageData = async (): Promise<void> => {
    setLoading(true);
    const res = await OrderCommentApiClient.getIndex(
      currentProject.id,
      order.id
    );
    setComments(res);
    setLoading(false);
  };

  const submitComment = async () => {
    if (!commentText || !visibility) return;

    setSubmitting(true);

    await OrderCommentApiClient.create(currentProject.id, order.id, {
      order_comment: {
        comment: commentText,
        visibility,
      },
    });

    await preparePageData();

    setCommentText('');
    setSubmitting(false);
  };

  const deleteComment = async () => {
    if (!commentToDelete) return;
    setDeleting(true);

    await OrderCommentApiClient.remove(
      currentProject.id,
      order.id,
      commentToDelete.id
    );

    setCommentToDelete(undefined);

    await preparePageData();

    setDeleting(false);
  };

  useEffect(() => {
    if (order?.id) preparePageData();
  }, [order]);

  return (
    <Box>
      <Card>
        <CardHeader
          title={localize.orders.show.commentCard.title}
          subheader={localize.orders.show.commentCard.subtitle}
        />
        <Divider sx={{ borderColor: '#DFDDF9' }} />
        <CardContent sx={mobile ? { padding: '0' } : {}}>
          <Box>
            <Box sx={{ pb: 1, pt: mobile ? 2 : 0 }}>
              {!comments?.length ? (
                loading ? (
                  <Loading />
                ) : (
                  <EmptyContent
                    title=' '
                    subtitle={localize.orders.show.commentCard.noComment}
                    withImage='comment'
                  />
                )
              ) : (
                <List sx={loading ? { opacity: '.5' } : {}}>
                  {comments.map((comment) => {
                    const isOwn = comment.user_id === currentUser.id;

                    return (
                      <ListItem
                        alignItems='flex-start'
                        secondaryAction={
                          isOwn && (
                            <OptionsMenu
                              menuItems={[
                                {
                                  title: localize.general.delete,
                                  onClick: () => setCommentToDelete(comment),
                                },
                              ]}
                            />
                          )
                        }
                      >
                        <ListItemAvatar>
                          <Avatar>
                            {comment.user_name.split(' ')?.length > 1
                              ? `${comment.user_name.split(' ')[0][0]}${comment.user_name.split(' ')[1][0]}`
                              : comment.user_name}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Typography variant='body1'>
                                {comment.user_name} {isOwn && '(Ви)'}
                              </Typography>
                              <Paper
                                sx={{
                                  background: '#E2E1F1',
                                  whiteSpace: 'break-spaces',
                                  p: 1,
                                  mr: 2,
                                }}
                              >
                                {comment.comment}
                              </Paper>
                            </>
                          }
                          secondary={
                            <Box sx={{ display: 'flex' }}>
                              <Box sx={{ mr: 1 }}>
                                {formatDateTime(comment.created_at)}
                              </Box>
                              <IconWithInfo
                                {...visibilityInfo[comment.visibility]}
                              />
                            </Box>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginTop: '20px',
                flexDirection: mobile ? 'column' : 'row',
                marginBottom: mobile ? '20px' : '0',
              }}
            >
              <FormField
                value={commentText}
                sx={{ width: 'auto', maxWidth: '100%', flex: '1 1 auto' }}
                placeholder={localize.orders.show.commentCard.yourComment}
                name='comment'
                label=''
                fieldType='text'
                onChange={(e) => setCommentText(e.target.value)}
              />
              <FormField
                value={visibility}
                sx={{
                  width: 'auth',
                  maxWidth: mobile ? '100%' : '200px',
                  flex: '1 1 auto',
                  alignSelf: 'flex-end',
                }}
                label=''
                options={visibilityOptions}
                localizedField={{
                  ua: {
                    options: {
                      self: localize.orders.show.commentCard.forMe,
                      all: localize.orders.show.commentCard.forAll,
                      management: localize.orders.show.commentCard.forManagers,
                    },
                  },
                }}
                name='visibility'
                fieldType='text'
                onChange={(e) => setVisibility(e.target.value)}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
              <Button
                variant='rounded'
                onClick={submitComment}
                fullWidth={mobile}
                disabled={!commentText || !visibility || submitting}
              >
                {submitting ? (
                  <CircularProgress size={26} thickness={6} color='primary' />
                ) : (
                  localize.general.add
                )}
              </Button>
            </Box>
          </Box>
        </CardContent>
        <ConfirmationDialog
          header={localize.orders.show.commentCard.deleteCommentText}
          open={!!commentToDelete}
          description={localize.orders.show.commentCard.deleteDescription}
          action={() => deleteComment()}
          submitting={deleting}
          discard={() => setCommentToDelete(undefined)}
          mainActionButtonText={localize.orders.show.commentCard.deleteBtnText}
        />
      </Card>
    </Box>
  );
};

export default CommentsCard;

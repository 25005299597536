import { Button, styled, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import localize from '../../../../localize';
import { ColorSection, ContainerInner, MainTitle, MainText } from '../../Price';
import { Badge } from '../Badge';
import { type RenderedPlans, TarrifsTable } from './TarrifsTable';
import { useMobile } from '../../../../hooks/useMobile';
import { useTablet } from '../../../../hooks/useTablet';

export type Period = 'month' | 'year';

const TarrifsBetaTextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '16px',
  alignItems: 'center',
  marginBottom: '14px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '40px',
  },
}));

const TarrifsBetaText = styled(Typography)(({ theme }) => ({
  color: '#D4FFE0',
  fontSize: '16px',
  display: 'block',
  fontWeight: 700,
  fontFamily: '"Montserrat", sans-serif',
  marginBottom: '0',
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '15px',
    fontWeight: 500,
  },
}));

const PeriodButtonsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '12px',
  padding: '10px',
  backgroundColor: '#9E97EB',
  borderRadius: '100px',
  opacity: 0.5,
}));

const PeriodButton = styled(Button)(() => ({
  color: '#0c0c0c',
  textTransform: 'capitalize',
  pointerEvents: 'none',
}));

const TarrifsTableMainContainer = styled('section')(() => ({
  padding: '70px 0 95px 0',
  position: 'relative',
  color: '#0c0c0c',
}));

export const Tarrifs = () => {
  const [period, setPeriod] = useState<Period>('month');
  const mobile = useMobile();
  const tablet = useTablet();

  return (
    <>
      <ColorSection>
        <ContainerInner sx={{ justifyContent: 'center' }}>
          <MainTitle variant='h2'>
            {localize.landing.pricingPage.tarrifsSectionTitle}
          </MainTitle>
          <MainText variant='body1'>
            {localize.landing.pricingPage.tarrifsSectionDescription}
          </MainText>
          <TarrifsBetaTextContainer>
            <Badge>Beta</Badge>
            <TarrifsBetaText variant='body1'>
              {localize.landing.pricingPage.tarrifsSectionSubtext}
            </TarrifsBetaText>
          </TarrifsBetaTextContainer>
          <Tooltip title={localize.landing.pricingPage.tarrifsSectionTooltip}>
            <PeriodButtonsContainer
              sx={tablet ? { display: 'none' } : { display: 'flex' }}
            >
              <PeriodButton
                sx={{
                  backgroundColor: period === 'month' ? '#fff' : '#7B75CB',
                  '&:hover': {
                    backgroundColor: period === 'month' ? '#fff' : '#7B75CB',
                    color: '#0c0c0c',
                  },
                }}
                variant='rounded'
                onClick={() => setPeriod('month')}
              >
                {localize.landing.pricingPage.period.month}
              </PeriodButton>
              <PeriodButton
                sx={{
                  backgroundColor: period === 'year' ? '#fff' : '#7B75CB',
                  '&:hover': {
                    backgroundColor: period === 'year' ? '#fff' : '#7B75CB',
                    color: '#0c0c0c',
                  },
                }}
                variant='rounded'
                onClick={() => setPeriod('year')}
              >
                {localize.landing.pricingPage.period.year}
              </PeriodButton>
            </PeriodButtonsContainer>
          </Tooltip>
        </ContainerInner>
      </ColorSection>
      <TarrifsTableMainContainer>
        <ContainerInner
          sx={{
            gap: '60px',
          }}
        >
          {mobile ? (
            ['basic', 'standard', 'business', 'custom'].map((plan) => (
              <TarrifsTable
                key={plan}
                data={localize.landing.pricingPage.pricingPlansTable}
                period={period}
                plan={plan as RenderedPlans}
              />
            ))
          ) : (
            <TarrifsTable
              data={localize.landing.pricingPage.pricingPlansTable}
              period={period}
              plan='all'
            />
          )}
        </ContainerInner>
      </TarrifsTableMainContainer>
    </>
  );
};

import React, { FC, PropsWithChildren } from 'react';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { ukUA } from '@mui/material/locale';
import type {} from '@mui/x-data-grid/themeAugmentation';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    rounded: true;
    roundedSecondary: true;
    transparent: true;
    danger: true;
  }
}

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const CustomTheme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 320,
          sm: 576,
          md: 768,
          lg: 992,
          xl: 1200,
        },
      },
      palette: {
        primary: {
          light: '#E2E1F1',
          main: '#7B75CB',
          contrastText: '#fff',
        },
        secondary: {
          light: '#8B8B8B',
          main: '#464646',
          contrastText: '#fff',
        },
        info: {
          main: '#0A7AFF',
          contrastText: '#fff',
          light: '#E2E1F1',
        },
      },
      typography: {
        h1: {
          fontSize: '36px',
          fontFamily: 'Rubik, sans-serif',
          fontWeight: 400,
        },
        h2: {
          //new Body/medium 18;
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '21.94px',
        },
        h3: {
          // new don't use yet
          fontSize: '20px',
        },
        h4: {
          fontSize: '16px',
          fontFamily: 'Rubik, sans-serif',
          fontWeight: 400,
        },
        h5: {
          // new Body/medium 14
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '17.07px',
          fontFamily: 'Montserrat, sans-serif',
        },

        h6: {
          //new: Body/bold 16;
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '19.5px',
        },
        subtitle1: {
          //new: Body/regular 14;
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '17.07px',
        },
        subtitle2: {
          // new body/medium text 12
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '12px',
          fontWeight: 500,
          lineHeight: '14.63px',
        },

        body1: {
          //new: Body/regular 14;
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '17.07px',
        },
        body2: {
          // new body/small text 12
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '14.63px',
        },
      },
      components: {
        MuiList: {
          styleOverrides: {
            root: {
              maxWidth: '100%',
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            root: {
              'span, p': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily: "'Montserrat', sans-serif",
              textTransform: 'lowercase',
              borderRadius: '10px',
              textWrap: 'nowrap',
            },
          },
          variants: [
            {
              props: { variant: 'rounded' },
              style: ({ theme }) => ({
                borderRadius: '30px',
                fontFamily: "'Montserrat', sans-serif",
                padding: '12px 32px',
                backgroundColor: '#7B75CB',
                boxShadow: '0px 11px 20px rgba(122, 104, 193, 0.33)',
                textTransform: 'none',
                color: '#fff',
                [theme.breakpoints.down('md')]: {
                  padding: '12px 24px',
                },
                '&:hover': {
                  backgroundColor: '#AFABEF',
                  color: '#fff',
                },
                '&:disabled': {
                  backgroundColor: '#D3D0FF',
                  boxShadow: '0px 4px 20px 0px #F4C3DE33',
                  color: '#fff',
                },
              }),
            },
            {
              props: { variant: 'roundedSecondary' },
              style: {
                borderRadius: '30px',
                fontFamily: "'Montserrat', sans-serif",
                backgroundColor: 'transparent',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: '#fff',
                padding: '12px 32px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#AFABEF',
                  color: '#fff',
                  borderColor: 'transparent',
                },
              },
            },
            {
              props: { variant: 'transparent' },
              style: {
                backgroundColor: 'transparent',
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'transparent',
                borderRadius: '30px',
                padding: '12px 24px',
                color: '#7B75CB',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 400,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
            {
              props: { variant: 'danger' },
              style: {
                borderRadius: '30px',
                padding: '12px 20px',
                backgroundColor: '#FA3B3B',
                textTransform: 'none',
                color: '#fff',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 400,
                '&:hover': {
                  backgroundColor: '#FA3B3B',
                },
                '&:disabled': {
                  opacity: 0.5,
                  cursor: 'not-allowed',
                  color: '#fff',
                },
              },
            },
          ],
        },
        MuiCollapse: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.in && {
                borderBottom: '1px solid rgb(234, 236, 240)',
              }),
            }),
          },
        },
        MuiChip: {
          styleOverrides: {
            filled: ({ ownerState }) =>
              ownerState.color === 'default'
                ? {
                    backgroundColor: 'white',
                    transitionDuration: '0',
                  }
                : {
                    backgroundColor: '#7B75CB',
                    transitionDuration: '0',
                  },
            root: {
              borderRadius: '24px',
              border: '1px solid #464646',
              backgroundColor: '#fff',
              height: '24px',
            },
            label: {
              padding: '0 4px 0 8px',
              fontSize: '12px',
            },
            deleteIcon: {
              margin: '0 2px',
              '&:hover': {
                '& path': {
                  fill: '#000',
                },
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              ...(ownerState.elevation === 2
                ? {
                    boxShadow:
                      'rgb(234, 236, 240) 0px 0px 1px, rgba(29, 41, 57, 0.08) 0px 1px 2px',
                  }
                : {
                    boxShadow: 'none',
                  }),

              '&.-inner': {
                background: 'rgb(249, 250, 251)',
              },
            }),
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: ({ ownerState }) => ({
              position: 'relative',

              '&.main th, &.-inner th': {
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '17.07px',
                fontFamily: 'Montserrat, sans-serif',
                background: '#fff',
                borderBottomColor: '#7B75CB',
              },

              '&.main th': {
                color: '#0C0C0C',
              },
              '&.-inner th': {
                color: '#464646',
              },

              '& td': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '14.63px',
                borderBottomColor: '#DFDDF9',
                textWrap: 'balance',
              },

              '&.-opened td': {
                borderBottomColor: '#7B75CB',
              },
              ...(ownerState.hover && {
                '&:hover:not(.-inner)': {
                  cursor: 'pointer',
                  background: '#E2E1F1',
                },
                '&.-inner:hover': {
                  cursor: 'pointer',
                  background: '#E2E1F1',
                },
              }),
              '&.-color-row': {
                background: '#E2E1F1',
              },
              '&.-inner td, &.-inner th': {
                border: 0,
              },
            }),
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              '&.column-sticky-right': {
                position: 'sticky',
                right: '0',
                justifyContent: 'flex-end',
                textAlign: 'right',
                background: 'inherit',
                padding: '16px 2px',
                '@media (min-width: 769px)': {
                  padding: '16px',
                },
              },
              '@media (min-width: 769px)': {
                '&.column-sticky-left': {
                  position: 'sticky',
                  background: 'inherit',
                },
              },

              '&.row-image': {
                padding: '0',
              },

              '&:not(.expand-column) p': {
                textWrap: 'balance',
              },
              '&.expand-column': {
                verticalAlign: 'top',

                p: {
                  wordBreak: 'break-word',
                },
              },
            },
            head: {
              color: '#8B8B8B',
              fontSize: '12px',
              fontWeight: 400,
              paddingTop: '6px',
              paddingBottom: '6px',
            },
          },
        },
        MuiPagination: {
          styleOverrides: {
            root: {
              border: '1px solid #DFDDF9',
              padding: '10px',
              borderRadius: '26px',
              fontFamily: 'Montserrat, sans-serif',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '14.63px',
              color: '#464646',
              '& .Mui-selected': {
                background: '#AFABEF',
              },
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              borderBottom: '1px solid #F1F1F1',
              '& .MuiTabs-scroller.MuiTabs-fixed': {
                width: '100%',
                overflow: 'auto!important',
                '& .MuiTabs-flexContainer': {
                  paddingBottom: '2px',
                },
              },

              '& .MuiTab-root': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '17.07px',
                color: '#0C0C0C',
                textTransform: 'none',
              },
              '& .Mui-selected': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '17.07px',
                color: '#7B75CB',
                textTransform: 'none',
              },
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            root: ({ ownerState }: { ownerState: any }) => ({
              ...(ownerState.severity === 'info'
                ? {
                    alignItems: 'center',
                    padding: '16px',
                    borderRadius: '4px',
                    background: '#E8F5FC',
                    marginBottom: '10px',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '14.63px',
                    color: '#000',
                  }
                : {}),
            }),
          },
        },
        MuiTextField: {
          styleOverrides: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            root: ({ ownerState }: { ownerState: any }) => ({
              ...(ownerState.variant === 'outlined'
                ? {
                    '& .MuiFormLabel-root.MuiInputLabel-root': {
                      position: 'static',
                      transform: 'none',
                      fontFamily: 'Montserrat, sans-serif',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '17.07px',
                      color: '#0C0C0C',
                      padding: '5px 0 10px',
                      pointerEvents: 'auto',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '20px',
                      borderColor: '#D9D9D9',
                      padding: '8px 16px',
                      height: '100%',
                      minHeight: '40px',
                      '&>*': { display: 'none' },
                    },
                    '& .MuiSelect-select.MuiSelect-outlined': {
                      padding: '0 0 5px',
                    },
                    '&>.MuiInputBase-root.MuiOutlinedInput-root': {
                      borderRadius: '20px',
                      borderColor: '#D9D9D9',
                      padding: '8px 16px',
                      fontFamily: 'Montserrat, sans-serif',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '17.07px',
                      color: '#464646',
                      background: '#fff',
                      '&>svg': {
                        marginTop: '-5px',
                        padding: '0',
                      },
                      '& button': {
                        marginTop: '-10px',
                        padding: '0',
                      },
                      '& input': {
                        marginTop: '-3px',
                        padding: '0 0 5px',
                        zIndex: 5,
                        '&::placeholder': {
                          color: '#000',
                        },
                      },
                    },
                  }
                : {}),
            }),
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            root: {
              '& .MuiCardHeader-title': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '19.5px',
                color: '#9B96EB',
              },
              '& .MuiCardHeader-subheader': {
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '15.85px',
                color: '#464646',
                marginTop: '8px',
              },
            },
          },
        },
      },
    },
    ukUA
    // localStorage.getItem('locale') === 'ua' ? ukUA : {},
  );

  return <MuiThemeProvider theme={CustomTheme}>{children}</MuiThemeProvider>;
};

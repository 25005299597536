import { FC, useContext, useMemo, useState } from 'react';
import { AnyObject } from '../../api/anyObjectTypes';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import localize from '../../localize';
import { Invoice } from '../Documents';
import { Order } from '../../api/OrderApiClient';
import DocumentsMenuItem from '../DocumentsMenu/DocumentsMenuItem';
import { CommonContext } from '../../contexts/CommonContext';
import CustomSwitch from '../CustomSwitch/CustomSwitch';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '32px',
    border: '1px solid #AFABEF',
    background: '#fff',
    padding: '32px',
    margin: '0',
    display: 'flex',
    width: '520px',
    maxWidth: '520px',
    height: '380px',
  },
}));

type CreatePDFFromOrderProps = {
  open: boolean;
  order: AnyObject;
  close: () => void;
};

const CreatePDFFromOrder: FC<CreatePDFFromOrderProps> = ({
  open,
  order,
  close,
}) => {
  const [fromWho, setFromWho] = useState<'manufacturer' | 'dropshipper'>(
    'manufacturer'
  );
  const [toWho, setToWho] = useState<'dropshipper' | 'client'>('dropshipper');

  const { currentProject } = useContext(CommonContext);

  const InvoiceToShow = useMemo(
    () => (
      <Invoice
        order={order as Order}
        fromWho={fromWho}
        toWho={toWho}
        project={{
          legal_name: currentProject.legal_name || currentProject.title,
          edropoy_code: currentProject.edropoy_code,
          iban: currentProject.iban,
        }}
      />
    ),
    [fromWho, toWho, order, currentProject]
  );

  return (
    <StyledDialog
      open={open}
      fullWidth={true}
      onClose={() => {
        close();
      }}
    >
      <>
        <Typography color='#464646' variant='h6' mb='30px'>
          {localize.orders.createPDF.title}
        </Typography>
        <Typography color='#0C0C0C' variant='h5' mb='10px'>
          {localize.orders.createPDF.fromWho}
        </Typography>
        <CustomSwitch
          checkedOption={localize.orders.createPDF.dropshipper}
          option={localize.orders.createPDF.manufacturer}
          onChange={(checked) => {
            setFromWho(checked ? 'dropshipper' : 'manufacturer');
            checked && setToWho('client');
          }}
          checked={fromWho === 'dropshipper'}
          firstOptionWidth={100}
        />
        <Divider sx={{ borderColor: '#DFDDF9', margin: '20px 0' }} />
        <Typography color='#0C0C0C' variant='h5' mb='10px'>
          {localize.orders.createPDF.toWho}
        </Typography>
        <Tooltip
          placement='top'
          title={
            fromWho === 'dropshipper'
              ? localize.orders.createPDF.tooltipText
              : ''
          }
        >
          <Box>
            <CustomSwitch
              checkedOption={localize.orders.createPDF.client}
              option={
                <span
                  style={{
                    color:
                      fromWho === 'dropshipper'
                        ? '#DFDDF9'
                        : toWho !== 'dropshipper'
                          ? '#0C0C0C'
                          : '#7B75CB',
                  }}
                >
                  {localize.orders.createPDF.dropshipper}
                </span>
              }
              disabled={fromWho === 'dropshipper'}
              checked={toWho !== 'dropshipper'}
              onChange={(checked) => {
                setToWho(checked ? 'client' : 'dropshipper');
              }}
              firstOptionWidth={100}
            />
          </Box>
        </Tooltip>
        <br />
        <br />
        <br />
        <DocumentsMenuItem
          btnText={localize.orders.createPDF.createBtn}
          close={() => {
            close();
          }}
          document={InvoiceToShow}
        />
        <IconButton
          aria-label='close'
          data-cy='order-create-PDF-close-btn'
          onClick={() => {
            close();
          }}
          sx={(theme) => ({
            position: 'absolute',
            right: 20,
            top: 20,
            padding: '2px',
            color: '#9B96EB',
            border: '1px solid #9B96EB',
          })}
        >
          <CloseIcon />
        </IconButton>
      </>
    </StyledDialog>
  );
};

export default CreatePDFFromOrder;

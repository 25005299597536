import { FC, useContext, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { Typography, IconButton } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';

import { AnyObject } from '../../api/anyObjectTypes';
import { ApiKeysApiClient } from '../../api/ApiKeysApiClient';
import { useAsyncError } from '../../hooks/useAsyncError';
import { formatDateTime } from '../../utils/time';
import { CommonContext } from '../../contexts/CommonContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import Loading from '../../components/Loading/Loading';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import TextWithCopyButton from '../../components/TextWithCopyButton/TextWithCopyButton';
import localize from '../../localize';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { DeleteItem } from '../../components/ActionsForTable';
import { useMobile } from '../../hooks/useMobile';

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '30px',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));

export const ApiKeysManagePage: FC = () => {
  const { currentProject, currentUser } = useContext(CommonContext);
  const throwError = useAsyncError();
  const [rows, setRows] = useState<AnyObject[]>([]);
  const [itemForDelete, setItemForDelete] = useState<AnyObject | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { setNotification } = useContext(NotificationContext);
  const mobile = useMobile();

  const columns = [
    {
      field: 'id',
      align: 'left',
      headerName: `${localize.api_integration.api_keys.table_fields.id}`,
      width: '130px',
    },
    {
      field: 'access_key',
      align: 'left',
      headerName: `${localize.api_integration.api_keys.table_fields.access_key}`,
      valueGetter: (row: any) => <TextWithCopyButton text={row.access_key} />,
    },
    {
      field: 'created_at',
      align: 'right',
      headerName: `${localize.api_integration.api_keys.table_fields.created_at}`,
      valueGetter: (row: any) =>
        row.created_at && formatDateTime(row.created_at),
    },
    {
      field: 'expires_at',
      align: 'right',
      headerName: `${localize.api_integration.api_keys.table_fields.expires_at}`,
      valueGetter: (row: any) =>
        row.expires_at && formatDateTime(row.created_at),
    },
    {
      field: 'actions',
      headerName: localize.products.columns.actions,
      align: 'left',
      className: 'column-sticky-right',
      width: mobile ? '45px' : '100px',
      renderCell: (row: AnyObject) => (
        <DeleteItem
          onClick={(row: AnyObject) => {
            setItemForDelete(row);
          }}
          key={`delete_item-${row.id}`}
          item={row}
        />
      ),
    },
  ];

  const create = async (): Promise<void> => {
    setLoading(true);
    await ApiKeysApiClient.create(currentProject.id)
      .catch((e) => {
        throwError(e);
      })
      .finally(() => {
        setNotification({
          severity: 'success',
          message: localize.general.successCreateMessage,
        });
        preparePageData().finally(() => setLoading(false));
      });
  };

  const deleteItem = () => {
    itemForDelete &&
      ApiKeysApiClient.remove(currentProject.id, itemForDelete.id)
        .then(() => {
          setRows(rows.filter((row) => row.id !== itemForDelete.id));
          setNotification({
            severity: 'success',
            message: localize.general.successDeleteMessage,
          });
          setItemForDelete(null);
        })
        .catch((e) => {
          setNotification({
            severity: 'warning',
            message: localize.general.unexpectedError,
          });
        });
  };

  const preparePageData = async (): Promise<void> => {
    const apiRows = await ApiKeysApiClient.getIndex(currentProject.id).catch(
      (e) => {
        throwError(e);
      }
    );

    setRows(apiRows || []);
  };

  useEffect(() => {
    setLoading(true);
    preparePageData().finally(() => setLoading(false));
  }, [currentProject]);

  if (loading) return <Loading />;

  return (
    <InnerContainer>
      <ConfirmationDialog
        header={`${localize.products.delete.title} ${localize.api_integration.delete_item}?`}
        image='/delete-image.svg'
        open={!!itemForDelete}
        description={localize.products.delete.text}
        action={() => deleteItem()}
        discard={() => setItemForDelete(null)}
        mainActionButtonText={localize.products.delete.deleteBtn}
      />
      <Typography variant='h6' color='#464646'>
        {localize.api_integration.api_keys.title}
      </Typography>
      {currentUser.user_permissions.api_key?.create?.length && (
        <IconButton
          onClick={() => {
            create();
          }}
          data-cy='manage-keys-create-btn'
          sx={{
            padding: '12px 24px',
            background: '#7B75CB',
            borderRadius: '100px',
            '&:hover': { background: '#7B75CB' },
          }}
        >
          <AddIcon sx={{ fill: '#fff' }} />
        </IconButton>
      )}

      {!!rows.length ? (
        <BasicTable rows={rows} columns={columns} />
      ) : (
        <EmptyContent />
      )}
    </InnerContainer>
  );
};

export default ApiKeysManagePage;

import { useContext, useState } from 'react';
import { Box, CircularProgress, Link, useTheme } from '@mui/material';
import { CustomAlert } from '../../components/CustomAlert';
import localize from '../../localize';
import { CommonContext } from '../../contexts/CommonContext';
import { Auth0ApiClient } from '../../api/Auth0ApiClient';
import { NotificationContext } from '../../contexts/NotificationContext';

export const Security = () => {
  const theme = useTheme();
  const { currentUser } = useContext(CommonContext);
  const { setNotification } = useContext(NotificationContext);
  const [sending, setSending] = useState(false);
  const triggerMail = () => {
    setSending(true);
    Auth0ApiClient.triggerPasswordResetEmail(currentUser?.email)
      .then(() => {
        setNotification({
          severity: 'success',
          message: localize.profile.edit.security.successToast,
        });
      })
      .catch((e) => {
        setNotification({
          severity: 'error',
          message: localize.profile.edit.security.errorToast,
        });
      })
      .finally(() => {
        setSending(false);
      });
  };
  return (
    <CustomAlert>
      <p>{localize.profile.edit.security.alert.firstLine}</p>
      <Box display='flex' alignItems='center'>
        <Link
          onClick={(e) => {
            if (sending) return;
            e.preventDefault();
            triggerMail();
          }}
          color={theme.palette.info.main}
          sx={
            sending
              ? { mr: 1, opacity: sending ? 0.5 : 1, pointerEvents: 'none' }
              : { cursor: 'pointer' }
          }
        >
          {localize.profile.edit.security.alert.linkText}
        </Link>
        {sending && <CircularProgress size={12} color='info' />}
      </Box>
    </CustomAlert>
  );
};

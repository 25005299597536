import { Button, styled, Typography } from '@mui/material';
import { useState } from 'react';
import localize from '../../../localize';
import Saly2 from '../../../assets/landing/salySecond.svg';
import doodle1 from '../../../assets/landing/doodle1.png';
import doodle3 from '../../../assets/landing/doodle3.png';
import { ContactModal } from './ContactModal';
import { useMobile } from '../../../hooks/useMobile';
import CommentPurpleImg from '../../../assets/landing/comment-matte.svg';

const AboutUsSectionInner = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '6% 20px 2% 0',
  position: 'relative',
  paddingTop: '6%',
  justifyContent: 'normal',
  overflowX: 'visible',
  alignItems: 'center',
  columnGap: '48px',
  marginTop: '0',
  background: `
  url(${doodle1}) 40% 0 no-repeat,
  url(${doodle3}) 3% 100% no-repeat`,
  width: '100%',
  maxWidth: '1280px',
  minWidth: '320px',
  marginRight: 'auto',
  [theme.breakpoints.down('lg')]: {
    background: 'none',
    width: '100%',
    maxWidth: '100%',
  },
  [theme.breakpoints.down('md')]: {
    padding: '6% 0 2% 0',
    background: 'none',
    width: '100%',
    flexDirection: 'column',
    rowGap: '48px',
  },
}));

const AboutUsSectionContainer = styled('section')(({ theme }) => ({
  position: 'relative',
  padding: '6% 0',
  color: '#0c0c0c',
  overflowX: 'hidden',
}));

const RoundedBg = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '274px',
  minWidth: '500px',
  [theme.breakpoints.down('lg')]: {
    minWidth: '400px',
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '50%',
    height: '244px',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '50%',
  },

  '&::after': {
    content: "' '",
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: '100vw',
    backgroundColor: '#DFDDF9',
    borderTopRightRadius: '24px',
    borderBottomRightRadius: '24px',
    zIndex: '-1',
    [theme.breakpoints.down('md')]: {
      maxHeight: '244px',
    },
  },
}));

const RoudedBgInnerImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: '-4%',
  [theme.breakpoints.down('md')]: {
    transform: 'translateX(-70%) translateY(-10%) scale(1.3)',
  },
}));

const AboutUsContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '40px',

  '& p': {
    margin: '0',
  },

  '& p.about-cta': {
    fontWeight: 500,
    color: '#464646',
  },

  '& span.first-word': {
    fontWeight: 500,
    color: '#7B75CB',
    fontSize: '18px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 20px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}));

export const AboutUsSection = () => {
  const mobile = useMobile();
  const [openModal, setOpenModal] = useState(false);
  const description: string = localize.landing.aboutSection.description;
  const firstWord: string = description.split(' ')[0];
  const restOfDescription: string =
    ' ' + description.split(' ').slice(1).join(' ');

  return (
    <AboutUsSectionContainer id='about'>
      <AboutUsSectionInner>
        <RoundedBg>
          <RoudedBgInnerImg src={Saly2} alt='saly' />
        </RoundedBg>
        <AboutUsContent>
          <Typography variant='body1'>
            <Typography component='span' className='first-word'>
              {firstWord}
            </Typography>
            <Typography component='span' className='rest-of-description'>
              {restOfDescription}
            </Typography>
          </Typography>
          <Typography variant='body1' component='p' className='about-cta'>
            {localize.landing.aboutSection.CTA}
          </Typography>
          <Button
            variant='rounded'
            onClick={() => setOpenModal(true)}
            sx={mobile ? { width: '100%' } : { width: 'fit-content' }}
          >
            {localize.landing.aboutSection.CTAButton}
          </Button>
          <ContactModal
            open={openModal}
            description={localize.landing.modal.consultationDescription}
            handleClose={() => setOpenModal(false)}
            handleSubmit={() => setOpenModal(false)}
            title={localize.landing.modal.titleConsultation}
            imgPath={CommentPurpleImg}
            note={localize.landing.modal.consultationFormNoteText}
          />
        </AboutUsContent>
      </AboutUsSectionInner>
    </AboutUsSectionContainer>
  );
};

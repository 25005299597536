import React, { useContext, useRef } from 'react';
import { Box, Button, Modal, styled, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import localize from '../../../localize';
import { create } from '../api/RequestForm';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { useMobile } from '../../../hooks/useMobile';

type ModalProps = {
  open: boolean;
  handleClose: () => void;
  title: string;
  description?: string;
  imgPath: string;
  note?: string;
  handleSubmit: () => void;
};

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  flexDirection: 'column',
  width: '950px',
  margin: '16px',
  backgroundColor: '#fff',
  fontFamily: 'Montserrat, sans-serif',
  borderRadius: '32px',
  padding: '32px 32px 64px 32px',
  [theme.breakpoints.down('md')]: {
    margin: '10px',
    padding: '16px 16px 32px 16px',
  },
}));

const ContactForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '550px',
  width: '100%',
  alignItems: 'center',
  label: {
    display: 'flex',
    fontSize: '16px',
    width: '100%',
    fontWeight: 600,
    flexDirection: 'column',
    gap: '8px',
    fontFamily: 'Montserrat, sans-serif',
  },
}));

const ContactModalInput = styled('input')(() => ({
  display: 'flex',
  fontSize: '16px',
  border: '1px solid #d8d8d8',
  borderRadius: '20px',
  outline: '1px solid transparent',
  fontWeight: 400,
  width: '100%',
  padding: '8px 16px',
  fontFamily: 'Montserrat, sans-serif',
  '&::placeholder': {
    color: '#B3B3B3',
  },
}));

const ModalHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  marginBottom: '48px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '32px',
  },
  [theme.breakpoints.down('sm')]: {
    marginBottom: '16px',
  },
}));

const CloseButton = styled('button')(({ theme }) => ({
  width: '40px',
  height: '40px',
  backgroundColor: 'transparent',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    width: '32px',
    height: '32px',
  },
}));
const ModalMainContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  alignItems: 'flex-start',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
  },
}));

const FormInner = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: '16px',
  width: '100%',
}));

const StyledImg = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  width: '280px',
  height: '280px',
  [theme.breakpoints.down('md')]: {
    width: '200px',
    height: '200px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100px',
    height: '100px',
  },
}));

const ModalHeaderTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 500,
  flex: 1,
  fontSize: '28px',
  fontFamily: 'Montserrat, sans-serif',
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '19.5px',
  },
}));

const ModalDescription = styled(Typography)(({ theme }) => ({
  marginBottom: '32px',
  fontSize: '14px',
  textAlign: 'center',
  fontFamily: 'Montserrat, sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    lineHeight: '15px',
    marginBottom: '20px',
  },
}));

export const ContactModal = ({
  open,
  handleClose,
  handleSubmit,
  imgPath,
  title,
  description,
  note,
}: ModalProps) => {
  const mobile = useMobile();
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailOrPhoneInputRef = useRef<HTMLInputElement>(null);
  const messageInputRef = useRef<HTMLInputElement>(null);
  const { setNotification } = useContext(NotificationContext);

  const emailOrPhonePattern =
    '^([\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6})|([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)$/';

  const onFormSubmit = () => {
    if (
      emailOrPhoneInputRef.current?.value ||
      nameInputRef.current?.value ||
      messageInputRef.current?.value
    )
      create({
        name: nameInputRef.current?.value,
        email: emailOrPhoneInputRef.current?.value,
        comment: messageInputRef.current?.value,
      }).then(() => {
        setNotification({
          severity: 'success',
          message: localize.landing.successSentMessage,
        });
        if (nameInputRef.current?.value) nameInputRef.current.value = '';
        if (emailOrPhoneInputRef.current?.value)
          emailOrPhoneInputRef.current.value = '';
        if (messageInputRef.current?.value) messageInputRef.current.value = '';
        handleClose();
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <StyledBox>
        <ModalHeader>
          <ModalHeaderTitle variant='h2'>{title}</ModalHeaderTitle>
          <CloseButton onClick={handleClose}>
            <Close sx={mobile ? { fontSize: '22px' } : { fontSize: '28px' }} />
          </CloseButton>
        </ModalHeader>
        {description && (
          <ModalDescription id='modal-description' variant='body1' sx={{}}>
            {description}
          </ModalDescription>
        )}

        <ModalMainContent>
          <StyledImg src={imgPath} alt={title} />
          <ContactForm onSubmit={handleSubmit}>
            <FormInner>
              <Box component='label'>
                {localize.landing.modal.formLabels.name} {'*'}
                <ContactModalInput
                  type='text'
                  ref={nameInputRef}
                  id='name'
                  placeholder={localize.landing.modal.formLabels.name}
                  required
                />
              </Box>
              <Box component='label'>
                {localize.landing.modal.formLabels.emailOrPhone} {'*'}
                <ContactModalInput
                  type='text'
                  ref={emailOrPhoneInputRef}
                  pattern={emailOrPhonePattern}
                  id='email-phone'
                  placeholder={localize.landing.modal.formLabels.emailOrPhone}
                  required
                />
              </Box>
              <Box component='label'>
                {localize.landing.modal.formLabels.message}{' '}
                {messageInputRef.current?.required && '*'}
                <ContactModalInput
                  ref={messageInputRef}
                  type='text'
                  id='message'
                  placeholder={localize.landing.modal.formLabels.message}
                />
              </Box>
            </FormInner>
            {note && (
              <Typography
                sx={{
                  marginTop: '24px',
                  fontSize: '12px',
                  textAlign: 'left',
                  fontFamily: 'Montserrat, sans-serif',
                }}
              >
                {note}
              </Typography>
            )}
            <Button
              variant='rounded'
              sx={
                mobile
                  ? { marginTop: '24px', width: '100%' }
                  : { marginTop: '48px' }
              }
              onClick={onFormSubmit}
            >
              {localize.landing.modal.formLabels.submit}
            </Button>
          </ContactForm>
        </ModalMainContent>
      </StyledBox>
    </Modal>
  );
};

import { FC } from 'react';
import { List, ListItem, styled, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
type NavigationProps = {
  links: { name: string; path: string }[];
  menuIsOpen: boolean;
  setMenuIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const StyledNavLink = styled(Link)(({ theme }) => ({
  padding: '10px 0',
  borderBottom: `2px solid transparent`,
  textDecoration: 'none',
  whiteSpace: 'nowrap',
  fontWeight: 500,
  transition: 'border .25s ease-in-out',
  color: '#0c0c0c',
  '&:hover': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  '&.active': {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
    border: '2px solid transparent',
    borderRadius: '30px',
    width: '100%',
    padding: '12px 32px',
    transitionProperty: 'background-color, border, color',
    '&.active': {
      backgroundColor: '#7B75CB',
      border: '2px solid #7B75CB',
      color: '#fff',
    },
    '&:hover': {
      border: '2px solid #7B75CB',
      backgroundColor: '#7B75CB',
      color: '#fff',
    },
  },
}));

const StyledNav = styled('nav')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    minWidth: '100%',
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  display: 'inline-flex',
  padding: 0,
  marginRight: 2,
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  flex: '1 1 auto',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    justifyContent: 'center',
  },
}));

export const Navigation: FC<NavigationProps> = ({
  links,
  menuIsOpen,
  setMenuIsOpen,
}) => {
  const location = useLocation();

  const handleCloseMenu = () => {
    menuIsOpen && setMenuIsOpen(false);
  };

  return (
    <StyledNav>
      <StyledList>
        {links.map(({ name, path }) => (
          <StyledListItem key={name}>
            <StyledNavLink
              className={path === location.pathname ? 'active' : ''}
              href={path}
              onClick={handleCloseMenu}
            >
              {name}
            </StyledNavLink>
          </StyledListItem>
        ))}
      </StyledList>
    </StyledNav>
  );
};

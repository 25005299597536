// TODO add avatar to user
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Link,
  styled,
  Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import localize from '../../localize';
// import avatarPlaceholder from '../../../assets/onboarding/logo-placeholder.svg';
import { matchIsValidTel } from 'mui-tel-input';
import { StyledTelInput } from '../../components/StyledInput';
import { useAsyncError } from '../../hooks/useAsyncError';
import { CommonContext } from '../../contexts/CommonContext';
import { UserApiClient } from '../../api/UserApiClient';
import { uploadBlobToAws } from '../../utils/aws';
import { CustomAlert } from '../../components/CustomAlert';
import { ImageData } from '../../utils/types';
import { LogoUpload } from '../../components/LogoUpload/LogoUpload';
import { NotificationContext } from '../../contexts/NotificationContext';
import { TextField } from '@mui/material';
import { useMobile } from '../../hooks/useMobile';

const MainSettingsForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '54px',
}));

const StartInputWrapper = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'center',
  marginTop: '20px',
}));

const InputsGroup = styled('div')(({ theme }) => ({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '24px',
  rowGap: '12px',
  alignItems: 'center',
  marginTop: '20px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
    '&.purple-text-label': {
      color: '#8D83FE',
    },
    '': {
      padding: '0',
    },
  },
}));

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '53px',
}));

export const ButtonsBox = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-start',
}));

type FormData = {
  name: string;
  surname: string;
  phoneNumber: string;
  email: string;
};

export const MainSettings = () => {
  const { currentUser, setCurrentUser } = useContext(CommonContext);
  const { setNotification } = useContext(NotificationContext);
  const mobile = useMobile();
  const throwError = useAsyncError();
  // const [imageData, setImageData] = useState<ImageData>({
  //   path: null,
  //   files: [],
  // });

  const initialStateFormData = {
    name: `${currentUser?.name?.split(' ')?.[0] || ''}`,
    surname: `${currentUser?.name?.split(' ')?.[1] || ''}`,
    phoneNumber: currentUser?.phone_number || '',
    email: currentUser?.email || '',
  };

  const [formData, setFormData] = useState<FormData>(initialStateFormData);

  const setInitFormData = useCallback(() => {
    setFormData(initialStateFormData);
  }, [currentUser]);

  const isValidEmail = useCallback((email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  }, []);

  const [submitting, setSubmitting] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  useEffect(() => {
    if (currentUser?.auth0_id) {
      setInitFormData();
    }
  }, [currentUser, setInitFormData]);

  useEffect(() => {
    setPhoneIsValid(matchIsValidTel(formData.phoneNumber));
  }, [formData.phoneNumber]);

  const hasFormChanged = useMemo(() => {
    return (
      formData.name !== `${currentUser?.name?.split(' ')?.[0] || ''}` ||
      formData.surname !== `${currentUser?.name?.split(' ')?.[1] || ''}` ||
      formData.phoneNumber !== currentUser?.phone_number ||
      formData.email !== currentUser?.email
    );
  }, [currentUser, formData]);

  const onSubmit = async () => {
    try {
      const { name, surname, phoneNumber, email } = formData;
      setSubmitting(true);
      // const uploadedAvatar = null
      //   formData.image.files.length > 0
      //     ? await uploadBlobToAws({
      //         projectId: currentUser?.id as string,
      //         ApiClient: UserApiClient,
      //         contentType: formData.image.files[0].type,
      //         files: formData.image.files,
      //       })
      //     : null;

      const updatedUser = await UserApiClient.updateSelf(
        {
          user: {
            name: `${name} ${surname}`,
            email,
            phone_number: phoneNumber,
            // picture: uploadedAvatar?.success[0],
          },
        },
        currentUser?.auth0_id as string
      );
      setSubmitting(false);
      if (currentUser?.email !== email) {
        window.location.href = '/cp/profile';
        return;
      }

      setCurrentUser({
        ...currentUser,
        name: updatedUser.name,
        email: updatedUser.email || currentUser?.email,
        phone_number: updatedUser.phone_number,
      });

      setNotification({
        severity: 'success',
        message: localize.general.successUpdateMessage,
      });
    } catch (error) {
      throwError(error);
      setNotification({
        severity: 'error',
        message: localize.general.unexpectedError,
      });
    }
  };

  useEffect(() => {
    const isValidForm =
      !!formData.name &&
      !!formData.surname &&
      (formData.phoneNumber === '' || !!phoneIsValid) &&
      isValidEmail(formData.email);
    setFormIsValid(isValidForm);
  }, [formData, phoneIsValid, isValidEmail]);

  return (
    <Grid item xs={12}>
      <CustomAlert>
        {localize.profile.edit.mainSettings.alert.firstLine}
      </CustomAlert>
      <MainSettingsForm>
        <StyledFormControl
          sx={{ maxWidth: mobile ? '100%' : '992px', rowGap: '32px' }}
        >
          {/* <StartInputWrapper>
            <LogoUpload imageData={imageData} setImageData={setImageData} />
          </StartInputWrapper> */}
          <InputsGroup>
            <LabelGroup>
              <label htmlFor='name'>
                {localize.profile.edit.mainSettings.labels.name}
              </label>
              <TextField
                id='name'
                value={formData.name}
                placeholder={
                  localize.profile.edit.mainSettings.labels.namePlaceholder
                }
                helperText={
                  !formData.name
                    ? localize.profile.edit.mainSettings.labels.required
                    : ' '
                }
                name='name'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    name: event.target.value,
                  })
                }
              />
            </LabelGroup>
            <LabelGroup>
              <label htmlFor='surname'>
                {localize.profile.edit.mainSettings.labels.surnamePlaceholder}
              </label>
              <TextField
                id='surname'
                value={formData.surname}
                placeholder={localize.profile.edit.mainSettings.labels.surname}
                name='surname'
                helperText={
                  !formData.surname
                    ? localize.profile.edit.mainSettings.labels.required
                    : ' '
                }
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    surname: event.target.value,
                  })
                }
              />
            </LabelGroup>

            <LabelGroup>
              <label htmlFor='phone'>
                {localize.profile.edit.mainSettings.labels.phoneNumber}
              </label>
              <StyledTelInput
                id='phone'
                defaultCountry={'UA'}
                onlyCountries={['UA']}
                required
                forceCallingCode
                langOfCountryName={'ua'}
                InputProps={{ sx: { color: '#333' } }}
                value={formData.phoneNumber}
                error={!phoneIsValid && formData.phoneNumber !== ''}
                helperText={
                  !phoneIsValid
                    ? formData.phoneNumber === ''
                      ? ' '
                      : localize.profile.edit.mainSettings.labels.formatError
                    : ' '
                }
                name='phone'
                onChange={(value: string) => {
                  setFormData({ ...formData, phoneNumber: value });
                  setPhoneIsValid(matchIsValidTel(value));
                }}
              />
            </LabelGroup>
            <LabelGroup>
              <label htmlFor='email'>
                {localize.profile.edit.mainSettings.labels.email}
              </label>
              <TextField
                id='email'
                type='email'
                value={formData.email}
                placeholder={
                  localize.profile.edit.mainSettings.labels.emailPlaceholder
                }
                helperText={
                  formData.email !== ''
                    ? isValidEmail(formData.email)
                      ? ' '
                      : localize.profile.edit.mainSettings.labels.formatError
                    : ' '
                }
                error={formData.email !== '' && !isValidEmail(formData.email)}
                name='email'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    email: event.target.value,
                  })
                }
              />
            </LabelGroup>
          </InputsGroup>
          <ButtonsBox>
            <Button
              variant='rounded'
              data-cy='main-settings-apply-btn'
              onClick={onSubmit}
              disabled={!formIsValid || submitting || !hasFormChanged}
              endIcon={
                submitting ? (
                  <CircularProgress size={16} color='inherit' />
                ) : null
              }
            >
              {localize.profile.edit.buttonApply}
            </Button>
            <Button
              variant='transparent'
              data-cy='main-settings-clear-btn'
              disabled={submitting || !hasFormChanged}
              onClick={() => setInitFormData()}
            >
              {localize.profile.edit.buttonClear}
            </Button>
          </ButtonsBox>
        </StyledFormControl>
      </MainSettingsForm>
    </Grid>
  );
};

const auth0domain = !process.env.REACT_APP_POINT_TO_STAGING
  ? 'factorywise.eu.auth0.com'
  : 'dev-9frarrow.us.auth0.com';
const auth0clientId = !process.env.REACT_APP_POINT_TO_STAGING
  ? 'xWQTVpTrHQrJjz8jkQaqSx2mEndtyPq0'
  : 'ZS7t7RaQdir3v86j6q3OQZGMheFkXQmd';

export class Auth0ApiClient {
  static triggerPasswordResetEmail(email: string): Promise<string> {
    return fetch(`https://${auth0domain}/dbconnections/change_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: auth0clientId,
        connection: 'Username-Password-Authentication',
        email,
      }),
    }).then((res) => res.text());
  }
}

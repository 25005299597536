import { Button, styled, Typography } from '@mui/material';
import localize from '../../../localize';
import sally3 from '../../../assets/landing/salyThird.png';
import doodle7 from '../../../assets/landing/doodle7.png';
import doodle5 from '../../../assets/landing/doodle5.png';

import { useState } from 'react';
import { ContactModal } from './ContactModal';
import ContactModalImg from '../../../assets/landing/contact-matte.svg';

const KnowMoreSectionContainer = styled('section')(({ theme }) => ({
  backgroundColor: '#AFABEF',
  position: 'relative',
  borderTopRightRadius: '100px',
  borderTopLeftRadius: '100px',
  padding: '3% 0',
  color: '#fff',
  [theme.breakpoints.down('md')]: {
    borderTopRightRadius: '60px',
    borderTopLeftRadius: '60px',
    padding: '4% 0 90px 0',
  },
  [theme.breakpoints.down('sm')]: {
    borderTopRightRadius: '40px',
    borderTopLeftRadius: '40px',
  },
}));

const KnowMoreSectionInner = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '0 20px',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  minHeight: '407px',
  width: '100%',
  maxWidth: '1280px',
  minWidth: '320px',
  margin: '0 auto',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    gap: '32px',
    alignItems: 'center',
    padding: '0 30px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },

  '& h2': {
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '24px',
  },

  '& p': {
    fontSize: '18px',
    lineHeight: '24px',
    marginBottom: '14px',
    fontFamily: '"Montserrat", sans-serif',
  },
}));

const KnowMoreSectionTextWithCTA = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '600px',
  gap: '40px',

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const KnowMoreSectionImg = styled('img')(({ theme }) => ({
  display: 'block',
  width: '465px',
  maxWidth: '100%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    maxWidth: '400px',
  },
}));

const KnowMoreSectionDoodle7 = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: '51px',
  height: 'auto',
  top: '10%',
  right: '15%',
}));

const KnowMoreSectionDoodle5 = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: '112px',
  height: 'auto',
  bottom: '-4%',
  right: '40%',
  [theme.breakpoints.down('md')]: {
    bottom: '0',
    left: '10%',
  },
}));

const KnowMoreButton = styled(Button)(({ theme }) => ({
  color: '#0c0c0c',
  backgroundColor: '#fff',
  boxShadow: 'none',
  alignSelf: 'flex-start',
  [theme.breakpoints.down('md')]: {
    alignSelf: 'start',
    width: '50%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const KnowMoreSection = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <KnowMoreSectionContainer>
      <KnowMoreSectionDoodle7 src={doodle7} />
      <KnowMoreSectionDoodle5 src={doodle5} />
      <KnowMoreSectionInner>
        <KnowMoreSectionTextWithCTA>
          <Typography variant='h2'>
            {localize.landing.knowMoreSection.title}
          </Typography>
          <Typography variant='body1' component='p'>
            {localize.landing.knowMoreSection.description}
          </Typography>
          <KnowMoreButton variant='rounded' onClick={() => setOpenModal(true)}>
            {localize.landing.knowMoreSection.CTAButton}
          </KnowMoreButton>
          <ContactModal
            open={openModal}
            handleClose={() => setOpenModal(false)}
            handleSubmit={() => setOpenModal(false)}
            title={localize.landing.modal.titleContactUs}
            imgPath={ContactModalImg}
          />
        </KnowMoreSectionTextWithCTA>

        <KnowMoreSectionImg src={sally3} />
      </KnowMoreSectionInner>
    </KnowMoreSectionContainer>
  );
};

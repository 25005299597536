import { FC, ReactNode } from 'react';
import { Stack, Typography, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 64,
  height: 27,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(30px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#dfddf9',
        boxShadow:
          '0px 2px 4px 0px #5A53BE3D inset, 0px -2px 4px 0px #F7F6FF inset',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#7B75CB',
    width: 25,
    height: 25,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#dfddf9',
    borderRadius: 20 / 2,
    boxShadow:
      '0px 2px 4px 0px #5A53BE3D inset, 0px -2px 4px 0px #F7F6FF inset',
  },
}));

type CustomSwitchProps = {
  checkedOption: ReactNode;
  option: ReactNode;
  onChange: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;
  firstOptionWidth?: number;
};

const CustomSwitch: FC<CustomSwitchProps> = ({
  checkedOption,
  option,
  onChange,
  checked,
  firstOptionWidth = 60,
  disabled = false,
}) => {
  return (
    <Stack
      width='100%'
      direction='row'
      spacing={1}
      sx={{ alignItems: 'center' }}
    >
      <Typography
        variant={checked ? 'subtitle1' : 'h6'}
        color={checked ? '#000000' : '#7B75CB'}
        width={firstOptionWidth}
      >
        {option}
      </Typography>
      <MaterialUISwitch
        data-cy='custom-switch'
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
      />
      <Typography
        variant={checked ? 'h6' : 'subtitle1'}
        color={checked ? '#7B75CB' : '#000000'}
      >
        {checkedOption}
      </Typography>
    </Stack>
  );
};

export default CustomSwitch;

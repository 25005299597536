import { FC } from 'react';
import { Order } from '../../api/OrderApiClient';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  Link,
} from '@mui/material';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import { NavLink } from 'react-router-dom';
import localize from '../../localize';
import IconWithInfo from '../../components/IconWithInfo';

type FilesCardProps = {
  order: Order;
};

export const FilesCard: FC<FilesCardProps> = ({ order }) => {
  return (
    <Card>
      <CardHeader
        title={
          <>
            {localize.orders.show.filesCard.title}
            <IconWithInfo text={localize.orders.show.filesCard.subtitle} />
          </>
        }
        action={
          order.editable_by_current_user && (
            <NavLink
              to={`/cp/orders/${order.id}/edit/files`}
              style={{
                display: 'block',
                color: '#5B4D8F',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '17.07px',
                fontFamily: 'Montserrat, sans-serif',
              }}
            >
              {localize.general.edit}
            </NavLink>
          )
        }
      />
      <Divider sx={{ borderColor: '#DFDDF9' }} />
      <CardContent>
        <List>
          {order.files?.length ? (
            order.files.map((el: any) => (
              <ListItem key={el.url} disablePadding>
                <Link href={el.url} target='_blank'>
                  {el.name}
                </Link>
              </ListItem>
            ))
          ) : (
            <EmptyContent
              title=' '
              subtitle={localize.orders.show.filesCard.noFiles}
              withImage='file'
            />
          )}
        </List>
      </CardContent>
    </Card>
  );
};

export default FilesCard;

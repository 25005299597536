import { FC } from 'react';
import { Order } from '../../api/OrderApiClient';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  Link,
  Divider,
  ListItemText,
} from '@mui/material';
import IconWithInfo from '../../components/IconWithInfo';
import FilesCard from './FilesCard';
import localize from '../../localize';

type TotalSumCardProps = {
  order: Order;
};

export const TotalSumCard: FC<TotalSumCardProps> = ({ order }) => {
  return (
    <Box>
      <Card
        sx={{ '& .MuiCardContent-root:last-child': { paddingBottom: '8px' } }}
      >
        <CardHeader
          title={localize.orders.show.totalSumCard.title}
          subheader={localize.orders.show.totalSumCard.subtitle}
        />
        <Divider sx={{ borderColor: '#DFDDF9' }} />
        <CardContent>
          <List sx={{ color: '#464646' }}>
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={{ variant: 'body1' }}
                primary={
                  <>
                    {localize.orders.show.totalSumCard.mainSum}
                    <IconWithInfo
                      text={localize.orders.show.totalSumCard.mainSumText}
                    />
                  </>
                }
              />
              {order.fixed_recommended_sum}
            </ListItem>
            {order.promotion_id ? (
              <ListItem disablePadding>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body1' }}
                  primary={localize.orders.show.totalSumCard.promotion}
                />
                {order.promotion_discount_value}
              </ListItem>
            ) : null}
            {order.fixed_customer_discount ? (
              <ListItem disablePadding>
                <ListItemText
                  primaryTypographyProps={{ variant: 'body1' }}
                  primary={localize.orders.show.totalSumCard.customerDiscount}
                />
                {order.customer_discount_value}
              </ListItem>
            ) : null}
            <Divider sx={{ borderColor: '#DFDDF9' }} />
            {order.fixed_client_discount ? (
              <>
                <ListItem disablePadding>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body1' }}
                    primary={
                      <>
                        {localize.orders.show.totalSumCard.sumForCustomer}
                        <IconWithInfo
                          text={
                            localize.orders.show.totalSumCard.sumForCustomerText
                          }
                        />
                      </>
                    }
                  />
                  {order.fixed_recommended_sum_with_discount}
                </ListItem>
                <br />
                <ListItem disablePadding>
                  <ListItemText
                    primaryTypographyProps={{ variant: 'h5' }}
                    primary={`${localize.orders.show.totalSumCard.dropDiscount} ${order.fixed_client_discount}%`}
                  />
                </ListItem>
              </>
            ) : null}
            <ListItem disablePadding>
              <ListItemText
                primaryTypographyProps={{ variant: 'body1' }}
                primary={
                  <>
                    {localize.orders.show.totalSumCard.sum}
                    <IconWithInfo
                      text={
                        order.user_is_client
                          ? localize.orders.show.totalSumCard.dropSumText
                          : order.promotion_id && order.fixed_customer_discount
                            ? localize.orders.show.totalSumCard.sumText
                            : localize.orders.show.totalSumCard.clientSumText
                      }
                    />
                  </>
                }
              />
              {order.sum}
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <FilesCard order={order} />
    </Box>
  );
};

export default TotalSumCard;

// TODO need to add tooltip text, status and statistics information

import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import {
  Alert,
  Link,
  Typography,
  Box,
  Avatar,
  List,
  ListItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { CommonContext } from '../../contexts/CommonContext';
import { ProductApiClient } from '../../api/ProductApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import { fieldsForTypes, toTemplateType } from '../../utils/template';
import { preparedColumns } from '../../utils/table';
import { BasicTable } from '../../components/BaseTable/BaseTable';
import Page, { PageAction } from '../../components/Page/Page';
import Loading from '../../components/Loading/Loading';
import { FileContainer } from '../../components/FileContainer';
import localize from '../../localize';
import EmptyContent from '../../components/EmptyContent/EmptyContent';
import MainCard from '../../components/MainCard/MainCard';

import { ReactComponent as InfoIconSvg } from '../../assets/info-circle.svg';
import { useMobile } from '../../hooks/useMobile';

const TextWithUnderline = styled(Typography)(() => ({
  width: '100%',
  borderBottom: '1px solid #DFDDF9',
  paddingBottom: '10px',
  marginBottom: '10px',
}));

const ListItemStyled = styled(ListItem)(() => ({
  padding: '0 0 4px 8px',
  alignItems: 'flex-start',
  flexWrap: 'wrap',
}));

export const ProductShowPage: FC = () => {
  const navigate = useNavigate();
  const baseSubFields = {
    product_modifications: [
      {
        field: 'available_count',
        align: 'left',
        headerName: localize.products.columns.availableCount,
        width: '80px',
      },
      {
        field: 'goToStock',
        headerName: localize.products.columns.actualCount,
        renderCell: (row: any) => {
          if (currentUser.user_permissions.product?.update?.length)
            return (
              <>
                {row?.actual_count}{' '}
                <Link
                  href={`/stocks/${row?.stock_id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/cp/stocks/edit/${row?.stock_id}`);
                  }}
                >
                  {localize.products.show.editStock}
                </Link>
              </>
            );

          return row?.count;
        },
      },
    ],
  };
  const { id } = useParams();
  const { currentProject, currentUser, templates } = useContext(CommonContext);
  const [item, setItem] = useState<AnyObject>({});
  const [requiredField, setRequiredField] = useState<AnyObject[]>([]);
  const [additionalField, setAdditionalField] = useState<AnyObject[]>([]);
  const [filesField, setFilesField] = useState<AnyObject[]>([]);
  const [columnsForSubItems, setColumnsForSubItems] = useState<AnyObject>(
    baseSubFields || {}
  );
  const [loading, setLoading] = useState(true);
  const mobile = useMobile();

  const avatar: string = useMemo(() => {
    if (filesField.length) {
      const notEmptyArray = filesField.find(
        (field) =>
          item.additional_fields[field.internal_name] &&
          !!item.additional_fields[field.internal_name].length
      );
      return notEmptyArray
        ? item.additional_fields[notEmptyArray.internal_name][0].url
        : '/logo.svg';
    } else {
      return '/logo.svg';
    }
  }, [filesField, item]);

  const pageActions = useMemo(() => {
    const editAction = {
      Component: (
        <NavLink
          to={`/cp/products/edit/${id}`}
          data-cy='link-to-products-edit'
          style={{
            textDecoration: 'none',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography color='#5B4D8F' variant='h5' p='10px'>
            {localize.general.edit}
          </Typography>
        </NavLink>
      ),
    };
    const res: PageAction[] = [];

    if (currentUser.user_permissions.product?.update?.length)
      res.push(editAction);

    return res;
  }, [item, id]);

  const preparePageData = async (id: string): Promise<void> => {
    const res = await ProductApiClient.getShow(currentProject.id, id);
    setItem({ ...res });
    const currentTemplate = templates.find(
      (el) => el.template_type === 'Product'
    );

    if (currentTemplate) {
      setRequiredField(
        currentTemplate.custom_fields.filter(
          (el: AnyObject) => el.required && el.field_type !== 'files'
        )
      );

      setAdditionalField(
        currentTemplate.custom_fields.filter(
          (el: AnyObject) => !el.required && el.field_type !== 'files'
        )
      );

      setFilesField(
        currentTemplate.custom_fields.filter(
          (el: AnyObject) => el.field_type === 'files'
        )
      );
    }

    const subItemColumns = { ...columnsForSubItems };
    subItemColumns['product_modifications'] = [
      ...(subItemColumns['product_modifications'] || []),
      ...preparedColumns(
        fieldsForTypes(templates, [toTemplateType('product_modifications')]) ||
          []
      ),
    ];

    setColumnsForSubItems(subItemColumns);
  };

  const getDataForField = (field: AnyObject) => {
    if (field.field_type === 'select') {
      return item[field.internal_name] ||
        item.additional_fields[field.internal_name]
        ? field.localized_field.ua.options[
            item[field.internal_name] ||
              item.additional_fields[field.internal_name]
          ]
        : null;
    }
    if (field.field_type === 'boolean') {
      return item[field.internal_name] ||
        item.additional_fields[field.internal_name]
        ? localize.general.true
        : localize.general.false;
    }
    return (
      item[field.internal_name] || item.additional_fields[field.internal_name]
    );
  };

  const ListItemInfo = ({
    element,
    width,
  }: {
    element: AnyObject;
    width: string;
  }) =>
    item[element.internal_name] ||
    item.additional_fields[element.internal_name] ? (
      <ListItemStyled key={element.label}>
        <Typography color='#0C0C0C' variant='h5' sx={{ width }}>
          {element.label}:
        </Typography>
        <Typography color='#464646' variant='body1' sx={{ flex: 1 }}>
          {getDataForField(element)}
        </Typography>
      </ListItemStyled>
    ) : null;

  useEffect(() => {
    if (!id || !templates) return;

    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  }, [id, templates, currentProject]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.products.show.title} actions={pageActions}>
      <Box width='100%'>
        {currentUser.user_permissions.template?.update?.length ? (
          <Alert
            severity='info'
            icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
          >
            {localize.products.show.infoText}
            <NavLink
              to='/cp/templates/edit/product'
              data-cy='link-to-template-edit-product'
              style={{
                color: '#0A7AFF',
                marginLeft: '5px',
                textDecoration: 'none',
              }}
            >
              {localize.products.show.linkInfoText}
            </NavLink>
          </Alert>
        ) : null}
        <Box
          display='grid'
          gridTemplateColumns={mobile ? '100%' : '367px 1fr 367px'}
          gap='20px'
          margin='20px 0'
        >
          <Box
            sx={{
              height: '100%',
              gridColumnStart: 1,
              gridColumnEnd: mobile ? 'auto' : 2,
            }}
          >
            <MainCard title={localize.products.show.mainFields} fullHeight>
              <Box display='flex'>
                <Avatar
                  alt='preview photo'
                  src={avatar}
                  sx={{ bgcolor: '#F0E7F7', width: '68px', height: '68px' }}
                  variant='rounded'
                />
                {requiredField.length && (
                  <List sx={{ padding: '0' }}>
                    {requiredField.map((el) => (
                      <ListItemInfo
                        key={`main_${el.label}`}
                        element={el}
                        width='80px'
                      />
                    ))}
                  </List>
                )}
              </Box>
              <TextWithUnderline
                color='#9B96EB'
                variant='h6'
                sx={{
                  marginTop: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {localize.products.show.filesFields}
                {/* {
                <Tooltip
                  title={localize.bookings.create.commentTooltip}
                  placement='top'
                >
                  <InfoIconSvg width='24px' height='24px' fill='#9B96EB' />
                </Tooltip>
              } */}
              </TextWithUnderline>
              {filesField.length && (
                <List sx={{ padding: '0' }}>
                  {filesField.some(
                    (field) =>
                      item.additional_fields[field.internal_name] &&
                      item.additional_fields[field.internal_name].length
                  ) ? (
                    filesField.map((el) =>
                      item.additional_fields[el.internal_name] &&
                      item.additional_fields[el.internal_name].length ? (
                        <ListItemStyled key={el.label}>
                          <Typography
                            color='#0C0C0C'
                            variant='h5'
                            sx={{
                              width: mobile ? '100%' : '100px',
                              marginRight: '10px',
                            }}
                          >
                            {el.label}:
                          </Typography>
                          <List sx={{ padding: '0 0 10px' }}>
                            {item.additional_fields[el.internal_name].map(
                              (item: AnyObject) => (
                                <ListItem
                                  key={item.name.slice(10)}
                                  sx={{ padding: '0 0 5px' }}
                                >
                                  <Link href={item.url} target='_blank'>
                                    {item.name.length > 25
                                      ? `...${item.name.slice(-24)}`
                                      : item.name}
                                  </Link>
                                </ListItem>
                              )
                            )}
                          </List>
                        </ListItemStyled>
                      ) : null
                    )
                  ) : (
                    <EmptyContent
                      title={localize.products.show.emptyFilesSection}
                      withImage='file'
                    />
                  )}
                </List>
              )}
            </MainCard>
          </Box>
          <Box
            sx={{
              height: '100%',
              gridColumnStart: mobile ? 1 : 2,
              gridColumnEnd: mobile ? 'auto' : 4,
            }}
          >
            <MainCard
              title={localize.products.show.additionalFields}
              fullHeight
            >
              {additionalField.length && (
                <List sx={{ padding: '0' }}>
                  {additionalField.map((el) => (
                    <ListItemInfo key={el.label} element={el} width='120px' />
                  ))}
                </List>
              )}
            </MainCard>
          </Box>
          <Box sx={{ gridColumnStart: 1, gridColumnEnd: mobile ? 'auto' : 4 }}>
            <MainCard title={localize.products.show.modifications}>
              <BasicTable
                key='table-product-modifications'
                rows={item['product_modifications']}
                expandColumns
                columns={columnsForSubItems['product_modifications']}
              />
            </MainCard>
          </Box>
          {/* <Box sx={{ height: '100%', gridColumnStart: 1, gridColumnEnd: 3 }}>
            <MainCard
              title={localize.products.show.comments}
              subtitle={localize.products.show.commentsText}
              tooltipText='some text'
            >
              <div></div>
            </MainCard>
          </Box>
          <Box sx={{ height: '100%', gridColumnStart: 3, gridColumnEnd: 4 }}>
            <MainCard
              title={localize.products.show.status}
              subtitle={localize.products.show.statusText}
              tooltipText='some text'
            >
              <div></div>
            </MainCard>
          </Box>
          <Box sx={{ height: '100%', gridColumnStart: 1, gridColumnEnd: 2 }}>
            <MainCard
              title={localize.products.show.statistics}
              subtitle={localize.products.show.statisticsText}
              tooltipText='some text'
              fullHeight
            >
              <div></div>
            </MainCard>
          </Box> */}
        </Box>
      </Box>
    </Page>
  );
};

export default ProductShowPage;

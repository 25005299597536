import { FC, ReactNode, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  ListItemProps,
  ListItemButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { useMobile } from '../../hooks/useMobile';
import { SoonText } from '../SoonText/SoonText';

export type SideBarListItemProps = {
  icon?: any;
  name: string;
  text: string;
  link?: string;
  commingSoon?: boolean;
  expandDirection?: 'down' | 'right';
};

export type SideBarListItemComponentProps = {
  item: SideBarListItemProps;
  innerItems?: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  >;
  selectedItem?: boolean;
  setSidebarOpen(o: boolean): void;
  className?: string;
  dataTest?: string;
};

interface ListItemComponentProps extends ListItemProps {}

const ListItemStyled = styled(ListItem)(() => ({
  minHeight: 48,
  justifyContent: 'initial',
  paddingRight: 2.5,
  position: 'relative',
  color: '#777777',
  borderRadius: '8px',
  cursor: 'pointer',

  '&.inner-item': {
    minHeight: 32,
    paddingTop: '4px',
    paddingBottom: '4px',
    color: '#464646',
  },
}));

const ListItemButtonStyled = styled(ListItemButton)(() => ({
  minHeight: 48,
  paddingRight: 2.5,
  position: 'relative',
  color: '#777777',
  borderRadius: '8px',
  cursor: 'pointer',

  '&.disabled': {
    pointerEvents: 'none',
    cursor: 'none',
  },

  '&:selected': {
    background: '#DFDDF9',
  },
  '&.inner-item': {
    minHeight: 32,
    paddingTop: '4px',
    paddingBottom: '4px',
    color: '#464646',
  },
}));

export const SideBarListItem: FC<SideBarListItemComponentProps> = ({
  item,
  innerItems,
  selectedItem,
  setSidebarOpen,
  className,
  dataTest,
}) => {
  const [open, setOpen] = useState(
    innerItems?.props.children.some((item: any) => {
      return item.props.selectedItem;
    }) || false
  );
  const mobile = useMobile();
  const closeIfMobile = (): void => {
    if (mobile) setSidebarOpen(false);
  };

  const ListItemComponent: FC<ListItemComponentProps> = useMemo(
    () =>
      item.expandDirection
        ? (ListItemStyled as FC<ListItemComponentProps>)
        : (ListItemButtonStyled as FC<ListItemComponentProps>),
    [item.expandDirection]
  );

  return (
    <>
      <ListItemComponent
        key={item.name}
        data-cy={dataTest}
        sx={{
          pl: item.icon ? 2.5 : 3.5,
          '&.Mui-selected': {
            background: '#DFDDF9',
          },
        }}
        className={`${item.commingSoon ? 'disabled' : ''} ${className ? className : ''}`}
        {...(item.link
          ? {
              component: NavLink,
              to: item.link,
            }
          : {})}
        onClick={(e: any): void => {
          item.expandDirection ? setOpen(!open) : closeIfMobile();
        }}
        selected={selectedItem || false}
        {...(item.expandDirection
          ? item.expandDirection === 'down'
            ? {
                secondaryAction: (
                  <IconButton edge='end'>
                    {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                ),
              }
            : {
                secondaryAction: (
                  <IconButton edge='end'>
                    {open ? (
                      <ArrowBackIosNewIcon sx={{ width: 16, height: 16 }} />
                    ) : (
                      <ArrowForwardIosIcon sx={{ width: 16, height: 16 }} />
                    )}
                  </IconButton>
                ),
              }
          : {})}
      >
        {item.icon && (
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 1,
              justifyContent: 'center',
              color: '',
            }}
          >
            {item.icon}
          </ListItemIcon>
        )}
        <ListItemText
          primary={item.text}
          primaryTypographyProps={{
            variant: className ? (mobile ? 'body2' : 'body1') : 'h5',
            color: item.name === 'projects_delete' ? '#FD3470' : 'inherit',
          }}
        />
        {item.commingSoon && <SoonText />}
        {item.expandDirection === 'right' && open && innerItems}
      </ListItemComponent>
      {item.expandDirection === 'down' && open && innerItems}
    </>
  );
};

export default SideBarListItem;

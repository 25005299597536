import { Button, Link, ListItem, styled, Typography } from '@mui/material';
import { ReactComponent as LogoTextWhiteSvg } from '../../../assets/logoTextWhite.svg';
import { ReactComponent as LogoIconSvg } from '../../../assets/logoIcon.svg';
import { ReactComponent as Copyright } from '../../../assets/landing/copyright.svg';
import localize from '../../../localize';
import { navLinks } from './Header';
import { useNavigate } from 'react-router-dom';
import { useContext, useRef } from 'react';
import { create } from '../api/RequestForm';
import { NotificationContext } from '../../../contexts/NotificationContext';

const FooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: '#5B4D8F',
  color: '#F3F2F7',
  padding: '3% 0',
  [theme.breakpoints.down('md')]: {
    padding: '32px 0',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '28px 0',
  },
}));

const FooterInner = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '0 20px',
  justifyContent: 'normal',
  alignItems: 'center',
  width: '100%',
  maxWidth: '1280px',
  minWidth: '320px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}));

const Logo = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  paddingRight: '20px',
  marginBottom: '32px',
  cursor: 'pointer',

  '&>svg+svg': {
    marginLeft: '15px',
    width: '72px',
    height: 'auto',
  },
}));

const FooterContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  justifyContent: 'normal',
  width: '100%',
}));

const FooterTopContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: '30px',
  },
}));

const FooterBottomContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  marginTop: '20px',
}));

const CopyrightLine = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: '4px',
  [theme.breakpoints.down('sm')]: {
    'p > span': {
      display: 'none',
    },
  },
}));

const EmailBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '16px',
  width: '462px',
  maxWidth: '100%',
  flexShrink: 0,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const FooterBoldText = styled('p')(() => ({
  fontWeight: 700,
  fontSize: '16px',
  margin: 0,
  fontFamily: '"Montserrat", sans-serif',
}));

const FooterLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  fontSize: '14px',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('lg')]: {
    justifyContent: 'center',
    gap: '20px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    width: '100%',
    maxWidth: '100%',
  },
}));

const FooterLinksInner = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  alignItems: 'flex-start',
  width: '100%',
  maxWidth: '682px',
  [theme.breakpoints.down('lg')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '30px',
    width: '100%',
    maxWidth: '100%',
  },

  '& a': {
    color: '#F3F2F7',
    textDecoration: 'none',
  },
}));

const EmailForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'normal',
  gap: '8px',

  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    gap: '10px',
    width: '100%',
    maxWidth: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    input: {
      width: '100%',
      maxWidth: '100%',
    },
    button: {
      width: '100%',
      flexGrow: 1,
      justifySelf: 'flex-start',
    },
  },
}));

const FooterInput = styled('input')(() => ({
  width: '100%',
  maxWidth: '300px',
  height: 'auto',
  maxHeight: '48px',
  backgroundColor: '#F3F2F7',
  borderRadius: '30px',
  border: '1px solid #F3F2F7',
  padding: '16px',
  outline: 'none',
  fontFamily: '"Montserrat", sans-serif',
}));

const FooterNavLinks = styled('ul')(() => ({
  display: 'flex',
  padding: 0,
  margin: 0,
  flexDirection: 'column',
  listStyle: 'none',
  color: '#F3F2F7',
  gap: '11px',
  '& a': {
    color: '#F3F2F7',
    textDecoration: 'none',
  },
}));

const FooterPolicyLinks = styled('ul')(() => ({
  display: 'flex',
  padding: 0,
  margin: 0,
  flexDirection: 'column',
  listStyle: 'none',
  color: '#F3F2F7',
  gap: '11px',
}));

const FooterContactLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  textAlign: 'right',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    textAlign: 'left',
  },
}));

const policysNavLinks = [
  { name: localize.landing.footer.privacyPolicy, path: '/privacy-policy' },
  { name: localize.landing.footer.termsOfService, path: '/terms-of-service' },
];

export const Footer = () => {
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const { setNotification } = useContext(NotificationContext);

  const onEmailFormSubmit = () => {
    if (emailRef.current?.value)
      create({
        email: emailRef.current?.value,
        comment: 'підписка на лендінгу',
      }).then(() => {
        setNotification({
          severity: 'success',
          message: localize.landing.successSentMessage,
        });
        if (emailRef.current?.value) emailRef.current.value = '';
      });
  };
  return (
    <FooterContainer>
      <FooterInner>
        <FooterContent>
          <FooterTopContent>
            <EmailBox>
              <Logo onClick={() => navigate('/')}>
                <LogoIconSvg />
                <LogoTextWhiteSvg />
              </Logo>
              <FooterBoldText>
                {localize.landing.footer.updatesPhrase}
              </FooterBoldText>
              <EmailForm>
                <FooterInput
                  type='email'
                  placeholder='example@gmail.com'
                  ref={emailRef}
                />
                <Button
                  variant='roundedSecondary'
                  sx={{ height: '48px', flexShrink: 0, padding: '14px 32px' }}
                  onClick={onEmailFormSubmit}
                >
                  {localize.landing.footer.submitWord}
                </Button>
              </EmailForm>
            </EmailBox>
            <FooterLinks>
              <FooterLinksInner>
                <FooterNavLinks>
                  {navLinks.map(({ name, path }, index) => (
                    <ListItem sx={{ padding: '0' }} key={name + index}>
                      <Link href={path}>{name}</Link>
                    </ListItem>
                  ))}
                </FooterNavLinks>
                <FooterPolicyLinks>
                  {policysNavLinks.map(({ name, path }, index) => (
                    <ListItem sx={{ padding: '0' }} key={name + index}>
                      <Link href={path}>{name}</Link>
                    </ListItem>
                  ))}
                </FooterPolicyLinks>
                <FooterContactLinks>
                  <FooterBoldText>Contacts</FooterBoldText>
                  <Link
                    sx={{ marginBottom: '14px' }}
                    href={localize.landing.footer.phoneNumber.link}
                  >
                    {localize.landing.footer.phoneNumber.text}
                  </Link>
                  <Link href={localize.landing.footer.email.link}>
                    {localize.landing.footer.email.text}
                  </Link>
                </FooterContactLinks>
              </FooterLinksInner>
            </FooterLinks>
          </FooterTopContent>
          <FooterBottomContent>
            <CopyrightLine>
              <Copyright />
              <Typography component='p'>
                {new Date().getFullYear()}{' '}
                <Typography component='span'>Factory Wise</Typography>
              </Typography>
            </CopyrightLine>
          </FooterBottomContent>
        </FooterContent>
      </FooterInner>
    </FooterContainer>
  );
};

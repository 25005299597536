import { Button, styled } from '@mui/material';
import React, { useState } from 'react';
import { MainText, MainTitle } from '../../Price';
import phoneImg from '../../../../assets/landing/comment-matte.svg';
import localize from '../../../../localize';
import { ContactModal } from '../ContactModal';
import { useMobile } from '../../../../hooks/useMobile';
export const ConsultContainer = styled('section')(() => ({
  padding: '10px 0 90px 0',
  position: 'relative',
  overflowX: 'hidden',
}));

export const PhoneImg = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  width: '400px',
  height: 'auto',
  [theme.breakpoints.down('xl')]: {
    width: '261px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '146px',
  },
}));

const ConsultContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'space-evenly',
  margin: '0 auto',
  alignItems: 'center',
  padding: '0 20px',
  width: '1280px',
  maxWidth: '100%',
  minWidth: '320px',
  marginRight: 'auto',
  [theme.breakpoints.down('md')]: {
    gap: '20px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 14px',
  },
}));

const RoundedBg = styled('div')(({ theme }) => ({
  position: 'relative',
  height: '325px',
  minWidth: '500px',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    height: '306px',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '100%',
  },

  '&::after': {
    content: "' '",
    position: 'absolute',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    height: '100%',
    width: '100vw',
    backgroundColor: '#AFABEF',
    borderTopLeftRadius: '24px',
    borderBottomLeftRadius: '24px',
    zIndex: '-1',
  },
}));

const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  paddingLeft: '150px',
  maxWidth: '625px',
  gap: '24px',
  h2: {
    textAlign: 'left',
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '50px',
    maxWidth: '500px',
    gap: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '17px',
  },
}));

const ConsultButton = styled(Button)(({ theme }) => ({
  color: '#0c0c0c',
  backgroundColor: '#fff',
  boxShadow: 'none',
  alignSelf: 'flex-start',
  marginLeft: '20px',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const ConsultSection = () => {
  const [openModal, setOpenModal] = useState(false);
  const mobile = useMobile();
  return (
    <ConsultContainer>
      <ConsultContainerInner>
        <PhoneImg src={phoneImg} />
        <RoundedBg>
          <TextContainer>
            <MainTitle variant='h2'>
              {localize.landing.pricingPage.consultSectionTitle}
            </MainTitle>
            <MainText
              sx={
                mobile
                  ? { textAlign: 'left', marginBottom: '0' }
                  : { marginBottom: '0' }
              }
              variant='body1'
            >
              {localize.landing.pricingPage.consultSectionDescription}
            </MainText>
            <ConsultButton variant='rounded' onClick={() => setOpenModal(true)}>
              {localize.landing.pricingPage.consultSectionCTAButton}
            </ConsultButton>
            <ContactModal
              open={openModal}
              handleClose={() => setOpenModal(false)}
              handleSubmit={() => setOpenModal(false)}
              title={localize.landing.modal.titleContactUs}
              imgPath={phoneImg}
            />
          </TextContainer>
        </RoundedBg>
      </ConsultContainerInner>
    </ConsultContainer>
  );
};

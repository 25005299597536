import { TableCell, Typography, styled } from '@mui/material';
import { Period } from './Tarrifs';
import { RenderedPlans, Costs } from './TarrifsTable';
import { useMobile } from '../../../../hooks/useMobile';

const StyledCell = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '14px',
  padding: '16px',
  fontWeight: 700,
  color: '#7B75CB',
}));

const CustomChip = styled('div')(({ theme }) => ({
  minWidth: '78px',
  width: 'fit-content',
  margin: 'auto',
  color: '#0c0c0c',
  fontSize: '16px',
  border: '1px solid #7B75CB',
  borderRadius: '16px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    marginRight: '0',
  },

  '.green-text': {
    fontWeight: 700,
    color: '#53C17F',
    fontSize: '16px',
  },
  '.strikethrough-text': {
    textDecoration: 'line-through',
    fontSize: '16px',
  },
}));

export const PriceHeaderCells = ({
  plan,
  period,
  costs,
}: {
  plan: RenderedPlans;
  period: Period;
  costs: Costs;
}) => {
  const mobile = useMobile();

  switch (plan) {
    case 'all':
      return (
        <>
          <TableCell
            align='center'
            sx={{
              padding: '16px',
            }}
          >
            <CustomChip
              sx={{
                padding: '8px 10px',
              }}
            >
              <Typography component='span' className='normal-text'>
                {costs.month.basic.currentPrice}
              </Typography>
            </CustomChip>
          </TableCell>
          <TableCell
            align='center'
            sx={{
              padding: '16px',
            }}
          >
            <CustomChip>
              <Typography component='span' className='strikethrough-text'>
                {costs[period].standard.oldPrice}
              </Typography>
              <Typography component='span' className='green-text'>
                {costs[period].standard.currentPrice}
              </Typography>
            </CustomChip>
          </TableCell>
          <TableCell
            align='center'
            sx={{
              padding: '16px',
            }}
          >
            <CustomChip>
              <Typography component='span' className='strikethrough-text'>
                {costs[period].business.oldPrice}
              </Typography>
              <Typography component='span' className='green-text'>
                {costs[period].business.currentPrice}
              </Typography>
            </CustomChip>
          </TableCell>
          <StyledCell align='center'>
            {costs.month.custom.currentPrice}
          </StyledCell>
        </>
      );
    case 'basic':
      return (
        <TableCell
          align={mobile ? 'right' : 'center'}
          sx={{
            padding: '16px',
          }}
        >
          <CustomChip
            sx={{
              padding: '8px 10px',
            }}
          >
            <Typography component='span' className='normal-text'>
              {costs.month.basic.currentPrice}
            </Typography>
          </CustomChip>
        </TableCell>
      );
    case 'standard':
      return (
        <TableCell
          align={mobile ? 'right' : 'center'}
          sx={{
            padding: '16px',
          }}
        >
          <CustomChip>
            <Typography component='span' className='strikethrough-text'>
              {costs[period].standard.oldPrice}
            </Typography>
            <Typography component='span' className='green-text'>
              {costs[period].standard.currentPrice}
            </Typography>
          </CustomChip>
        </TableCell>
      );
    case 'business':
      return (
        <TableCell
          align={mobile ? 'right' : 'center'}
          sx={{
            padding: '16px',
          }}
        >
          <CustomChip>
            <Typography component='span' className='strikethrough-text'>
              {costs[period].business.oldPrice}
            </Typography>
            <Typography component='span' className='green-text'>
              {costs[period].business.currentPrice}
            </Typography>
          </CustomChip>
        </TableCell>
      );
    case 'custom':
      return (
        <StyledCell align={mobile ? 'right' : 'center'}>
          {costs.month.custom.currentPrice}
        </StyledCell>
      );
    default:
      return <></>;
  }
};

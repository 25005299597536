import React, { FC, Fragment, useMemo } from 'react';
import {
  Image,
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { Order } from '../../api/OrderApiClient';
import { formatDate } from '../../utils/time';

Font.register({
  family: 'Montserrat',
  fonts: [
    { src: '/fonts/Montserrat-Regular.ttf', fontWeight: 400 },
    {
      src: '/fonts/Montserrat-Medium.ttf',
      fontWeight: 500,
    },
    {
      src: '/fonts/Montserrat-SemiBold.ttf',
      fontWeight: 600,
    },
  ],
  format: 'truetype',
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: 'column',
    color: '#0C0C0C',
    position: 'relative',
  },
  logoContainer: {
    position: 'absolute',
    top: 10,
    right: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoImage: {
    width: 30,
    marginRight: 2,
  },
  logoText1: {
    fontSize: 10,
    fontWeight: 600,
    color: '#7B75CB',
    lineHeight: 1,
  },
  logoText2: {
    fontSize: 10,
    fontWeight: 400,
    color: '#9B96EB',
    lineHeight: 1,
  },
  section: {},
  column: { flexDirection: 'column' },
  row: { flexDirection: 'row' },
  block: { minWidth: '50%', padding: '0 10px' },
  pt: { paddingTop: '20px' },
  text: { fontSize: 11 },
  signLine: { paddingTop: '10px' },
  emptyLine: { minHeight: '30px' },
  spaceBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: { flexDirection: 'row', marginTop: 24 },
  logo: { width: 90 },
  reportTitle: { fontSize: 14, textAlign: 'center' },
  reportSubtitle: { fontSize: 12, textAlign: 'center' },
  addressTitle: { fontSize: 12, fontWeight: 600 },
  addressText: { fontSize: 10, fontWeight: 400 },
  invoice: { fontWeight: 600, fontSize: 20 },
  invoiceNumber: { fontSize: 11, fontWeight: 600 },
  address: { fontSize: 10 },
  dividerRight: {
    borderRight: '1px solid #DFDDF9',
  },
  theader: {
    fontSize: 12,
    fontWeight: 500,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    backgroundColor: '#DFDDF9',
    borderColor: '#464646',
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  theader2: { flex: 2, borderBottomWidth: 1 },
  theader3: { maxWidth: '30px', borderBottomWidth: 1 },
  tbody: {
    fontSize: 10,
    fontWeight: 500,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    borderColor: '#464646',
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  total: {
    fontSize: 12,
    fontWeight: 600,
  },
  totalSection: {
    fontSize: 10,
    fontWeight: 500,
    margin: '10px 10px 10px auto',
  },
  tbody2: { flex: 2, borderRightWidth: 1 },
  tbody3: { maxWidth: '30px', borderLeftWidth: 1 },
});

type InvoiceProps = {
  order: Order;
  fromWho?: string;
  toWho?: string;
  project?: {
    legal_name: string;
    edropoy_code: string;
    iban: string;
  };
};

export const Invoice: FC<InvoiceProps> = ({
  order,
  fromWho,
  toWho,
  project,
}) => {
  const data = useMemo(
    () => ({
      id: order.id,
      date: formatDate(new Date(Date.now()).toString()),
      items: order.order_items.map((oi) => ({
        id: oi.id,
        desc: oi.title,
        qty: oi.count,
        price: oi.fixed_price,
      })),
      discount:
        toWho === 'dropshipper'
          ? (order.fixed_recommended_sum - order.sum).toFixed(2)
          : (
              order.fixed_recommended_sum -
              order.fixed_recommended_sum_with_discount
            ).toFixed(2),
      total: parseFloat(
        `${toWho === 'dropshipper' ? order.sum : order.fixed_recommended_sum_with_discount}`
      ).toFixed(2),
      from:
        fromWho === 'dropshipper'
          ? {
              name: order.user?.drop_legal_name || order.user_name,
              edropoy_code: order.user?.edropoy_code || '',
              iban: order.user?.iban || '',
            }
          : {
              name: project?.legal_name || '',
              edropoy_code: project?.edropoy_code || '',
              iban: project?.iban || '',
            },
      to:
        toWho === 'dropshipper'
          ? {
              name: order.user?.drop_legal_name || order.user_name,
              edropoy_code: order.user?.edropoy_code || '',
              iban: order.user?.iban || '',
            }
          : {
              name: order.customer_name,
              phone: order.customer_phone_number,
              email: order.customer_email,
            },
    }),
    [order, fromWho, toWho]
  );

  const EmptyLine = () => <View style={styles.emptyLine}></View>;

  const Logo = () => (
    <View style={styles.logoContainer}>
      <Image src='/PDFLogo.png' style={styles.logoImage} />
      <View>
        <Text style={styles.logoText1}>FACTORY</Text>
        <Text style={styles.logoText2}>WISE</Text>
      </View>
    </View>
  );

  const InvoiceTitle = () => (
    <View style={styles.section}>
      <Text style={styles.reportTitle}>Накладна</Text>
      <Text style={styles.reportSubtitle}>
        до замовлення №{data.id} від {data.date}
      </Text>
    </View>
  );

  const Address = () => (
    <View style={[styles.section, styles.spaceBetween]}>
      <View style={[styles.block, styles.dividerRight]}>
        <Text style={styles.addressTitle}>Постачальник:</Text>
        <Text style={styles.addressText}>{data.from.name}</Text>
        {data.from.edropoy_code ? (
          <Text style={styles.addressText}>
            ЄДРПОУ: {data.from.edropoy_code}
          </Text>
        ) : (
          <EmptyLine />
        )}
        {data.from.iban ? (
          <Text style={styles.addressText}>ІБАН: {data.from.iban}</Text>
        ) : (
          <EmptyLine />
        )}
      </View>
      {toWho === 'dropshipper' ? (
        <View style={styles.block}>
          <Text style={styles.addressTitle}>Покупець:</Text>
          <Text style={styles.addressText}>{data.to.name}</Text>
          {data.from.edropoy_code ? (
            <Text style={styles.addressText}>
              ЄДРПОУ: {data.from.edropoy_code}
            </Text>
          ) : (
            <EmptyLine />
          )}
          {data.from.iban ? (
            <Text style={styles.addressText}>ІБАН: {data.from.iban}</Text>
          ) : (
            <EmptyLine />
          )}
        </View>
      ) : Object.values(data.to).filter((item) => !!item).length ? (
        <View style={styles.block}>
          <Text style={styles.addressTitle}>Покупець:</Text>
          {data.to.name ? (
            <Text style={styles.addressText}>{data.to.name}</Text>
          ) : (
            <EmptyLine />
          )}
          {data.to.email ? (
            <Text style={styles.addressText}>Емейл: {data.to.email}</Text>
          ) : (
            <EmptyLine />
          )}
          {data.to.phone ? (
            <Text style={styles.addressText}>Телефон: {data.to.phone}</Text>
          ) : (
            <EmptyLine />
          )}
        </View>
      ) : null}
    </View>
  );

  const TableHead = () => (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        marginTop: 30,
        color: '#0C0C0C',
      }}
    >
      <View style={[styles.theader, styles.theader3]}>
        <Text>№</Text>
      </View>
      <View style={[styles.theader, styles.theader2]}>
        <Text>Найменування</Text>
      </View>
      <View style={styles.theader}>
        <Text>Кількість</Text>
      </View>
      <View style={styles.theader}>
        <Text>Ціна</Text>
      </View>
      <View style={styles.theader}>
        <Text>Сума</Text>
      </View>
    </View>
  );

  const TableBody = () => (
    <>
      {data.items.map((item, index) => (
        <Fragment key={item.id}>
          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={[styles.tbody, styles.tbody3]}>
              <Text>{index + 1}</Text>
            </View>
            <View style={[styles.tbody, styles.tbody2]}>
              <Text>{item.desc}</Text>
            </View>
            <View style={styles.tbody}>
              <Text>{item.qty}</Text>
            </View>
            <View style={styles.tbody}>
              <Text>{item.price} </Text>
            </View>
            <View style={styles.tbody}>
              <Text>{(item.price * item.qty).toFixed(2)}</Text>
            </View>
          </View>
        </Fragment>
      ))}
    </>
  );

  const TotalInfo = () => (
    <View style={[styles.section, styles.totalSection]}>
      <Text style={styles.text}>Всього {data.items.length} найменувань</Text>
      <Text style={styles.text}>
        Загалом {Number(data.total) + Number(data.discount)} грн.
      </Text>
      <Text style={styles.text}>Знижка {data.discount} грн.</Text>
      <Text style={styles.total}>Сума до сплати {data.total} грн.</Text>
    </View>
  );

  const SigningSection = () => (
    <View style={[styles.section, styles.spaceBetween]}>
      <View style={styles.block}>
        <Text style={styles.addressTitle}>Постачальник:</Text>
        <Text style={styles.signLine}>
          ________________________________________
        </Text>
      </View>
      <View style={styles.block}>
        <Text style={styles.addressTitle}>Покупець:</Text>
        <Text style={styles.signLine}>
          ________________________________________
        </Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Logo />
        <InvoiceTitle />
        <EmptyLine />
        <Address />
        <TableHead />
        <TableBody />
        <EmptyLine />
        <TotalInfo />
        <EmptyLine />
        <SigningSection />
      </Page>
    </Document>
  );
};

export default Invoice;

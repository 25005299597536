import { FC, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import localize from '../../localize';

import { ReactComponent as UploadItemIconSvg } from '../../assets/document-copy.svg';
import { ReactComponent as CommentIconSvg } from '../../assets/comment.svg';

type EmptyContentProps = {
  fullPage?: boolean;
  title?: string;
  subtitle?: string;
  withImage?: 'box' | 'comment' | 'file';
};

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  justifySelf: 'center',
  flexDirection: 'column',
  minWidth: '100%',
  minHeight: '80%',
}));

const FullPageContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: '100vh',
  minWidth: '100vw',
}));

export const EmptyContent: FC<EmptyContentProps> = ({
  title,
  subtitle,
  withImage,
  fullPage = false,
}) => {
  const Container = useMemo(
    () => (fullPage ? FullPageContainer : InnerContainer),
    [fullPage]
  );

  const Image: FC<{ image: string }> = ({ image }) => {
    switch (image) {
      case 'box':
        return (
          <Box
            width='80px'
            height='80px'
            mb='20px'
            sx={{
              '&>img': {
                display: 'block',
                objectPosition: 'center',
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              },
            }}
          >
            <img src='/empty-box.png' alt='empty box' />
          </Box>
        );
      case 'file':
        return (
          <UploadItemIconSvg
            fill='#777777'
            style={{ marginBottom: '20px', width: '30px', height: '30px' }}
          />
        );
      case 'comment':
        return <CommentIconSvg style={{ marginBottom: '20px' }} />;
      default:
        return <></>;
    }
  };

  return (
    <Container>
      {withImage && <Image image={withImage} />}
      <Typography variant='h5' color='#464646'>
        {title || localize.general.emptyPage}
      </Typography>
      {subtitle && <Typography variant='h4'>{subtitle}</Typography>}
    </Container>
  );
};

export default EmptyContent;

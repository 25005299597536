import { styled } from '@mui/material';
import { OnboardingProvider } from '../../contexts/OnboardingContext';
import { StepManager } from './StepManager';

export const OnboaringPageContainer = styled('section')(() => ({
  display: 'flex',
  height: '100vh',
  minHeight: '600px',
  minWidth: '700px',
  position: 'relative',
  fontFamily: 'Montserrat, sans-serif',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));
export const MainTitle = styled('h2')(() => ({
  fontWeight: 500,
  fontSize: '18px',
  textAlign: 'center',
  margin: 0,
  fontFamily: '"Montserrat", sans-serif',
}));

export const MainDescription = styled('p')(() => ({
  fontWeight: 400,
  fontSize: '12px',
  textAlign: 'center',
  marginBottom: '40px',
  fontFamily: '"Montserrat", sans-serif',
}));

export const ButtonsBox = styled('div')(() => ({
  display: 'flex',
  gap: '16px',
  justifyContent: 'center',
}));

export const OnboardingPage = () => {
  return (
    <OnboardingProvider>
      <StepManager />
    </OnboardingProvider>
  );
};

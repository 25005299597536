import {
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useState } from 'react';
import { MainHeadingText } from './Notifications';

import { ReactComponent as ShareIcon } from '../../assets/export.svg';
import { ReactComponent as CopyIcon } from '../../assets/document-copy.svg';
import { NotificationContext } from '../../contexts/NotificationContext';
import localize from '../../localize';
import { StyledInput } from '../../components/StyledInput';
import { CommonContext } from '../../contexts/CommonContext';
import { useAsyncError } from '../../hooks/useAsyncError';
import { UserApiClient } from '../../api/UserApiClient';
import { Check, Close } from '@mui/icons-material';

const MainWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  paddingBottom: '53px',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: '590px',
  gap: '8px',
}));

const InputButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '4px',
  marginBottom: '-8px',
}));

const InputButton = styled(Button)(({ theme }) => ({
  padding: '0 4px',
  maxWidth: 'fit-content',
  minWidth: 'fit-content',
  '&:hover': {
    backgroundColor: 'transparent',
    '&>svg path': {
      fill: '#7B75CB',
    },
  },
}));

export const StyledFlatInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  maxWidth: '512px',
  color: '#0c0c0c',
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #7B75CB',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: '2px solid #7B75CB',
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid #7B75CB',
  },
}));

const IntegrationStatus = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '590px',
}));

export const TelegramBotPanel = () => {
  const { currentUser, setCurrentUser } = useContext(CommonContext);
  const [telegramUsername, setTelegramUsername] = useState<string | undefined>(
    currentUser?.telegram_username
  );
  const throwError = useAsyncError();
  const [chatId, setChatId] = useState<string | undefined>(
    currentUser?.chat_id
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { setNotification } = useContext(NotificationContext);

  const TELEGRAM_BOT_URL = 'https://t.me/fw_api_orders_bot';

  const [linkInputValue] = useState(TELEGRAM_BOT_URL);

  // const [tgCodeInputValue] = useState("322");

  const openNewTab = (url: string) => window.open(url, '_blank');

  const copyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      setNotification({
        severity: 'success',
        message: localize.profile.edit.notifications.textCopied,
      });
    });
  };

  const triggerUpdate = async (): Promise<void> => {
    setSubmitting(true);
    await UserApiClient.updateSelf(
      {
        user: { telegram_username: telegramUsername },
      },
      currentUser.auth0_id
    )
      .then((res) => {
        setNotification({
          severity: 'success',
          message: localize.general.successCreateMessage,
        });
        setCurrentUser({
          ...currentUser,
          telegram_username: res.telegram_username,
          chat_id: res.chat_id,
        });
      })
      .catch((e) => {
        throwError(e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <MainWrapper>
      <StyledBox sx={{ gap: '24px' }}>
        <IntegrationStatus>
          <MainHeadingText>
            {localize.profile.edit.notifications.telegramConnect.title}
          </MainHeadingText>
          {chatId ? (
            <Chip
              icon={<Check />}
              label='Інтеграція активна'
              variant='outlined'
              color='success'
            />
          ) : (
            <Chip
              icon={<Close />}
              label='Інтеграція неактивна'
              variant='outlined'
              color='error'
            />
          )}
        </IntegrationStatus>
        <Typography variant='body1'>
          {localize.profile.edit.notifications.telegramConnect.description}
        </Typography>
      </StyledBox>
      <StyledBox>
        <MainHeadingText>
          {localize.profile.edit.notifications.telegramUsername.title}
        </MainHeadingText>
        <FormContainer>
          <StyledInput
            value={telegramUsername || ''}
            onChange={(e) => setTelegramUsername(e.target.value)}
            sx={{ flex: '1 1 auto' }}
            color='primary'
          />
          <Button
            variant='rounded'
            data-cy='telegram-bot-apply-btn'
            onClick={triggerUpdate}
            disabled={submitting}
            endIcon={
              submitting ? <CircularProgress size={16} color='inherit' /> : null
            }
          >
            {localize.profile.edit.buttonApply}
          </Button>
        </FormContainer>
      </StyledBox>

      <StyledBox>
        <MainHeadingText>
          {localize.profile.edit.notifications.telegramLink.title}
        </MainHeadingText>
        <InputContainer>
          <StyledFlatInput
            variant='standard'
            value={linkInputValue}
            InputProps={{
              readOnly: true,
            }}
            color='primary'
          />
          <InputButtonsContainer>
            <InputButton
              disableRipple
              onClick={() => copyToClipboard(linkInputValue)}
            >
              <CopyIcon fill='#AFABEF' />
            </InputButton>
            <InputButton
              disableRipple
              onClick={() => openNewTab(linkInputValue)}
            >
              <ShareIcon fill='#AFABEF' />
            </InputButton>
          </InputButtonsContainer>
        </InputContainer>
      </StyledBox>
      {/* <StyledBox>
        <MainHeadingText>
          {localize.profile.edit.notifications.telegramCode.title}
        </MainHeadingText>
        <InputContainer>
          <StyledFlatInput
            variant='standard'
            value={tgCodeInputValue}
            InputProps={{
              readOnly: true,
            }}
            color='primary'
          />
          <InputButtonsContainer>
            <InputButton
              disableRipple
              onClick={() => copyToClipboard(tgCodeInputValue)}
            >
              <CopyIcon />
            </InputButton>
          </InputButtonsContainer>
        </InputContainer>
      </StyledBox> */}
    </MainWrapper>
  );
};

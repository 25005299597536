import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Landing, Price } from './pages/landing-pages';
import RootCP from './pages/RootCP';
import { ThemeProvider } from './theme/ThemeProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/uk';

export const App: FC = () => {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='uk'>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/price' element={<Price />} />
          <Route path='/cp/*' element={<RootCP />} />
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;

import { FC } from 'react';
import { IconButton } from '@mui/material';

import { AnyObject } from '../../api/anyObjectTypes';
import { ReactComponent as EditItemIconSvg } from '../../assets/edit-item.svg';

type EditItemProps = {
  onClick: (row: AnyObject) => void;
  item: AnyObject;
};

export const EditItem: FC<EditItemProps> = ({ onClick, item }) => {
  return (
    <IconButton
      data-cy={`table-edit-item-btn-${item.id}`}
      sx={{
        width: 40,
        height: 40,
      }}
      onClick={() => {
        onClick(item);
      }}
    >
      <EditItemIconSvg fill='#777777' />
    </IconButton>
  );
};

import { Alert, AlertProps } from '@mui/material';
import React from 'react';
type CustomAlertProps = AlertProps & {
  backgroundColor?: string;
  children: React.ReactNode;
};
export const CustomAlert = ({
  severity = 'info',
  backgroundColor = '#E8F5FC',
  children,
  ...props
}: CustomAlertProps) => {
  return (
    <Alert
      severity={severity}
      sx={{
        bgcolor: backgroundColor,
        rowGap: '8px',
        '& .MuiAlert-icon': {
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: '28px',
          color: '#0c0c0c',
        },
      }}
      {...props}
    >
      {children}
    </Alert>
  );
};

import { BlobProvider, DocumentProps, usePDF } from '@react-pdf/renderer';
import { Button, Typography } from '@mui/material';
import { Article as ArticleIcon } from '@mui/icons-material';
import { FC, JSXElementConstructor, ReactElement, useEffect } from 'react';

type DocumentsMenuItemProps = {
  document: ReactElement<DocumentProps, string | JSXElementConstructor<any>>;
  btnText?: string;
  close?: () => void;
};

const DocumentsMenuItem: FC<DocumentsMenuItemProps> = ({
  document,
  btnText,
  close,
}) => {
  const [instance, update] = usePDF(undefined);

  useEffect(() => {
    update(document);
  }, [document]);

  return (
    <Button
      variant='rounded'
      onClick={() => {
        if (instance.url) {
          window.open(instance.url, '_blank');
          close && close();
        }
      }}
      startIcon={
        btnText ? undefined : <ArticleIcon sx={{ transform: 'scale(1.4)' }} />
      }
      size='large'
    >
      <Typography variant='body1'>{btnText || 'завантажити як pdf'}</Typography>
    </Button>
  );
};

export default DocumentsMenuItem;

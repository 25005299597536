import { FC, useEffect, useContext, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  Stack,
  Switch,
  Typography,
  TextField,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import localize from '../../localize';
import { NotificationContext } from '../../contexts/NotificationContext';
import { CommonContext } from '../../contexts/CommonContext';
import { ManufactureRequestApiClient } from '../../api/ManufactureRequestApiClient';
import { AnyObject } from '../../api/anyObjectTypes';
import { BaseApiClient } from '../../api/BaseApiClient';
import Loading from '../Loading/Loading';
import FormField from '../FormField/FormField';

import { ReactComponent as DeleteIcon } from '../../assets/delete-item.svg';
import { useValidateFormRequiredFields } from '../../hooks/useValidateFormRequiredFields';
import { useNavigate } from 'react-router-dom';
import CustomSwitch from '../CustomSwitch/CustomSwitch';
import { useMobile } from '../../hooks/useMobile';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '.MuiPaper-root': {
    borderRadius: '32px',
    border: '1px solid #AFABEF',
    background: '#fff',
    padding: '32px',
    margin: '0',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: '800px',
    overflow: 'visible',
    '& .MuiPopper-root.MuiAutocomplete-popper>*': {
      height: '30vh',
      alignItems: 'flex-start',
      padding: '10px 24px',
      '&>*': {
        height: '100%',
      },
    },
  },
}));

const ItemsFieldsBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '370px 140px 40px',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
  '&>div': { padding: '0' },
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

type ManufactureCreateFormProps = {
  open: boolean;
  discard(): void;
};

export const ManufactureCreateForm: FC<ManufactureCreateFormProps> = ({
  open,
  discard,
}) => {
  const basicMainLabels: AnyObject = {
    product_modification_id:
      localize.manufacture_requests.columns.product_modification_id,
    count: localize.manufacture_requests.columns.count,
  };
  const [mainFields, setMainFields] = useState<AnyObject[]>([]);
  const { setNotification } = useContext(NotificationContext);
  const [submitting, setSubmitting] = useState(false);
  const { currentProject } = useContext(CommonContext);
  const [isOutcome, setIsOutcome] = useState(false);
  const [comment, setComment] = useState('');
  const [items, setItems] = useState<AnyObject[]>([{}]);
  const [relations, setRelations] = useState<AnyObject[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mobile = useMobile();

  const isFormValid = useValidateFormRequiredFields(mainFields, items);

  const closeModal = () => {
    setComment('');
    setItems([{}]);
    !submitting && discard();
  };

  const fetchRelations = async () => {
    setLoading(true);
    const relationItems = await BaseApiClient.getIndex(
      currentProject?.id,
      'products'
    );

    const currentRelations = relationItems
      .map((el) =>
        el.product_modifications.map((pm: AnyObject) => ({
          groupLabel: [
            el.additional_fields?.secondaryid,
            el.additional_fields?.n_collection,
            el.title,
          ]
            .filter((f) => !!f)
            .join(' - '),
          label: `${pm.count} || ${pm.parsed_title}`,
          price: pm.price,
          id: pm.id,
        }))
      )
      .flat();

    relationItems && setRelations(currentRelations);
    setLoading(false);
  };

  const handleInputChange = ({
    value,
    index,
    name,
  }: {
    value: string;
    index: number;
    name: string;
  }) => {
    const newItems = items.slice();
    const newFields = {
      [name]: value,
    };
    newItems[index] = {
      ...newItems[index],
      ...newFields,
    };

    setItems(newItems);
  };

  const deleteItem = (
    e: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    e.preventDefault();
    const newItems = items.slice();
    newItems[index]['_destroy'] = true;
    setItems(newItems);
  };

  const onSubmit = async () => {
    const listOfItems = items
      .filter((item) => !item['_destroy'])
      .map((item) => ({
        product_modification_id: item.product_modification_id.id,
        count: item.count,
      }));
    setSubmitting(true);
    const record = await ManufactureRequestApiClient.create(currentProject.id, {
      manufacture_request: {
        comment,
        warehouse_flow: isOutcome ? 'outcome' : 'income',
        manufacture_requests_product_modifications_attributes: listOfItems,
      },
    });
    if (record) {
      setNotification({
        severity: 'success',
        message: localize.general.successCreateMessage,
      });
      setSubmitting(false);
      closeModal();
      navigate('/cp/manufacture_requests');
    } else {
      setNotification({
        severity: 'error',
        message: localize.general.unexpectedError,
      });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    setMainFields([
      ...Object.keys(basicMainLabels || {}).map((el) => ({
        editable: true,
        hidden: true,
        internal_name: el,
        label: basicMainLabels[el],
        parsable: false,
        required: true,
      })),
    ]);
    fetchRelations();
  }, [currentProject]);

  return (
    <StyledDialog open={open} fullWidth={true} onClose={closeModal}>
      <IconButton
        aria-label='close'
        data-cy='manufacture-form-close-btn'
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 12,
          top: 12,
          padding: '2px',
          color: '#9B96EB',
          border: '1px solid #9B96EB',
        }}
      >
        <CloseIcon />
      </IconButton>
      {loading && (
        <Stack width={mobile ? '100%' : 620} height={600}>
          <Loading />
        </Stack>
      )}
      {!loading && (
        <Stack
          component='form'
          spacing={1}
          minWidth={mobile ? 200 : 620}
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <CustomSwitch
            checkedOption={localize.manufacture_requests.index.status.outcome}
            option={localize.manufacture_requests.index.status.income}
            onChange={(checked) => {
              setIsOutcome(checked);
            }}
            checked={isOutcome}
          />
          <br />
          <TextField
            id='manufacture-comment'
            name='comment'
            label={localize.manufacture_requests.columns.comment}
            value={comment}
            placeholder={localize.general.inputPlaceholder}
            onChange={(e) => setComment(e.target.value)}
            sx={{ width: mobile ? '100%' : '370px' }}
          />
          <Divider sx={{ borderColor: '#DFDDF9' }} />
          <Box maxHeight={300} overflow='auto' id='fields_box_scroll'>
            {mainFields.length
              ? items.map((item, i) => {
                  if (item['_destroy']) return null;

                  return (
                    <ItemsFieldsBox key={`pm-${i}`}>
                      {mainFields.map((mf) => (
                        <FormField
                          key={`product_modification-${i}-${mf.internal_name}`}
                          value={item[mf.internal_name] || ''}
                          label={mf.label}
                          id={`pm_${mf.internal_name}-${i}`}
                          name={mf.internal_name}
                          required={mf.required}
                          fieldType={mf.field_type}
                          options={mf.options}
                          localizedField={mf.localized_field}
                          onChange={(e) => {
                            handleInputChange({
                              index: i,
                              value: e.target.value,
                              name: mf.internal_name,
                            });
                          }}
                          autocomplete={
                            mf.internal_name === 'product_modification_id'
                              ? {
                                  options: relations || [],
                                  onChange: (_, newValue: any) => {
                                    handleInputChange({
                                      index: i,
                                      value: newValue,
                                      name: mf.internal_name,
                                    });
                                  },
                                }
                              : undefined
                          }
                        />
                      ))}
                      {items.filter((item) => !item['_destroy']).length > 1 ? (
                        <>
                          <IconButton
                            data-cy='manufacture-form-delete-item-btn'
                            sx={{
                              width: 40,
                              height: 40,
                              display: 'block',
                              marginTop: '20px',
                              marginLeft: mobile ? 'auto' : '0',
                              background: '#fa3b3b81',
                              '&:hover': { background: '#FA3B3B' },
                            }}
                            onClick={(e): void => deleteItem(e, i)}
                          >
                            <DeleteIcon fill='#fff' />
                          </IconButton>
                          {mobile ? (
                            <Divider
                              sx={{ borderColor: '#DFDDF9', marginTop: '20px' }}
                            />
                          ) : null}
                        </>
                      ) : null}
                    </ItemsFieldsBox>
                  );
                })
              : null}
          </Box>
          <Button
            variant='transparent'
            data-cy='manufacture-form-add-item-btn'
            sx={{ width: 'fit-content' }}
            startIcon={
              loading ? (
                <CircularProgress color='inherit' size={20} />
              ) : (
                <AddIcon />
              )
            }
            onClick={() => (!loading ? setItems([...items, {}]) : undefined)}
          >
            {loading ? '' : localize.manufacture_requests.create.addFieldsBtn}
          </Button>
          {mobile ? <Divider sx={{ borderColor: '#DFDDF9' }} /> : null}
          <Box>
            <Button
              size='large'
              data-cy='manufacture-form-submit-btn'
              type='submit'
              variant='rounded'
              fullWidth={mobile}
              disabled={submitting || !isFormValid}
            >
              {submitting ? (
                <CircularProgress size={26} thickness={6} color='primary' />
              ) : isOutcome ? (
                localize.manufacture_requests.create.addOutcomeBtn
              ) : (
                localize.manufacture_requests.create.addIncomeBtn
              )}
            </Button>
            <Button
              variant='transparent'
              fullWidth={mobile}
              data-cy='manufacture-form-cancel-btn'
              onClick={closeModal}
              disabled={submitting}
            >
              {localize.general.cancel}
            </Button>
          </Box>
        </Stack>
      )}
    </StyledDialog>
  );
};

export default ManufactureCreateForm;

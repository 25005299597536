import localize from '../../localize';

export const projectSettingsFormFields = [
  {
    id: 'legal_name',
    label: localize.projects.edit.main.labels.fopName,
    type: 'text',
    required: false,
    placeholder: localize.projects.edit.main.labels.textPlaceholder,
  },
  {
    id: 'title',
    label: localize.projects.edit.main.labels.title,
    type: 'text',
    required: false,
    placeholder: localize.projects.edit.main.labels.textPlaceholder,
  },
  {
    id: 'website',
    label: localize.projects.edit.main.labels.website,
    type: 'text',
    required: false,
    placeholder: localize.projects.edit.main.labels.websitePlaceholder,
  },
  {
    id: 'contact_person',
    label: localize.projects.edit.main.labels.contactName,
    type: 'text',
    required: false,
    placeholder: localize.projects.edit.main.labels.textPlaceholder,
  },
  {
    id: 'category',
    label: localize.projects.edit.main.labels.category,
    type: 'radio-group',
    required: false,
    options: localize.onboarding.categoryData,
  },
  {
    id: 'min_order',
    label: localize.projects.edit.main.labels.minOrder,
    type: 'text',
    required: false,
    placeholder: localize.projects.edit.main.labels.textPlaceholder,
  },
  {
    id: 'region',
    label: localize.projects.edit.main.labels.region,
    labelNote: localize.projects.edit.main.labels.ifExists,
    type: 'select',
    required: false,
    placeholder: localize.projects.edit.main.labels.chooseRegion,
    options: localize.projects.edit.main.regions,
  },
  {
    id: 'delivery_time',
    label: localize.projects.edit.main.labels.deliveryTime,
    type: 'text',
    required: false,
    placeholder: localize.projects.edit.main.labels.textPlaceholder,
  },
  {
    id: 'delivery_text',
    label: localize.projects.edit.main.labels.delivery,
    type: 'text',
    required: false,
    placeholder: localize.projects.edit.main.labels.textPlaceholder,
  },
  {
    id: 'return_of_goods',
    label: localize.projects.edit.main.labels.returnOfGoods,
    type: 'text',
    required: false,
    placeholder: localize.projects.edit.main.labels.textPlaceholder,
  },
  {
    id: 'description',
    label: localize.projects.edit.main.labels.description,
    type: 'textarea',
    required: false,
    placeholder: localize.projects.edit.main.labels.textPlaceholder,
  },
] as const;

export const paymentFormFields = [
  {
    id: 'edropoy_code',
    label: localize.projects.edit.paymentSettings.labels.edrpou,
    type: 'text',
    required: false,
    placeholder:
      localize.projects.edit.paymentSettings.labels.edrpouPlaceholder,
    errorText: localize.projects.edit.paymentSettings.labels.edrpouError,
  },
  {
    id: 'iban',
    label: localize.projects.edit.paymentSettings.labels.iban,
    type: 'text',
    required: false,
    placeholder: localize.projects.edit.paymentSettings.labels.ibanPlaceholder,
    errorText: localize.projects.edit.paymentSettings.labels.ibanError,
  },
] as const;

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  styled,
  Typography,
} from '@mui/material';
import { QuestionMark as QuestionMarkIcon } from '@mui/icons-material';
import { Period } from './Tarrifs';
import { PriceHeaderCells } from './PriceHeaderCells';

export const TableWrapper = styled(TableContainer)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  alignItems: 'stretch',
  gap: '20px',
  width: '100%',
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '3fr 2fr',
    gap: '8px',
  },
}));

const TarrifsNamesTable = styled(Table)(({ theme }) => ({
  maxWidth: '410px',
  fontFamily: 'Montserrat, sans-serif',
  border: 'none',
  '& td, & th': {
    border: 'none',
  },

  tbody: {
    border: 'none',
    borderRadius: '14px',
    boxShadow: '11px 10px 10px 0px #606CD614',
    backgroundColor: '#AFABEF',
    fontWeight: 700,

    '& tr, td, th': {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: '15px',
        fontWeight: 500,
        padding: '8px',
      },
    },

    '& tr td:last-of-type': {
      paddingRight: '8px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '3px',
        paddingRight: '4px',
      },
    },

    '& tr td:first-of-type': {
      paddingRight: '8px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '135px',
      },
    },

    '& tr:first-of-type td:first-of-type': {
      borderTopLeftRadius: '14px',
      paddingTop: '8px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '6px',
      },
    },

    '& tr:first-of-type td:last-of-type': {
      borderTopRightRadius: '14px',
      paddingTop: '8px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '6px',
      },
    },

    '& tr:last-of-type td:first-of-type': {
      borderBottomLeftRadius: '14px',
      paddingBottom: '8px',
      paddingRight: '0',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '6px',
      },
    },

    '& tr:last-of-type td:last-of-type': {
      borderBottomRightRadius: '14px',
      paddingBottom: '8px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: '6px',
      },
    },
  },
}));

const StyledTooltipContent = styled(Paper)(({ theme }) => ({
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  color: '#7B75CB',
  [theme.breakpoints.down('sm')]: {
    width: '24px',
    height: '24px',
    '> svg': {
      fontSize: '12px',
    },
  },
}));

const TarrifsDataTable = styled(Table)(({ theme }) => ({
  maxWidth: '810px',
  fontFamily: 'Montserrat, sans-serif',
  color: '#0c0c0c',
  border: 'none',
  '& td, & th': {
    border: 'none',
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 7px',
      fontSize: '12px',
    },
  },

  '.data-section': {
    '&:before': {
      content: '""',
      height: '25px',
      display: 'table-row',
      [theme.breakpoints.down('sm')]: {
        height: '13px',
      },
    },
    '&:first-of-type': {
      '&:before': {
        height: '8px',
        [theme.breakpoints.down('sm')]: {
          height: '5px',
        },
      },
    },
    '.data-row': {
      '.data-cell': {
        backgroundColor: '#DFDDF9',
        minWidth: '40px',
      },
    },

    '&:nth-of-type(2)': {
      '&:before': {
        height: '44px',
        [theme.breakpoints.down('sm')]: {
          height: '22px',
        },
      },
    },

    '&:nth-of-type(3)': {
      '&:before': {
        height: '36px',
        [theme.breakpoints.down('sm')]: {
          height: '18px',
        },
      },
    },

    '&:last-of-type': {
      '&:after': {
        content: '""',
        display: 'table-row',
        height: '8px',
      },
      '&:before': {
        content: '""',
        display: 'table-row',
        height: '25px',
        [theme.breakpoints.down('sm')]: {
          height: '12.5px',
        },
      },
    },
    '& tr:first-of-type td:first-of-type': {
      borderTopLeftRadius: '4px',
    },

    '& tr:first-of-type td:last-of-type': {
      borderTopRightRadius: '4px',
    },

    '& tr:last-of-type td:first-of-type': {
      borderBottomLeftRadius: '4px',
    },

    '& tr:last-of-type td:last-of-type': {
      borderBottomRightRadius: '4px',
    },
  },
}));

type PricingPlan = {
  currentPrice: string;
  oldPrice?: string;
};

export type Costs = Record<
  Period,
  {
    basic: PricingPlan;
    standard: PricingPlan;
    business: PricingPlan;
    custom: PricingPlan;
  }
>;

type Feature = {
  feature: string;
  tooltip?: string;
  basic: number | string;
  standard: number | string;
  business: number | string;
  custom: string;
};

type PricingPlansTable = {
  headers: {
    feature: string;
    plans: Record<RenderedPlans, string | string[]>;
  };
  features: Feature[];
  costs: Costs;
};

export type RenderedPlans =
  | 'all'
  | 'basic'
  | 'standard'
  | 'business'
  | 'custom';

const StyledCell = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Montserrat, sans-serif',
  fontSize: '14px',
  padding: '16px',
  fontWeight: 700,
  color: '#7B75CB',
}));

export const TarrifsTable = ({
  period,
  data,
  plan,
}: {
  period: Period;
  data: PricingPlansTable;
  plan: RenderedPlans;
}) => {
  return (
    <TableWrapper>
      <TarrifsNamesTable>
        <TableHead>
          {plan === 'all' && (
            <TableRow aria-hidden='true'>
              <TableCell sx={{ opacity: 0, padding: '16px' }} align='right'>
                <Typography variant='h6'>&nbsp;</Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            {plan === 'all' ? (
              <TableCell
                sx={{
                  border: 'none',
                  paddingRight: 0,
                  color: '#0c0c0c',
                  padding: '16px',
                  height: '58px',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
                align='right'
              >
                {data.headers.feature}
              </TableCell>
            ) : (
              <StyledCell align='left'>{data.headers.plans[plan]}</StyledCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.features.map((f, i) => (
            <TableRow key={f.feature + i}>
              <TableCell
                sx={{
                  color: '#fff',
                  fontWeight: 700,
                  fontSize: '16px',
                  padding: '16px',
                }}
              >
                {f.feature}
              </TableCell>
              <TableCell>
                {f.tooltip ? (
                  <Tooltip title={f.tooltip}>
                    <StyledTooltipContent>
                      <QuestionMarkIcon fontSize='small' />
                    </StyledTooltipContent>
                  </Tooltip>
                ) : (
                  ' '
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TarrifsNamesTable>
      <TarrifsDataTable>
        <TableHead>
          {plan === 'all' && (
            <TableRow>
              {(data.headers.plans[plan] as string[]).map((plan) => (
                <StyledCell align='center' key={plan}>
                  {plan}
                </StyledCell>
              ))}
            </TableRow>
          )}
          <TableRow>
            <PriceHeaderCells costs={data.costs} period={period} plan={plan} />
          </TableRow>
        </TableHead>
        <>
          {data.features.map((item) => (
            <TableBody className='data-section' key={item.feature}>
              <TableRow className='data-row'>
                {plan === 'all' ? (
                  <>
                    <TableCell className='data-cell' align='center'>
                      {item.basic}
                    </TableCell>
                    <TableCell className='data-cell' align='center'>
                      {item.standard}
                    </TableCell>
                    <TableCell className='data-cell' align='center'>
                      {item.business}
                    </TableCell>
                    <TableCell className='data-cell' align='center'>
                      {item.custom}
                    </TableCell>
                  </>
                ) : (
                  <TableCell className='data-cell' align='center'>
                    {item[plan]}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          ))}
        </>
      </TarrifsDataTable>
    </TableWrapper>
  );
};

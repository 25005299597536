import { FC, ReactNode, useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  MenuItem,
  TextField,
  InputLabel,
  Box,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import FileUpload from '../FileUpload/FileUpload';
import { getRandomHexSegment } from '../../utils/strings';

import { ReactComponent as CloseCircle } from '../../assets/chip-delete.svg';

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '500px',
  padding: '10px',
  '& > div': {
    width: '100%',
  },
}));

const FullWidthContainer = styled('div')(({ theme }) => ({
  width: '100%!important',
  padding: '10px',
  '& > div': {
    width: '100%',
  },
}));

const AutocompleteGroupLabel = styled('div')(({ theme }) => ({
  fontWeight: 500,
  fontSize: '12px',
  color: '#bdbdbd',
  paddingLeft: '5px',
}));

const ChipDeleteIcon = styled(CloseCircle)(({ theme }) => ({
  transition: 'all 0.3s ease',
  '& svg:hover path': {
    fill: theme.palette.primary.main,
  },
}));

const LabelForInput = styled(InputLabel)(() => ({
  marginBottom: '10px',
  color: '#0C0C0C',
  overflow: 'visible',
}));

type FormFieldProps = {
  value: string | AnyObject;
  label: string | ReactNode;
  name: string;
  id?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  hasChips?: boolean;
  fieldType?: string;
  options?: string[];
  loading?: boolean;
  localizedField?: any;
  onChange(e: { target: { name: any; value: any } }): void;
  autocomplete?: {
    options: AnyObject[];
    renderOption?(
      props: React.HTMLAttributes<HTMLLIElement>,
      option: any
    ): ReactNode;
    onChange(_: any, newValue: any): void;
  };
  sx?: any;
  error?: string;
};

export const FormField: FC<FormFieldProps> = ({
  name,
  id,
  label,
  placeholder,
  value,
  required,
  onChange,
  fieldType = 'string',
  disabled,
  options,
  localizedField,
  hasChips,
  autocomplete,
  loading,
  error,
  sx,
}) => {
  const [inputValues, setInputValues] = useState<AnyObject>({});
  const currentUserLocale = 'ua';
  const fieldLabel = useMemo(() => {
    if (
      localizedField &&
      localizedField[currentUserLocale] &&
      localizedField[currentUserLocale]?.label
    )
      return localizedField[currentUserLocale]?.label;

    return label;
  }, [label]);

  const styles = useMemo(
    () => ({
      ...sx,
      ...(loading
        ? {
            opacity: '0.5',
            pointerEvents: 'none',
          }
        : {}),
    }),
    [sx]
  );

  if (!autocomplete) {
    if (options?.length) {
      return (
        <Container sx={styles}>
          <LabelForInput required={required}>{fieldLabel}</LabelForInput>
          <TextField
            id={name}
            data-cy={`${name}-input`}
            select
            disabled={disabled || fieldType === 'static'}
            required={required}
            margin='none'
            fullWidth
            value={value}
            error={!!error}
            helperText={error}
            onChange={(e) =>
              onChange({ target: { name, value: e.target.value } })
            }
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {!!(
                  localizedField && localizedField[currentUserLocale]?.options
                )
                  ? localizedField[currentUserLocale].options[option]
                  : option}
              </MenuItem>
            ))}
          </TextField>
        </Container>
      );
    }
    if (fieldType === 'boolean')
      return (
        <Container
          sx={{ ...styles, height: 'fit-content', margin: 'auto 0 5px' }}
        >
          <FormControlLabel
            disabled={disabled}
            name={name}
            data-cy={`${name}-checkbox`}
            label={fieldLabel}
            required={required}
            value={value}
            control={
              <Checkbox
                checked={Boolean(value)}
                onChange={(event, checked) => {
                  onChange({ target: { name, value: checked } });
                }}
              />
            }
          />
        </Container>
      );
    if (
      fieldType === 'number' ||
      fieldType === 'string' ||
      fieldType === 'static'
    ) {
      return (
        <Container sx={styles}>
          <LabelForInput required={required}>{fieldLabel}</LabelForInput>
          <TextField
            id={id || name}
            name={name}
            data-cy={`${name}-input`}
            type={fieldType === 'number' ? 'number' : 'text'}
            disabled={disabled || fieldType === 'static'}
            required={required}
            value={value}
            placeholder={placeholder || localize.general.inputPlaceholder}
            error={!!error}
            helperText={error}
            inputProps={{
              ...(fieldType === 'number'
                ? { min: '0', step: name !== 'count' ? '0.001' : '1' }
                : {}),
              disabled: disabled || fieldType === 'static',
            }}
            onChange={onChange}
            margin='none'
            fullWidth
          />
        </Container>
      );
    }
    if (fieldType === 'text') {
      return (
        <Container sx={styles}>
          <LabelForInput required={required}>{fieldLabel}</LabelForInput>
          <TextField
            id={name}
            name={name}
            data-cy={`${name}-input`}
            multiline={true}
            type='text'
            disabled={disabled}
            required={required}
            placeholder={placeholder || localize.general.inputPlaceholder}
            value={value}
            error={!!error}
            helperText={error}
            inputProps={{
              disabled,
            }}
            onChange={onChange}
            margin='none'
            fullWidth
          />
        </Container>
      );
    }
    if (fieldType === 'file')
      return (
        <FullWidthContainer sx={styles}>
          <LabelForInput>{fieldLabel}</LabelForInput>
          <FileUpload
            data-cy={`${name}-file-upload`}
            onChange={(files) => onChange({ target: { name, value: files } })}
            value={value as string[]}
          />
        </FullWidthContainer>
      );

    if (fieldType === 'image')
      return (
        <FullWidthContainer sx={styles}>
          <LabelForInput>{fieldLabel}</LabelForInput>
          <FileUpload
            compact={true}
            data-cy={`${name}-file-image-upload`}
            onChange={(files) => onChange({ target: { name, value: files } })}
            value={value as string[]}
            accept={{ 'image/*': [] }}
            maxFiles={1}
          />
        </FullWidthContainer>
      );

    if (fieldType === 'files')
      return (
        <FullWidthContainer sx={styles}>
          <LabelForInput>{fieldLabel}</LabelForInput>
          <FileUpload
            data-cy={`${name}-group-file-upload`}
            onChange={(files) => onChange({ target: { name, value: files } })}
            value={value as string[]}
            accept={{ 'image/*': [] }}
          />
        </FullWidthContainer>
      );

    if (fieldType === 'datetime')
      return (
        <Container sx={styles}>
          <LabelForInput>{fieldLabel}</LabelForInput>
          <DateTimePicker
            data-cy={`${name}-datetime-picker`}
            value={(value as Dayjs) || null}
            onChange={(newValue) =>
              onChange({ target: { name, value: newValue } })
            }
          />
        </Container>
      );
    if (fieldType === 'select' || hasChips) {
      return (
        <Container sx={styles}>
          <Autocomplete
            multiple
            freeSolo
            disableCloseOnSelect
            disableClearable
            data-cy={`${name}-chips-input`}
            options={[]}
            value={(value as string[]) || []}
            onChange={(event, newValue) =>
              onChange({ target: { name, value: newValue } })
            }
            renderTags={(value: string[], getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option}
                  deleteIcon={<ChipDeleteIcon />}
                  {...getTagProps({ index })}
                  key={option + getRandomHexSegment()}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                data-cy={`${name}`}
                error={!!error}
                helperText={error}
                disabled={disabled}
                fullWidth
                required={required}
                label={fieldLabel}
                variant='outlined'
              />
            )}
          />
        </Container>
      );
    }
  }

  if (!autocomplete?.options?.length) return null;

  return (
    <Container
      sx={{
        ...styles,
        '& .MuiPaper-root': {
          borderRadius: '16px',
          border: '1px solid #AFABEF',
          background: '#fff',
          padding: '10px',
          margin: '0',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          maxWidth: '800px',
          overflow: 'visible',
          '& .MuiAutocomplete-listbox': {
            width: '100%',
          },
          '& .MuiPopper-root.MuiAutocomplete-popper>*': {
            height: '30vh',
            alignItems: 'flex-start',
            padding: '10px',
            '&>*': {
              height: '100%',
            },
          },
        },
      }}
    >
      <Autocomplete
        disablePortal
        autoSelect
        filterOptions={(x) =>
          inputValues[name]
            ? x.filter((y) => {
                return (
                  y?.groupLabel
                    ?.toLowerCase()
                    ?.includes(inputValues[name]?.toLowerCase()) ||
                  y?.label
                    ?.toLowerCase()
                    ?.includes(inputValues[name]?.toLowerCase())
                );
              })
            : x
        }
        onChange={autocomplete.onChange}
        renderOption={
          autocomplete.renderOption ||
          ((props, option) => (
            <li {...props} key={option.id} data-cy={option.id}>
              {option.label}
            </li>
          ))
        }
        renderGroup={(params) => (
          <Box
            key={params.key}
            sx={{
              padding: '5px 0',
              '&:not(:last-child)': { borderBottom: '1px solid #7B75CB' },
            }}
          >
            <AutocompleteGroupLabel
              sx={{
                color: '#7B75CB',
                fontWeight: 500,
                fontSize: '14px',
                mt: '10px',
              }}
            >
              {params.group}
            </AutocompleteGroupLabel>
            <div>{params.children}</div>
          </Box>
        )}
        inputValue={
          inputValues[name] || localize.general.inputSelectPlaceholder
        }
        onInputChange={(_, newInputValue) => {
          setInputValues({ ...inputValues, [name]: newInputValue });
        }}
        value={
          autocomplete.options.find((ao) =>
            typeof value === 'object' ? value?.id === ao.id : ao.id === value
          ) || null
        }
        groupBy={(option: AnyObject) => option.groupLabel}
        getOptionLabel={(option: AnyObject) => option.label}
        options={autocomplete.options}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            data-cy={`${name}-autocomplete`}
            required={required}
            fullWidth
            error={!!error}
            helperText={error}
            label={
              (typeof value === 'object'
                ? value?.groupLabel
                : autocomplete.options.find((ao) => ao.id === value)
                    ?.groupLabel) || fieldLabel
            }
          />
        )}
      />
    </Container>
  );
};

export default FormField;

import { ReactComponent as AnalyticsIconSvg } from '../assets/analytics.svg';
import { ReactComponent as KeyIconSvg } from '../assets/key.svg';
import { ReactComponent as UsersIconSvg } from '../assets/users.svg';
import { ReactComponent as WarehouseIconSvg } from '../assets/warehouse.svg';
import { ReactComponent as SalesIconSvg } from '../assets/sales.svg';
// import { ReactComponent as EditIconSvg } from '../assets/edit.svg';
// import { ReactComponent as DeleteIconSvg } from '../assets/delete.svg';

import localize from '../localize';

export type SideBarItem = {
  icon?: any;
  name: string;
  text: string;
  link?: string;
  commingSoon?: boolean;
  expandDirection?: 'down' | 'right';
};

interface NavLinksForAPP {
  uk: { [key: string]: { link: string; name: string } };
  en: { [key: string]: { link: string; name: string } };
}

export const basicSideBarItems: SideBarItem[] = [
  {
    icon: <AnalyticsIconSvg />,
    text: localize.sidebar.analytics,
    name: 'analytics',
    link: '#',
    commingSoon: true,
  },
  {
    icon: <SalesIconSvg />,
    text: localize.sidebar.sales,
    name: 'sales',
    expandDirection: 'down',
  },
  {
    icon: <WarehouseIconSvg />,
    text: localize.sidebar.warehouse,
    name: 'warehouse',
    expandDirection: 'down',
  },
  {
    icon: <KeyIconSvg />,
    text: localize.sidebar.integration,
    name: 'integration',
    expandDirection: 'down',
  },
  {
    icon: <UsersIconSvg />,
    text: localize.sidebar.users,
    name: 'users',
    expandDirection: 'down',
  },
];

const basicSideBarUserItems: SideBarItem[] = [
  {
    text: localize.sidebar.usersApplications,
    name: 'users-clients',
    link: '/cp/users',
    commingSoon: true,
  },
  {
    text: localize.sidebar.usersDrop,
    name: 'users-drop',
    link: '/cp/users/dropshipers',
  },
  {
    text: localize.sidebar.usersAdmin,
    name: 'users-admin',
    link: '/cp/users/admins',
  },
];

const basicSideBarSalesItems: SideBarItem[] = [
  { text: localize.sidebar.orders, name: 'order', link: '/cp/orders' },
  {
    text: localize.sidebar.promotions,
    name: 'promotion',
    link: '/cp/promotions',
  },
  { text: localize.sidebar.clients, name: 'customer', link: '/cp/clients' },
];

const basicSideBarWarehouseItems: SideBarItem[] = [
  { text: localize.sidebar.products, name: 'product', link: '/cp/products' },
  { text: localize.sidebar.bookings, name: 'booking', link: '/cp/bookings' },
  {
    text: localize.sidebar.manufacture,
    name: 'manufacturerequest',
    link: '/cp/manufacture_requests',
  },
];

const basicSideBarIntegrationItems: SideBarItem[] = [
  { text: localize.sidebar.API, name: 'api_key', link: '/cp/integrations/api' },
  {
    text: localize.sidebar.delivery,
    name: 'delivery',
    link: '#',
    commingSoon: true,
  },
  {
    text: localize.sidebar.banking,
    name: 'banking',
    link: '#',
    commingSoon: true,
  },
];

export const innerSideBarItems: { [key: string]: SideBarItem[] } = {
  sales: basicSideBarSalesItems,
  warehouse: basicSideBarWarehouseItems,
  integration: basicSideBarIntegrationItems,
  users: basicSideBarUserItems,
};

export const projectsNavItems: SideBarItem[] = [
  {
    text: localize.sidebar.switchProjects,
    name: 'switch_projects',
    expandDirection: 'right',
  },
  // {
  //   text: localize.sidebar.createProject,
  //   name: 'create_project',
  //   link: '#',
  // },
  // {
  //   text: localize.sidebar.projectsCatalogue,
  //   name: 'projects_catalogue',
  //   link: '#',
  // },
  {
    text: localize.sidebar.projectsSettings,
    name: 'projects_settings',
    link: '/cp/settings',
  },
];

export const navLinksForApp: NavLinksForAPP = {
  uk: {
    // Аналітика: '#',
    'API доступ': { link: '/api-keys', name: 'api_key' },
    // Telegram: '#',
    // 'Нова пошта': '#',
    // Банкінг: '#',
    Продукти: { link: '/cp/products', name: 'product' },
    Замовлення: { link: '/cp/orders', name: 'order' },
    'Спеціальні пропозиції': { link: '/cp/promotions', name: 'promotion' },
    Клієнти: { link: '/cp/clients', name: 'customer' },
    Бронювання: { link: '/cp/bookings', name: 'booking' },
    Виробництво: {
      link: '/cp/manufacture_requests',
      name: 'manufacturerequest',
    },
    'Профіль користувача': { link: '/cp/profile', name: 'profile' },
    'Налаштування проєкту': { link: '/cp/settings', name: 'project' },
    // 'Перемикнути проєкт': '#',
    // 'Створити проєкт': '#',
    // 'Каталог проєктів': '#',
    'Редагувати проєкт': { link: '/cp/settings', name: 'project' },
    // 'Видалити проєкт': '#',
    Заявки: { link: '/cp/users', name: 'users' },
    Дропшипери: { link: '/cp/users/dropshipers', name: 'users-drop' },
    Менеджери: { link: '/cp/users/admins', name: 'users-admin' },
  },
  en: {},
};

import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Grid, Typography } from '@mui/material';

import { CommonContext } from '../../contexts/CommonContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { Order, OrderApiClient } from '../../api/OrderApiClient';
import { BookingApiClient } from '../../api/BookingApiClient';
import { post } from '../../api/request';
import localize from '../../localize';
import Loading from '../../components/Loading/Loading';
import Page from '../../components/Page/Page';
import StatusesTimeline, {
  statusColors,
} from '../../containers/StatusesTimeline';
import ClientDetailsCard from '../../containers/order/ClientDetailsCard';
import OrderItemsCard from '../../containers/order/OrderItemsCard';
import CommentsCard from '../../containers/order/CommentsCard';
import TotalSumCard from '../../containers/order/TotalSumCard';
import AuditsContent from '../../containers/AuditsContent';
import CreatePDFFromOrder from '../../components/CreatePDFFromOrder/CreatePDFFromOrder';
import SmallCardForOrder from '../../containers/order/SmallCardForOrder';
import { formatDateTime } from '../../utils/time';
import { styled } from '@mui/material/styles';
import PageMenu from '../../components/PageMenu/PageMenu';
import { numberToCurrency } from '../../utils/numberToCurrency';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import { useMobile } from '../../hooks/useMobile';

const StyledGridItem = styled(Grid)(({ theme }) => ({
  padding: '6px',
  '&>*': {
    width: '100%',
    height: '100%',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '4px 8px 32px 0px #5043CF14',
    background: '#fff',
    border: '1px solid #fff',
    [theme.breakpoints.down('md')]: {
      padding: '8px',
    },
  },
}));

export const OrderShowPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setNotification } = useContext(NotificationContext);
  const { currentProject, currentUser } = useContext(CommonContext);
  const [item, setItem] = useState<Order>();
  const [openCreatePDF, setOpenCreatePDF] = useState(false);
  const [itemIdToDelete, setItemIdToDelete] = useState<string | undefined>();
  const [statusToChangeTo, setStatusToChangeTo] = useState<
    string | undefined
  >();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [booking, setBooking] = useState<boolean>(false);
  const [changingStatus, setChangingStatus] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [itemIdToBookTemporary, setItemIdToBookTemporary] = useState<
    string | undefined
  >();
  const mobile = useMobile();

  const inQueue = useMemo(
    () =>
      item?.order_items &&
      !!item?.order_items.filter((oi: any) => !oi?.hide_collapsed).length &&
      currentUser.user_permissions.role_name !== 'client',
    [item, currentUser]
  );

  const pageActions = useMemo(() => {
    if (!item) return [];

    const res: any[] = [];

    const createPDF = {
      Component: (
        <Button
          variant='transparent'
          data-cy={`order_${id}_create_PDF_btn`}
          onClick={() => {
            item && setOpenCreatePDF(true);
          }}
        >
          {localize.orders.show.createPDF}
        </Button>
      ),
    };

    res.push(createPDF);

    const deleteAction = {
      title: localize.orders.show.deleteButton,
      onClick: () => setItemIdToDelete(id),
    };

    const editItem: any[] = [];

    if (item?.last_status !== 'Canceled' && item?.last_status !== 'Complete') {
      if (item?.editable_by_current_user) {
        let validStatuses = ['Pending'];
        if (
          ['staff', 'owner', 'manager', 'administrator'].includes(
            currentUser?.user_permissions?.role_name
          )
        )
          validStatuses = [
            'Pending',
            'Ready',
            'InProduction',
            'Complete',
            'Canceled',
            'Blocked',
          ];
        validStatuses
          .filter((st) => st !== item?.last_status)
          .forEach((status) =>
            editItem.push({
              title:
                status === 'Pending' &&
                currentUser?.user_permissions?.role_name === 'client'
                  ? localize.orders.show.clientChangeStatusToPending
                  : localize.orders.show[`changeStatusTo${status}`],
              onClick: () => setStatusToChangeTo(status),
            })
          );
        if (
          item?.last_status === 'Draft' &&
          !!item?.order_items?.filter((oi: any) => !oi.is_booked).length
        ) {
          editItem.push({
            title: localize.orders.show.bookTemporary,
            onClick: () => setItemIdToBookTemporary(id),
          });
        }
      }

      if (item?.editable_by_current_user) {
        editItem.push({ divider: true });
      }

      if (
        currentUser.user_permissions.order?.destroy?.length &&
        (currentUser.user_permissions.role_name !== 'client' ||
          item?.last_status === 'Draft')
      )
        editItem.push(deleteAction);
    }

    if (item?.last_status !== 'Canceled' && item?.last_status !== 'Complete') {
      if (item?.editable_by_current_user) {
        const editAction = {
          Component: (
            <PageMenu
              menuItems={editItem}
              title={localize.general.options}
              isRounded
            />
          ),
        };

        res.push(editAction);
      }
    }

    return res;
  }, [item]);

  const preparePageData = async (id: string): Promise<void> => {
    const res = await OrderApiClient.getShow(currentProject.id, id);
    setItem({ ...res });
  };

  const deleteItem = () => {
    if (itemIdToDelete) {
      setDeleting(true);
      OrderApiClient.remove(currentProject.id, itemIdToDelete).then(() => {
        setItemIdToDelete(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successDeleteMessage,
        });
        setDeleting(false);
        navigate(`/cp/orders`);
      });
    }
  };

  const bookTemporary = () => {
    if (item && itemIdToBookTemporary && item.order_items?.length > 0) {
      setBooking(true);
      Promise.all(
        item.order_items.map((orderItem: any) =>
          BookingApiClient.create(currentProject.id, {
            order_item_id: orderItem.id,
          })
        )
      ).then(() => {
        setItemIdToBookTemporary(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successMessage,
        });
        loadData(item.id);
        setBooking(false);
      });
    }
  };

  const changeStatus = () => {
    if (statusToChangeTo && id) {
      setChangingStatus(true);
      post(`projects/${currentProject.id}/orders/${id}/order_statuses`, {
        title: statusToChangeTo,
      }).then(() => {
        setStatusToChangeTo(undefined);
        setNotification({
          severity: 'success',
          message: localize.general.successMessage,
        });
        loadData(id);
        setChangingStatus(false);
      });
    }
  };

  const loadData = (id: string) => {
    setLoading(true);
    preparePageData(id).finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!id) return;

    loadData(id);
  }, [id, currentProject]);

  if (loading || !item || !id)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page
      title={`${localize.orders.show.title} ${item.source ? `(${item.source})` : ''} №${item.id}`}
      actions={pageActions}
      subtitle={inQueue ? localize.orders.show.queueAttention : undefined}
    >
      <CreatePDFFromOrder
        open={openCreatePDF}
        close={() => {
          setOpenCreatePDF(false);
        }}
        order={item}
      />
      <Grid container columns={mobile ? 1 : 5}>
        <StyledGridItem item xs={1}>
          <SmallCardForOrder
            type='last_status'
            text={
              <Typography
                color={statusColors[item.last_status]}
                component='span'
                variant='h5'
              >
                {localize.order_statuses.values[item.last_status]}
              </Typography>
            }
          />
        </StyledGridItem>
        <StyledGridItem item xs={1}>
          <SmallCardForOrder
            type='sent_to_manager_at'
            text={formatDateTime(item.sent_to_manager_at)}
          />
        </StyledGridItem>
        <StyledGridItem item xs={1}>
          <SmallCardForOrder
            type='updated_at'
            text={formatDateTime(item.updated_at)}
          />
        </StyledGridItem>
        <StyledGridItem item xs={1}>
          <SmallCardForOrder
            type='fixed_recommended_sum_with_discount'
            text={numberToCurrency(+item.fixed_recommended_sum_with_discount)}
          />
        </StyledGridItem>
        <StyledGridItem item xs={1}>
          <SmallCardForOrder type='sum' text={numberToCurrency(+item.sum)} />
        </StyledGridItem>
        <StyledGridItem item xs={1} md={3}>
          <TotalSumCard order={item} />
        </StyledGridItem>
        <StyledGridItem item xs={1} md={2}>
          <ClientDetailsCard order={item} />
        </StyledGridItem>
        <StyledGridItem item xs={1} md={5}>
          <OrderItemsCard order={item} />
        </StyledGridItem>
        <StyledGridItem item xs={1} md={3}>
          <CommentsCard order={item} />
        </StyledGridItem>
        <StyledGridItem item xs={1} md={2}>
          <StatusesTimeline />
        </StyledGridItem>
        <StyledGridItem item xs={1} md={5}>
          <AuditsContent auditedType='Order' auditedId={id} />
        </StyledGridItem>
      </Grid>
      <ConfirmationDialog
        header={localize.orders.show.deleteConfirmationHeading}
        open={!!itemIdToDelete}
        submitting={deleting}
        description={localize.orders.show.deleteConfirmationText}
        action={() => deleteItem()}
        discard={() => setItemIdToDelete(undefined)}
        mainActionButtonText={localize.orders.show.deleteConfirmationButtonText}
      />
      <ConfirmationDialog
        header={localize.orders.show.bookTemporaryConfirmationHeading}
        open={!!itemIdToBookTemporary}
        submitting={booking}
        description={localize.orders.show.bookTemporaryConfirmationDescription}
        action={() => bookTemporary()}
        discard={() => setItemIdToBookTemporary(undefined)}
        mainActionButtonText={
          localize.orders.show.bookTemporaryConfirmationButtonText
        }
      />
      <ConfirmationDialog
        header={localize.orders.show.statusChangeConfirmationHeading}
        open={!!statusToChangeTo}
        submitting={changingStatus}
        description={
          <>
            {statusToChangeTo &&
              localize.order_statuses.values_info[
                statusToChangeTo === 'Pending' &&
                currentUser.user_permissions.role_name === 'client'
                  ? 'Pending_client'
                  : statusToChangeTo
              ]}
            {statusToChangeTo === 'Complete' && inQueue ? (
              <>
                <br />
                <br />
                {localize.orders.show.changeStatusToCompleteWithQueue}
              </>
            ) : (
              ''
            )}
          </>
        }
        action={() => changeStatus()}
        discard={() => setStatusToChangeTo(undefined)}
        mainActionButtonText={
          localize.orders.show.statusChangeConfirmationButtonText
        }
      />
    </Page>
  );
};

export default OrderShowPage;

import { FC, PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Button, CssBaseline, Typography } from '@mui/material';

import SideBar from '../SideBar/SideBar';
import RightSideBar from '../RightSideBar/RightSideBar';
import PageMenu from '../PageMenu/PageMenu';
import { useMobile } from '../../hooks/useMobile';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  maxWidth: '100vw',
  // maxHeight: '100vh', i dont know why
}));

const ContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
}));

const PageLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  minHeight: '98vh',
  flexDirection: 'column',
  maxWidth: '100%',
  overflowX: 'auto',
  height: '98vh',
  maxHeight: '98vh',
  margin: '1vh 10px 1vh 0',
  backgroundColor: '#fff',
  flex: '1 1 auto',
  borderRadius: '24px',
  border: '1px solid #F2F2F2',
  boxShadow: '4px 8px 32px 0px #5043CF14',
  [theme.breakpoints.down('md')]: {
    height: '100vh',
    maxHeight: '100vh',
    borderRadius: '0',
    margin: '0',
    border: 'none',
  },
}));

const PageTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  maxHeight: '20vh',
  justifyContent: 'space-between',
  padding: '30px 50px 30px 40px',
  width: '100%',
  zIndex: 6,

  [theme.breakpoints.down('xl')]: {
    padding: '30px 50px 30px 100px',
  },

  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    top: '0',
    padding: '10px 10px 10px 30%',
    maxHeight: 'none',
    backgroundColor: '#fff',
    minHeight: '54px',
    alignItems: 'center',
  },

  h2: {
    flex: '1 1 auto',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  '&>* + *': {
    marginLeft: '20px!important',
  },
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: '10px',
    right: '10px',
  },
}));

const PageContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  padding: '0 50px 50px 40px',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    padding: '0 16px 50px',
  },
}));

export type PageAction = {
  title?: string;
  link?: string;
  onClick?(): void;
  Component?: ReactNode;
  startIcon?: ReactNode;
  variant?: 'outlined';
};

type PageProps = {
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: PageAction[];
  rightContent?: ReactNode;
  root?: boolean;
};

export const Page: FC<PropsWithChildren & PageProps> = (props) => {
  const navigate = useNavigate();
  const mobile = useMobile();

  if (!props.root)
    return (
      <PageLayout
        sx={mobile ? { paddingTop: props.subtitle ? '150px' : '70px' } : {}}
      >
        <PageTitle>
          <div style={mobile ? { width: '80%' } : {}}>
            {typeof props.title == 'string' ? (
              <Typography variant={mobile ? 'h6' : 'h2'} color='#000'>
                {props.title}
              </Typography>
            ) : (
              props.title
            )}
            {props.subtitle && typeof props.subtitle == 'string' ? (
              <Typography variant='body1' sx={mobile ? { mt: '10px' } : {}}>
                {props.subtitle}
              </Typography>
            ) : (
              props.subtitle
            )}
          </div>
          {props.rightContent}
          {props.actions?.length ? (
            <Actions>
              {mobile && (
                <PageMenu
                  isRounded
                  isIconBtn={true}
                  menuItems={[...props.actions]}
                />
              )}
              {!mobile &&
                props.actions?.map((action) => {
                  if (action.Component)
                    return (
                      <div key={Math.random() * 199}>{action.Component}</div>
                    );

                  return (
                    <Button
                      href={action.link || undefined}
                      onClick={(e) => {
                        e.preventDefault();
                        action.onClick
                          ? action.onClick()
                          : action.link && navigate(action.link);
                      }}
                      key={action.title}
                      variant={action.variant || 'contained'}
                      startIcon={action.startIcon}
                      size='large'
                    >
                      {action.title && (
                        <Typography variant='body1'>{action.title}</Typography>
                      )}
                    </Button>
                  );
                })}
            </Actions>
          ) : null}
        </PageTitle>
        <PageContent>{props.children}</PageContent>
      </PageLayout>
    );

  return (
    <Container>
      <ContainerInner>
        <CssBaseline />
        <SideBar />
        {props.children}
        <RightSideBar />
      </ContainerInner>
    </Container>
  );
};

export default Page;

import {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import { Audit, AuditsApiClient } from '../api/AuditsApiClient';
import { CommonContext } from '../contexts/CommonContext';
import { formatDateTime } from '../utils/time';
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
  timelineOppositeContentClasses,
  timelineItemClasses,
} from '@mui/lab';
import {
  auditedActionLabels,
  auditedTypeLabels,
  groupByRequestUUID,
  parsedAuditedChanges,
} from '../utils/audit';
import localize from '../localize';

import { ReactComponent as MarketingIconSvg } from '../assets/order/marketing.svg';

type AuditsContentProps = {
  auditedType: 'Order';
  auditedId: string;
};

export const AuditsContent: FC<PropsWithChildren & AuditsContentProps> = ({
  auditedType,
  auditedId,
}) => {
  const { currentProject } = useContext(CommonContext);
  const [audits, setAudits] = useState<Audit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingAudit, setFetchingAudit] = useState<string>();
  const groupedAudits = useMemo(() => groupByRequestUUID(audits), [audits]);
  const TimelineComponent = Timeline as any;

  const fetchAudit = (auditId: string) => {
    setFetchingAudit(auditId);
    AuditsApiClient.getShow(currentProject?.id, auditId)
      .then((audit) => {
        const clonnedAudits = structuredClone(audits);
        const index = audits.findIndex((a) => a.id === audit.id);
        if (index < 0) return;

        clonnedAudits[index] = audit;
        setAudits(clonnedAudits);
      })
      .finally(() => setFetchingAudit(undefined));
  };

  useEffect(() => {
    if (auditedType && auditedId) {
      setLoading(true);
      AuditsApiClient.getIndex(currentProject?.id, auditedType, auditedId)
        .then(setAudits)
        .finally(() => setLoading(false));
    }
  }, [auditedType, auditedId]);

  return (
    <Box>
      <Typography variant='h5' color='#464646'>
        {localize.orders.show.auditsContent.title}
      </Typography>
      <TimelineComponent
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {audits?.length && !loading ? (
          Object.keys(groupedAudits).map((requestId, i) => (
            <Box key={`audits-${i}`} sx={{ position: 'relative' }}>
              <Typography
                color='#0C0C0C'
                variant='subtitle2'
                sx={{
                  position: 'absolute',
                  top: '-5px',
                  left: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <MarketingIconSvg />{' '}
                {localize.orders.show.auditsContent.updatedOrder}
              </Typography>
              {groupedAudits[requestId].map((audit, ind) => (
                <TimelineItem key={`${requestId}-${ind}-${Math.random()}`}>
                  <TimelineSeparator>
                    <TimelineDot sx={{ background: '#53C17F', margin: '0' }} />
                    <TimelineConnector sx={{ background: '#53C17F' }} />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{
                      marginTop: ind === 0 ? '20px' : '-10px',
                      padding: '0 16px 10px',
                    }}
                  >
                    <Typography variant='overline'>
                      {groupedAudits[requestId][0].user?.name} -{' '}
                      {formatDateTime(groupedAudits[requestId][0].created_at)}
                    </Typography>

                    <Box sx={{ pb: 1 }}>
                      <Typography variant='caption'>
                        {auditedTypeLabels[audit.auditable_type]} -{' '}
                        {auditedActionLabels[audit.action]}
                      </Typography>
                      {parsedAuditedChanges(audit).map((change) => (
                        <Typography key={change.label}>
                          <b>{change.label}:</b>{' '}
                          {change.content?.length > 1 ? (
                            change.content.map((ch, i) => (
                              <span
                                key={`change-key-${i}`}
                                style={
                                  i === 0
                                    ? {
                                        textDecoration: 'line-through',
                                        opacity: '.5',
                                      }
                                    : {}
                                }
                              >
                                {i === 1 ? ' ' : ''}
                                {ch}
                              </span>
                            ))
                          ) : change.content[0] === '..._id' ? (
                            fetchingAudit === audit.id ? (
                              <CircularProgress size={15} />
                            ) : (
                              <Chip
                                label={'• • •'}
                                sx={{
                                  height: 'auto',
                                  bgcolor: '#434242',
                                  color: 'white',
                                  cursor: 'pointer',
                                  span: { padding: '0 10px' },
                                }}
                                disabled={!!fetchingAudit}
                                onClick={() => fetchAudit(audit.id)}
                              />
                            )
                          ) : (
                            change.content[0]
                          )}
                        </Typography>
                      ))}
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Box>
          ))
        ) : (
          <TimelineItem>
            <TimelineSeparator sx={{ posting: 'relative' }}>
              {loading ? (
                <CircularProgress
                  size={20}
                  sx={{ position: 'absolute', top: '8px' }}
                />
              ) : null}
              <TimelineDot
                sx={{
                  opacity: loading ? '.1' : '1',
                  background: '#53C17F',
                  margin: '0',
                }}
              />
              <TimelineConnector sx={{ background: '#53C17F' }} />
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant='body1'
                sx={{
                  color: '#8B8B8B',
                  fontStyle: 'italic',
                }}
              >
                {loading
                  ? localize.orders.show.auditsContent.loadData
                  : localize.orders.show.auditsContent.empty}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        )}
      </TimelineComponent>
    </Box>
  );
};

export default AuditsContent;

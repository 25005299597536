import { FC } from 'react';
import { Typography } from '@mui/material';
import localize from '../../localize';

const NotRequiredLabel: FC = () => {
  return (
    <Typography
      variant='body1'
      color='#464646'
      sx={{
        background: '#E8F5FC',
        padding: '4px 16px',
        textWrap: 'nowrap',
        borderRadius: '999px',
      }}
    >
      {localize.general.notRequired}
    </Typography>
  );
};

export default NotRequiredLabel;

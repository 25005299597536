import { FC } from 'react';
import { Order } from '../../api/OrderApiClient';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  Link,
} from '@mui/material';
import IconWithInfo from '../../components/IconWithInfo';
import { NavLink, useNavigate } from 'react-router-dom';
import DeliveryDetailsCard from './DeliveryDetailsCard';
import CustomerDetailsCard from './CustomerDetailsCard';
import localize from '../../localize';
import { StyledTypography } from './components';

type ClientDetailsCardProps = {
  order: Order;
};

export const ClientDetailsCard: FC<ClientDetailsCardProps> = ({ order }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader
        title={localize.orders.show.clientDetailsCard.title}
        subheader={
          order.user_is_client
            ? localize.orders.show.clientDetailsCard.subtitleForDrop
            : localize.orders.show.clientDetailsCard.subtitleForClient
        }
        subheaderTypographyProps={{ maxWidth: '300px' }}
        action={
          order.editable_by_current_user && (
            <NavLink
              to={`/cp/orders/${order.id}/edit/client`}
              style={{
                display: 'block',
                color: '#5B4D8F',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '17.07px',
                fontFamily: 'Montserrat, sans-serif',
              }}
            >
              {localize.general.edit}
            </NavLink>
          )
        }
      />
      <Divider sx={{ borderColor: '#DFDDF9' }} />
      <CardContent sx={{ color: '#464646' }}>
        {order.user_is_client ? (
          <>
            <StyledTypography variant='body1'>
              {localize.orders.show.clientDetailsCard.dropName}{' '}
              <Typography component='span' variant='subtitle2'>
                {order.user_name}
              </Typography>
            </StyledTypography>
            <StyledTypography variant='body1'>
              {localize.orders.show.clientDetailsCard.dropDiscount}{' '}
              <IconWithInfo
                text={localize.orders.show.clientDetailsCard.dropDiscountText}
              />
              :{' '}
              <Typography component='span' variant='subtitle2' ml='auto'>
                {order.fixed_client_discount || 0}%
              </Typography>
            </StyledTypography>
          </>
        ) : null}
        <StyledTypography variant='body1'>
          {localize.orders.show.clientDetailsCard.managerName}{' '}
          <Typography component='span' variant='subtitle2'>
            {order.user_is_client ? order.user_maneger_name : order.user_name}
          </Typography>
        </StyledTypography>
        {order.promotion_id ? (
          <StyledTypography variant='body1'>
            {localize.orders.show.clientDetailsCard.promotion}{' '}
            <Link
              variant='subtitle2'
              href={`/cp/promotions/${order.promotion_id}`}
            >
              {order.promotion_title}
            </Link>
          </StyledTypography>
        ) : null}
        <Divider sx={{ borderColor: '#DFDDF9', marginTop: '20px' }} />
        <CustomerDetailsCard order={order} />
        <DeliveryDetailsCard order={order} />
      </CardContent>
    </Card>
  );
};

export default ClientDetailsCard;

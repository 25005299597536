import { get, destroy, post, put } from './request';
import { AnyObject } from './anyObjectTypes';

export type Order = {
  editable_by_current_user?: boolean;
  files: any;
  delivery_operator: string;
  delivery_recipient_name: string;
  delivery_recipient_phone: string;
  delivery_address: string;
  delivery_ttn: string;
  sent_to_manager_at: string;
  updated_at: string;
  fixed_recommended_sum: number;
  customer_id?: string;
  user_role?: string;
  user_id?: string;
  user_auth0_id?: string;
  fixed_recommended_sum_with_discount: number;
  promotion_discount_value: number;
  customer_discount_value: number;
  promotion_id: any;
  promotion_title: string;
  sum: any;
  user_is_client: any;
  user_name: string;
  user_discount: number;
  user_maneger_name: string;
  user: {
    id: string;
    drop_legal_type: string;
    drop_legal_name: string;
    edropoy_code: string;
    iban: string;
  };
  customer_name: string;
  customer_personal_discount: number;
  customer_email: string;
  customer_phone_number: string;
  id: string;
  count: number;
  status: string;
  comment: string;
  order_items: any[];
  product_title: string;
  product_modification_title: string;
  fixed_client_discount: number;
  fixed_customer_discount: number;
  last_status: string;
  source?: string;
  additional_fields?: object;
};

export type OrderItem = {
  product_modification_id: string;
  count: string;
};

export type DeliveryKeys =
  | 'delivery_operator'
  | 'delivery_recipient_name'
  | 'delivery_recipient_phone'
  | 'delivery_address'
  | 'delivery_ttn';

export type CustomerKeys =
  | 'customer_name'
  | 'customer_email'
  | 'fixed_customer_discount'
  | 'customer_phone_number';

export type Delivery = {
  delivery_operator?: string;
  delivery_recipient_name?: string;
  delivery_recipient_phone?: string;
  delivery_address?: string;
  delivery_ttn?: string;
};

export type OrderFilterParams = {
  page: string; // page number to return - start from 1 (not 0)
  per_page?: string; // how many items per page to return
  user_id?: string; // friendly id of a user to filter by
  last_status?: string; // status name
  start_date?: string;
  end_date?: string;
  min_sum?: string;
  max_sum?: string;
};

export type OrderIndexResponse = {
  orders: Order[];
  pagination: {
    count: number;
    pages: number;
    page: number;
    next: number;
    prev: number;
  };
  stats: {
    orders_per_status: any;
    sum_per_status: any;
  };
};

export class OrderApiClient {
  static getIndex(
    projectId: string,
    filterParams?: OrderFilterParams
  ): Promise<OrderIndexResponse> {
    // const queryString = new URLSearchParams(filterParams).toString();
    // return get(`projects/${projectId}/orders?filter_enabled=true&${queryString}`);
    return get(`projects/${projectId}/orders`);
  }

  static getShow(projectId: string, itemId: string): Promise<Order> {
    return get(`projects/${projectId}/orders/${itemId}`);
  }

  static remove(projectId: string, itemId: string): Promise<string> {
    return destroy(`projects/${projectId}/orders/${itemId}`);
  }

  static create(projectId: string, requestBody: AnyObject): Promise<Order> {
    return post(`projects/${projectId}/orders`, requestBody);
  }

  static update(
    projectId: string,
    requestBody: AnyObject,
    itemId: string
  ): Promise<Order> {
    return put(`projects/${projectId}/orders/${itemId}`, requestBody);
  }
}

import { Snackbar, Alert, AlertColor } from '@mui/material';
import {
  FC,
  createContext,
  useMemo,
  useState,
  useEffect,
  PropsWithChildren,
} from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

const emptyFun = (): void => {
  return;
};

type NotificationProps = {
  setNotification: (notification: Notification) => void;
  notification?: Notification;
};

type Notification = {
  message: string;
  severity?: AlertColor;
};

export const NotificationContext = createContext<NotificationProps>({
  setNotification: emptyFun,
  notification: { severity: 'success', message: '322' },
});

export const NotificationProvider: FC<PropsWithChildren> = (props) => {
  const [notification, setNotification] = useState<Notification>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const handleClose = () => {
    setNotification(undefined);
  };

  const setColor = () => {
    const box = document.querySelector(':root') as HTMLElement;
    if (notification && box) {
      switch (notification.severity) {
        case 'success':
          box.style.setProperty('--after-color', '#BAFFA6');
          break;
        case 'warning':
          box.style.setProperty('--after-color', '#FFE37D');
          break;
        case 'error':
          box.style.setProperty('--after-color', '#FC9081');
          break;
        default:
          box.style.setProperty('--after-color', '#92C8FB');
      }
    }
  };

  useEffect(() => {
    setOpenSnackbar(!!notification);
    setColor();
  }, [notification]);

  const value = useMemo(
    () => ({
      setNotification,
      notification,
    }),
    [notification, setNotification]
  );

  return (
    <NotificationContext.Provider value={value}>
      {props.children}
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{ background: '#5B4D8F', borderRadius: '4px' }}
          id='notification_box'
        >
          <Alert
            onClose={handleClose}
            variant='outlined'
            iconMapping={{
              success: (
                <CheckCircleRoundedIcon
                  fontSize='inherit'
                  sx={{
                    color: '#BAFFA6',
                  }}
                />
              ),
              warning: (
                <WarningIcon fontSize='inherit' sx={{ color: '#FFE37D' }} />
              ),
              error: <ErrorIcon fontSize='inherit' sx={{ color: '#FC9081' }} />,
              info: (
                <InfoRoundedIcon fontSize='inherit' sx={{ color: '#92C8FB' }} />
              ),
            }}
            severity={notification?.severity}
            sx={{
              width: '100%',
              border: 'none',
              color: '#fff',
              alignItems: 'center',
            }}
          >
            {notification?.message}
          </Alert>
        </Snackbar>
      )}
    </NotificationContext.Provider>
  );
};

NotificationProvider.displayName = 'NotificationProvider';

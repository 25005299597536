import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from '@mui/material';
import { styled } from '@mui/material';
import localize from '../../localize';
import logoPlaceholder from '../../assets/onboarding/logo-placeholder.svg';

import { ImageData } from '../../utils/types';

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
    '&.purple-text-label': {
      color: '#8D83FE',
    },
  },
}));

const FileInputLabel = styled('label')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'normal',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
}));

const AvatarPlaceholder = styled('img')(() => ({
  maxWidth: '67px',
  height: '67px',
  borderRadius: '4px',
  objectFit: 'cover',
  objectPosition: 'center',
}));

const LogoImage = styled('img')(() => ({
  width: '67px',
  height: '67px',
  objectFit: 'cover',
  objectPosition: 'center',
  borderRadius: '4px',
}));

export const LogoUpload = ({
  imageData,
  setImageData,
  chooseLogoText = localize.projects.edit.main.labels.chooseLogo,
  label,
}: {
  imageData: ImageData;
  chooseLogoText?: string;
  setImageData: React.Dispatch<React.SetStateAction<ImageData>>;
  label?: string;
}) => {
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData({
          path: reader.result as string,
          files: [file],
        });
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <LabelGroup>
      {label && <label htmlFor='avatar'>{label}</label>}
      <FileInputLabel htmlFor='avatar'>
        <Box display='flex' alignItems='flex-start'>
          <Button
            variant='outlined'
            component='div'
            sx={{
              borderRadius: '4px',
              backgroundColor: '#f6f6fe',
              borderColor: '#f6f6fe',
              width: '67px',
              height: '67px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                backgroundColor: '#e8e8ff',
                borderColor: '#e8e8ff',
              },
            }}
          >
            {imageData.path ? (
              <LogoImage
                width={67}
                height={67}
                src={imageData.path}
                alt='logo'
              />
            ) : (
              <AvatarPlaceholder alt='logo placeholder' src={logoPlaceholder} />
            )}
            <input
              type='file'
              accept='image/*'
              hidden
              id='avatar'
              onChange={handleImageChange}
            />
          </Button>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              gap: '8px',
              marginLeft: '16px',
            }}
          >
            <Link
              className='purple-text-label'
              sx={{
                textTransform: 'none',
                padding: 0,
                fontSize: '12px',
                cursor: 'pointer',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: '400',
                textDecoration: 'underline',
              }}
              component='label'
              htmlFor='avatar'
            >
              {chooseLogoText}
            </Link>
            <Typography
              variant='body1'
              sx={{
                color: '#777',
                fontWeight: '400',
                maxWidth: '300px',
                fontFamily: '"Montserrat", sans-serif',
              }}
            >
              {localize.projects.edit.main.labels.chooseLogoDescription}
            </Typography>
          </Box>
        </Box>
      </FileInputLabel>
    </LabelGroup>
  );
};

import { FC, useMemo, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import localize from '../../localize';

import { ReactComponent as CoinIconSvg } from '../../assets/order/coin.svg';
import { ReactComponent as ExchangeIconSvg } from '../../assets/order/exchange.svg';
import { ReactComponent as MarketingIconSvg } from '../../assets/order/marketing.svg';
import { ReactComponent as MoneyIconSvg } from '../../assets/order/money.svg';
import { ReactComponent as RocketIconSvg } from '../../assets/order/rocket.svg';
import { ReactComponent as CompleteIconSvg } from '../../assets/order/total-complete.svg';
import { ReactComponent as CanceledIconSvg } from '../../assets/order/total-canceled.svg';

const StyledPaper = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
}));

type SmallCardForOrderProps = {
  title?: ReactNode;
  text: ReactNode;
  type: string;
};

const SmallCardForOrder: FC<SmallCardForOrderProps> = ({
  text,
  type,
  title,
}) => {
  const ImageForCard: FC = () => {
    switch (type) {
      case 'last_status':
        return <MarketingIconSvg />;
      case 'sent_to_manager_at':
        return <RocketIconSvg />;
      case 'updated_at':
        return <ExchangeIconSvg />;
      case 'fixed_recommended_sum_with_discount':
        return <CoinIconSvg />;
      case 'sum':
        return <MoneyIconSvg />;
      case 'total_pending':
        return <CoinIconSvg style={{ width: '40px', height: '40px' }} />;
      case 'total_complete':
        return <CompleteIconSvg style={{ width: '40px', height: '40px' }} />;
      case 'total_canceled':
        return <CanceledIconSvg style={{ width: '40px', height: '40px' }} />;
      default:
        return <></>;
    }
  };

  const textForCard = useMemo(() => {
    return title || localize.orders.show[type];
  }, [type]);

  return (
    <StyledPaper>
      <ImageForCard />
      <Box>
        <Typography color='#7B75CB' variant='subtitle2' mb='4px'>
          {textForCard}
        </Typography>
        <Typography color='#464646' variant='body2'>
          {text}
        </Typography>
      </Box>
    </StyledPaper>
  );
};

export default SmallCardForOrder;

import { Button, FormControl, styled, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { UserApiClient } from '../../../api/UserApiClient';
import { CommonContext } from '../../../contexts/CommonContext';
import { useOnboardingMultiStep } from '../../../contexts/OnboardingContext';
import { useAsyncError } from '../../../hooks/useAsyncError';
import localize from '../../../localize';
import { StyledInput } from '../../../components/StyledInput';
import {
  ButtonsBox,
  MainTitle,
  OnboaringPageContainer,
} from '../OnboardingPage';
import ShowStepNumbers from '../ShowStepNumbers';

const PageInnerContainer = styled('div')(() => ({
  display: 'flex',
  maxWidth: '686px',
  width: '100%',
  margin: '0 auto',
  flexDirection: 'column',
  padding: '0 20px',
  alignItems: 'center',
  justifyContent: 'center',
}));

const UserForm = styled('form')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '54px',
}));

const LabelGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
  label: {
    color: '#0c0c0c',
    fontSize: '14px',
    fontFamily: 'Montserrat, sans-serif',
    '.red-star': {
      color: '#FF0000',
    },
    '.grey-text': {
      color: '#777',
    },
  },
}));
export const DropshipperPlacementInfo = () => {
  const [formData, setFormData] = useState({
    placementInfo: '',
  });

  const { step, getTotalSteps, nextStep, prevStep } = useOnboardingMultiStep();
  const { currentUser, setCurrentUser } = useContext(CommonContext);
  const throwError = useAsyncError();

  useEffect(() => {
    if (currentUser?.auth0_id) {
      setFormData({
        placementInfo: currentUser?.drop_description,
      });
    }
  }, [currentUser]);

  const onSubmit = async () => {
    try {
      const updatedUser = await UserApiClient.updateSelf(
        {
          user: {
            drop_description: !!formData.placementInfo
              ? formData.placementInfo
              : undefined,
          },
        },
        currentUser.auth0_id
      );
      setCurrentUser({ ...currentUser, updatedUser });

      nextStep();
    } catch (e) {
      throwError(e);
    }
  };

  return (
    <OnboaringPageContainer>
      <PageInnerContainer>
        <ShowStepNumbers currentStep={step} totalSteps={getTotalSteps()} />

        <MainTitle sx={{ marginBottom: '54px' }}>
          {localize.onboarding.placementMainTitle}
        </MainTitle>
        <UserForm>
          <FormControl sx={{ width: '100%' }}>
            <LabelGroup>
              <label htmlFor='name'>
                Умови співпраці та майданчики{' '}
                <span className='grey-text'>(Не обов'язково)</span>
              </label>
              <Typography
                variant='body1'
                sx={{
                  fontSize: '12px',
                  color: '#777',
                  fontWeight: '400',
                  fontFamily: '"Montserrat", sans-serif',
                }}
              >
                {localize.onboarding.placementFormSubText}
              </Typography>
              <StyledInput
                id='name'
                value={formData.placementInfo}
                multiline
                rows={3}
                style={{ padding: '0' }}
                placeholder='Введіть текст'
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    placementInfo: event.target.value,
                  })
                }
              />
            </LabelGroup>
          </FormControl>
        </UserForm>

        <ButtonsBox>
          <Button
            variant='rounded'
            data-cy='dropshipper-placement-info-next-btn'
            onClick={onSubmit}
            sx={{
              padding: '14px 44px',
            }}
          >
            {localize.onboarding.indexPage.nextButton}
          </Button>
          <Button
            variant='transparent'
            data-cy='dropshipper-placement-info-prev-btn'
            onClick={prevStep}
          >
            {localize.onboarding.indexPage.prevButton}
          </Button>
        </ButtonsBox>
      </PageInnerContainer>
    </OnboaringPageContainer>
  );
};

import { useEffect, useState } from 'react';
import { AnyObject } from '../api/anyObjectTypes';

export const useValidateFormRequiredFields = (
  fields: AnyObject[],
  values: AnyObject
) => {
  const [isValid, setIsFormValid] = useState(false);
  useEffect(() => {
    const fieldsValid = Array.isArray(values)
      ? values.every((item: AnyObject) => {
          if (item['_destroy']) {
            return true;
          }
          return fields.every((mf) => {
            if (mf.required) {
              return item[mf.internal_name] && item[mf.internal_name] !== '';
            }
            return true;
          });
        })
      : fields.every((mf) => {
          if (mf.required) {
            return values[mf.internal_name] && values[mf.internal_name] !== '';
          }
          return true;
        });

    setIsFormValid(fieldsValid);
  }, [fields, values, values.length]);
  return isValid;
};

import { FC, ReactNode } from 'react';
import { Typography, Paper, Tooltip, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as InfoIconSvg } from '../../assets/info-circle.svg';

const PaperStyled = styled(Paper)(() => ({
  position: 'relative',
  borderRadius: '8px',
  padding: '24px 16px',
  boxShadow: '4px 8px 32px 0px #5043CF14',
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

const CustomTooltip = styled(Tooltip)(() => ({
  position: 'absolute',
  top: '24px',
  right: '16px',
}));

type MainCardProps = {
  children: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  tooltipText?: string;
  fullHeight?: boolean;
};

const MainCard: FC<MainCardProps> = ({
  children,
  title,
  subtitle,
  tooltipText,
  fullHeight,
}) => {
  return (
    <PaperStyled sx={{ height: fullHeight ? '100%' : 'fit-content' }}>
      <Typography color='#9B96EB' variant='h6'>
        {title}
      </Typography>
      {subtitle && (
        <Typography color='#777777' variant='body2'>
          {subtitle}
        </Typography>
      )}
      {tooltipText && (
        <CustomTooltip title={tooltipText} placement='top'>
          <InfoIconSvg width='24px' height='24px' fill='#9B96EB' />
        </CustomTooltip>
      )}
      <Divider sx={{ borderColor: '#DFDDF9' }} />
      {children}
    </PaperStyled>
  );
};

export default MainCard;

import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Collapse,
  Checkbox,
  Pagination,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';

import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import { formatDateTime } from '../../utils/time';
import { useMobile } from '../../hooks/useMobile';

import { ReactComponent as DeleteItemIconSvg } from '../../assets/delete-item.svg';

export type Column = {
  [key: string]: any;
};

type BasicTableProps = {
  rows: AnyObject[];
  columns: Column[];
  isCheckbox?: boolean;
  isWithPhoto?: boolean;
  onRowClick?: (row: AnyObject) => void;
  OnCollapsedRowClick?: (row: AnyObject, key?: string) => void;
  collapsedContentColumns?: AnyObject;
  isInner?: boolean;
  colorRow?: boolean;
  noPagination?: boolean;
  expandColumns?: boolean;
  dataCy?: string;
};

export const BasicTable: FC<BasicTableProps> = ({
  rows,
  columns,
  collapsedContentColumns,
  isInner,
  isCheckbox,
  isWithPhoto,
  colorRow,
  onRowClick,
  OnCollapsedRowClick,
  noPagination = false,
  expandColumns = false,
  dataCy,
}) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState<number | null>(null);
  const [width, setWidth] = useState(0);
  const mobile = useMobile();

  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set());

  const expandable = useMemo(
    () => Object.keys(collapsedContentColumns || {}).length,
    [collapsedContentColumns]
  );

  const tableMaxWidth = useMemo(
    () =>
      columns
        .map((c, j) => c.width || (c.align === 'left' || j === 0 ? 200 : 140))
        .reduce((partialSum, a) => partialSum + a, 0) +
      (expandable || isInner ? 32 : 0) +
      48,
    [columns]
  );

  const extraWidth = useMemo(
    () => (tableMaxWidth > width || isInner ? 0 : width - tableMaxWidth),
    [columns, width]
  );

  const plusWidthForLeftAligned = useMemo(
    () =>
      extraWidth /
      columns.filter((c, j) => c.align === 'left' || j === 0).length,
    [extraWidth]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setOpen(null);
  };

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      rows
        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
        .some((item) => !selectedRows.has(item.id))
    ) {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = new Set(prevSelectedRows);
        rows
          .slice((page - 1) * rowsPerPage, page * rowsPerPage)
          .forEach((item) => newSelectedRows.add(item.id));
        return newSelectedRows;
      });
    } else {
      setSelectedRows((prevSelectedRows) => {
        const newSelectedRows = new Set(prevSelectedRows);
        rows
          .slice((page - 1) * rowsPerPage, page * rowsPerPage)
          .forEach((item) => newSelectedRows.delete(item.id));
        return newSelectedRows;
      });
    }
  };

  const handleCheckboxChange = (rowIndex: string) => {
    setSelectedRows((prevSelectedRows) => {
      const newSelectedRows = new Set(prevSelectedRows);
      if (newSelectedRows.has(rowIndex)) {
        newSelectedRows.delete(rowIndex);
      } else {
        newSelectedRows.add(rowIndex);
      }
      return newSelectedRows;
    });
  };

  useEffect(() => {
    setWidth(
      document.getElementsByClassName('basic-table')[0]?.clientWidth || 0
    );
  }, [width, columns]);

  return (
    <>
      {isCheckbox && (
        <Box display='flex' alignItems='center' marginBottom='10px' gap='10px'>
          <Typography
            variant='body2'
            color='#5B4D8F'
            sx={{ textDecoration: 'underline' }}
          >{`Selected ${selectedRows.size} from ${rows.length}`}</Typography>
          <IconButton data-cy='deselect-all' sx={{ width: 40, height: 40 }}>
            <DeleteItemIconSvg />
          </IconButton>
          {/* <IconButton sx={{ width: 40, height: 40 }}>
            <DeleteItemIconSvg />
          </IconButton> */}
        </Box>
      )}
      <Box
        sx={{
          background: isInner ? '#fff' : 'none',
          margin: isInner ? '10px 5px' : '0',
        }}
        width={isInner ? tableMaxWidth + 20 : '100%'}
        className={`${isInner && '-inner'}`}
      >
        <TableContainer sx={{ padding: '0 0 10px' }} className='basic-table'>
          <Table
            sx={{ minWidth: 320, tableLayout: 'fixed' }}
            size={isInner ? 'small' : 'medium'}
            ref={tableRef}
            data-cy={dataCy ? `table-${dataCy}` : 'main-table'}
          >
            <TableHead>
              <TableRow className={isInner ? '-inner' : 'main'}>
                {!isInner && isCheckbox && (
                  <TableCell
                    key='checkbox-head'
                    data-cy='checkbox-head'
                    className='column-sticky-left'
                    sx={{
                      minWidth: 60,
                      maxWidth: 60,
                      width: 60,
                      background: '#fff',
                      left: 0,
                    }}
                  >
                    <Checkbox
                      data-cy='select-all'
                      checked={rows.every((item) => selectedRows.has(item.id))}
                      indeterminate={rows
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .every((item) => selectedRows.has(item.id))}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                )}
                {!isInner && isWithPhoto && (
                  <TableCell
                    key='photo-head'
                    data-cy='photo-head'
                    className='row-image'
                    sx={{
                      minWidth: 80,
                      maxWidth: 80,
                      width: 80,
                    }}
                  ></TableCell>
                )}
                {expandable || isInner ? (
                  <TableCell
                    data-cy='expand-head'
                    key='expand-head'
                    className='column-sticky-left'
                    colSpan={0}
                    sx={{
                      minWidth: 45,
                      maxWidth: 45,
                      width: 45,
                      left: isCheckbox ? '55px' : '0',
                      background: '#fff',
                    }}
                  />
                ) : null}

                {columns.map((c, j) => {
                  const leftAligned = c.align === 'left' || j === 0;
                  return (
                    <TableCell
                      sx={{
                        left: isCheckbox ? '100px' : expandable ? '40px' : 0,
                        borderRightColor:
                          c.field === 'title' ? 'black' : 'transparent',
                        textAlign: 'left',
                        minWidth:
                          c.width ||
                          (leftAligned
                            ? 200 + (plusWidthForLeftAligned || 0)
                            : 140),
                        maxWidth:
                          c.width ||
                          (leftAligned
                            ? 200 + (plusWidthForLeftAligned || 0)
                            : 140),
                        width:
                          c.width ||
                          (leftAligned
                            ? 200 + (plusWidthForLeftAligned || 0)
                            : 140),
                      }}
                      key={c.headerName}
                      data-cy={`${c.field}-head`}
                      align={leftAligned ? 'left' : 'right'}
                      className={c.className}
                    >
                      {c.headerName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(noPagination
                ? rows
                : rows.slice(rowsPerPage * (page - 1), page * rowsPerPage)
              ).map((row, i) => [
                <TableRow
                  key={row.id + i}
                  data-cy={`table-row-${i}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    background: i % 2 === 0 && !isInner ? '#FAF9FF' : '#fff',
                  }}
                  className={`${isInner ? '-inner' : ''} ${
                    colorRow || selectedRows.has(row.id) ? '-color-row' : ''
                  } ${open === i ? '-opened' : ''}`}
                  hover={!!onRowClick}
                >
                  {!isInner && isCheckbox && (
                    <TableCell
                      key={`checkbox-row-${row.id}`}
                      data-cy={`checkbox-row-${i}`}
                      className='column-sticky-left'
                      sx={{
                        minWidth: 60,
                        maxWidth: 60,
                        width: 60,
                        left: 0,
                      }}
                    >
                      <Checkbox
                        checked={selectedRows.has(row.id)}
                        onChange={() => handleCheckboxChange(row.id)}
                      />
                    </TableCell>
                  )}
                  {!isInner && isWithPhoto && (
                    <TableCell
                      key={`photo-row-${row.id}`}
                      data-cy={`photo-row-${i}`}
                      className='row-image'
                      sx={{
                        minWidth: 80,
                        maxWidth: 80,
                        width: 80,
                      }}
                    >
                      <Box width={80} height={100}>
                        <img
                          src={
                            row.additional_fields.preview_images
                              ? row.additional_fields.preview_images[0].url
                              : '/logo.svg'
                          }
                          alt={row.title}
                          style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                            display: 'block',
                          }}
                        />
                      </Box>
                    </TableCell>
                  )}

                  {expandable ? (
                    <TableCell
                      key={`expand-row-${i}`}
                      data-cy={`expand-row-${i}`}
                      className='column-sticky-left'
                      sx={{
                        minWidth: 45,
                        maxWidth: 45,
                        width: 45,
                        padding: '0 0 0 5px',
                        left: isCheckbox ? '55px' : '0',
                      }}
                    >
                      {!row['hide_collapsed'] ? (
                        <IconButton
                          aria-label='expand row'
                          data-cy={`expand-${i}`}
                          size='small'
                          onClick={(e): void => {
                            e.stopPropagation();
                            setOpen(open === i ? null : i);
                          }}
                        >
                          {open === i ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      ) : null}
                    </TableCell>
                  ) : isInner ? (
                    <TableCell
                      key={`expand-row-${i}`}
                      data-cy={`expand-row-${i}`}
                      sx={{ minWidth: 32, maxWidth: 32, width: 32 }}
                    />
                  ) : null}
                  {columns.map((c, j) => {
                    const leftAligned = c.align === 'left' || j === 0;
                    return (
                      <TableCell
                        key={`${c.field}-${i}`}
                        data-cy={`${c.field}-${i}`}
                        onClick={() => {
                          c.className !== 'column-sticky-right' &&
                            onRowClick &&
                            onRowClick(row);
                        }}
                        align={
                          leftAligned || c.type === 'text' ? 'left' : 'right'
                        }
                        sx={{
                          left: isCheckbox ? '100px' : expandable ? '40px' : 0,
                          textAlign: 'left',
                        }}
                        className={`${c.className ? c.className : ''} ${expandColumns ? 'expand-column' : ''}`}
                      >
                        {!!c.renderCell ? (
                          c.renderCell(row)
                        ) : c.valueGetter ? (
                          <Typography
                            variant='body1'
                            sx={{ whiteSpace: 'pre-line' }}
                          >
                            {typeof c.valueGetter(row) === 'boolean'
                              ? c.valueGetter(row)
                                ? localize.general.true
                                : localize.general.false
                              : c.valueGetter(row)}
                          </Typography>
                        ) : c.type === 'boolean' ? (
                          <Typography variant='body1'>
                            {row[c.field]
                              ? localize.general.true
                              : localize.general.false}
                          </Typography>
                        ) : c.type === 'datetime' && row[c.field] ? (
                          <Typography variant='body1'>
                            {formatDateTime(row[c.field])}
                          </Typography>
                        ) : (
                          <Typography variant='body1'>
                            {row[c.field]}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>,
                collapsedContentColumns && !row['hide_collapsed'] ? (
                  <TableRow
                    key={`${row.id + i}-collapsed`}
                    className={`${row.id}-collapsed`}
                    data-cy={`${row.id}-collapsed`}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      background: '#F0F0F0',
                    }}
                  >
                    <TableCell
                      sx={{
                        padding: '0 20px',
                        border: 0,
                        minWidth: '100%',

                        '& .-inner': {
                          width: '100%',
                          borderRadius: '4px',
                          margin: '0',
                        },
                      }}
                      colSpan={columns.length + 1}
                      data-cy={`cell-collapsed-${i}`}
                    >
                      <Collapse in={i === open} timeout='auto' unmountOnExit>
                        <br />
                        {Object.keys(collapsedContentColumns).map(
                          (key, ind) => {
                            return (
                              <BasicTable
                                columns={collapsedContentColumns[key]}
                                key={`${row.id}-bt-${key + ind}-collapsable`}
                                dataCy={`cell-collapsed-${i}-${key}`}
                                rows={
                                  Array.isArray(row[key])
                                    ? row[key]
                                    : [row[key]]
                                }
                                onRowClick={(r): void => {
                                  OnCollapsedRowClick &&
                                    OnCollapsedRowClick(r, key);
                                }}
                                noPagination
                                isInner
                              />
                            );
                          }
                        )}
                        <br />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                ) : null,
              ])}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          mt='10px'
          sx={{ flexWrap: 'wrap', gap: '10px' }}
        >
          {rows.length > rowsPerPage && !noPagination ? (
            <>
              <FormControl
                sx={{
                  display: 'flex',
                  gap: '10px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  m: mobile ? '0 auto' : '0',
                }}
              >
                <Typography color='#000000' variant='body2'>
                  {localize.general.tableRowPerPage}
                </Typography>
                <Select
                  value={rowsPerPage}
                  sx={{
                    '&>div': {
                      width: '25px',
                      padding: '5px 0 5px 10px',
                    },
                  }}
                  onChange={(e) => {
                    setRowsPerPage(+e.target.value);
                  }}
                >
                  {[10, 15, 20, 25, 30].map((number, ind) => (
                    <MenuItem
                      key={number}
                      data-cy={`rows-per-page-${number}`}
                      value={number}
                      sx={{
                        '&.Mui-selected, &.Mui-selected:hover': {
                          background: '#AFABEF',
                        },
                        borderLeft: '1px solid #DFDDF9',
                        borderRight: '1px solid #DFDDF9',
                        borderTop: ind === 0 ? '1px solid #DFDDF9' : 'none',
                        borderBottom: '1px solid #DFDDF9',
                      }}
                    >
                      {number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Pagination
                count={Math.ceil(rows.length / rowsPerPage)}
                page={page}
                siblingCount={mobile ? 0 : 1}
                sx={mobile ? { padding: '2px', m: '0 auto' } : {}}
                data-cy='pagination'
                onChange={handleChangePage}
                color='primary'
              />
            </>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

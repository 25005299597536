import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Button, CssBaseline, Link, Typography } from '@mui/material';
import pinkBlobMain from '../../../assets/landing/pinkBlob.svg';
import creamBlobMain from '../../../assets/landing/creamBlob.svg';
import saly1 from '../../../assets/landing/salyFirst.png';
import doodle1 from '../../../assets/landing/doodle1.png';
import doodle2 from '../../../assets/landing/doodle2.png';
import doodle3 from '../../../assets/landing/doodle3.png';
import doodle4 from '../../../assets/landing/doodle4.png';
import TabletHeroBg from '../../../assets/landing/hero-mobile-bg.svg';
import localize from '../../../localize';
import { ReactComponent as HeroTextLine } from '../../../assets/landing/line1.svg';
import MobileSallyHero from '../../../assets/landing/sally-mobile-hero.png';
import { Header } from './Header';
import { ButtonTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const HeroContainer = styled('div')(({ theme }) => ({
  fontFamily: 'Montserrat, Rubik, sans-serif',
  width: '100%',
  position: 'relative',
  height: '100vh',
  color: '#0c0c0c',
  background: `url(${saly1}) right 20% top 3% no-repeat,
  url(${doodle2}) left 45% top 10% no-repeat,
  url(${pinkBlobMain}) no-repeat right top / 900px,
  url(${creamBlobMain}) no-repeat center top / 900px,
  url(${doodle4}) no-repeat right 5% top 5%,
  url(${doodle3}) no-repeat center bottom 5%,
  url(${doodle1}) right 40% top 36% no-repeat
  `,

  [theme.breakpoints.down('md')]: {
    background: `
  url(${saly1}) left 20% top -15% no-repeat,
  url(${doodle2}) left 45% top 20% no-repeat,
  url(${pinkBlobMain}) no-repeat right top / 900px,
  url(${creamBlobMain}) no-repeat center top / 900px,
  url(${doodle4}) no-repeat right 5% top 5%,
  url(${doodle3}) no-repeat center bottom 5%,
  url(${doodle1}) right 40% top 36% no-repeat
  `,
  },
  [theme.breakpoints.down('sm')]: {
    backgroundImage: `url(${TabletHeroBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
  },
}));

const ContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 20px',
  width: '100%',
  maxWidth: '1280px',
  minWidth: '320px',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}));

const MainScreen = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: 'calc(100% - 125px)',
  position: 'relative',
  flexDirection: 'column',
  marginTop: 'auto',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: 'calc(100% - 100px)',
  },
}));

const MobileHeroImg = styled('img')(({ theme }) => ({
  position: 'relative',
  width: '146px',
  height: 'auto',
  display: 'none',
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

const MainScreenTextContent = styled('div')(({ theme }) => ({
  lineHeight: 1.2,
  fontWeight: 400,
  display: 'flex',
  rowGap: '40px',
  flexDirection: 'column',
  maxWidth: '495px',
  width: '100%',

  p: {
    marginTop: '20px',
    lineHeight: '20px',
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      maxWidth: '495px',
      marginTop: '0px',
    },
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    rowGap: '40px',
  },
  [theme.breakpoints.down('sm')]: {
    rowGap: '10vw',
  },
}));

const MainScreenTitle = styled(Typography)(({ theme }) => ({
  fontSize: '70px',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    fontSize: '40px',
    lineHeight: '50px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',
    lineHeight: '36px',
  },
}));

const AccentWord = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'block',
  position: 'relative',
  textTransform: 'lowercase',
  fontWeight: 700,
  [theme.breakpoints.down('md')]: {
    textTransform: 'none',
  },
}));

const MainScreenTitleContent = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'normal',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    gap: '15px',
    justifyContent: 'flex-start',
  },
  '@media (max-width: 320px)': {
    justifyContent: 'center',
    gap: 'auto',
  },

  svg: {
    [theme.breakpoints.down('md')]: {
      width: '120px',
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90px',
      height: 'auto',
    },
  },
}));

const MainScreenTitleWrapperText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
}));

const HeroTextLineStyled = styled(HeroTextLine)(({ theme }) => ({
  position: 'relative',
  transform: 'translateY(-8px)',
  [theme.breakpoints.down('sm')]: {
    transform: 'translateY(-6px)',
  },
}));

const HeroButton: OverridableComponent<ButtonTypeMap> = styled(Button)(
  ({ theme }) => ({
    alignSelf: 'flex-start',
    [theme.breakpoints.down('md')]: {
      alignSelf: 'initial',
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  })
);

export const Hero: FC = () => {
  return (
    <HeroContainer>
      <CssBaseline />
      <Header />
      <MainScreen>
        <ContainerInner>
          <MainScreenTextContent>
            <MainScreenTitleContent>
              <MainScreenTitleWrapperText>
                <MainScreenTitle variant='h1'>
                  {localize.landing.heading}
                  <AccentWord>{localize.landing.headingWord}</AccentWord>
                </MainScreenTitle>
                <HeroTextLineStyled />
              </MainScreenTitleWrapperText>
              <MobileHeroImg src={MobileSallyHero} alt='sally' />
            </MainScreenTitleContent>
            <Typography component={'p'} variant='body1'>
              {localize.landing.subText}
            </Typography>
            <HeroButton component={Link} href='/cp' variant='rounded'>
              {localize.landing.headCTA}
            </HeroButton>
          </MainScreenTextContent>
        </ContainerInner>
      </MainScreen>
    </HeroContainer>
  );
};

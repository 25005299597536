import { ListItem, styled, Typography } from '@mui/material';
import localize from '../../../localize';
import simplicity from '../../../assets/landing/offers/simplicity.png';
import integration from '../../../assets/landing/offers/integration.png';
import statistics from '../../../assets/landing/offers/statistics.png';
import flexibility from '../../../assets/landing/offers/flexibility.png';

const OffersSectionContainer = styled('section')(({ theme }) => ({
  padding: '3% 0',
  color: '#0c0c0c',
}));

const OffersSectionInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 20px',
  justifyContent: 'normal',
  alignItems: 'center',
  maxWidth: '1280px',
  width: '100%',
  minWidth: '320px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
    margin: '0',
  },
}));

const OffersTitle = styled('h2')(({ theme }) => ({
  color: '#0c0c0c',
  alignSelf: 'flex-start',
  fontSize: '34px',
  fontWeight: '500',
  margin: '30px 0',
  fontFamily: '"Montserrat", sans-serif',
  [theme.breakpoints.down('md')]: {
    margin: '24px 0',
    fontSize: '20px',
  },
}));

const OffersList = styled('ul')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gap: '24px',
  justifyContent: 'normal',
  alignItems: 'stretch',
  padding: 0,
  margin: 0,
  listStyle: 'none',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    width: '100%',
  },

  '& li': {
    display: 'flex',
    gap: '32px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '24px',
    boxShadow: '0px 4px 20px 0px rgba(198, 183, 255, 0.3)',
    height: '100%',
    padding: '24px 20px 48px 20px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

    '& h4': {
      fontWeight: 700,
      fontSize: '16px',
      color: '#7B75CB',
      fontFamily: '"Montserrat", sans-serif',
    },

    '& img': {
      maxWidth: '137px',
      [theme.breakpoints.down('md')]: {
        maxWidth: '88px',
      },
    },

    '& p': {
      textAlign: 'center',
    },
  },
}));

const ImgItemWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  height: '100%',
  maxHeight: '137px',
}));

const ImgItem = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
}));

const offersImg = [
  {
    path: simplicity,
  },
  {
    path: integration,
  },
  {
    path: statistics,
  },
  {
    path: flexibility,
  },
];

const offersSectionLoc: { title: string; description: string }[] =
  localize.landing.offersSection.items;

const offersSectionData = offersSectionLoc.map((item, index) => ({
  ...item,
  path: offersImg[index].path,
}));

export const OffersSection = () => {
  return (
    <OffersSectionContainer>
      <OffersSectionInner>
        <OffersTitle>{localize.landing.offersSection.title}</OffersTitle>
        <OffersList>
          {offersSectionData.map(({ title, description, path }) => (
            <ListItem sx={{ padding: 0 }} key={title}>
              <ImgItemWrapper>
                <ImgItem src={path} alt={title} />
              </ImgItemWrapper>

              <Typography
                variant='h4'
                sx={{
                  marginTop: 'auto',
                }}
              >
                {title}
              </Typography>
              <Typography
                variant='body1'
                component={'p'}
                sx={{
                  maxWidth: '343px',
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: '12px',
                }}
              >
                {description}
              </Typography>
            </ListItem>
          ))}
        </OffersList>
      </OffersSectionInner>
    </OffersSectionContainer>
  );
};

import React, { useState, useEffect } from 'react';

import {
  InputBase,
  Paper,
  styled,
  IconButton,
  FormControlLabel,
  Switch,
} from '@mui/material';

import localize from '../../localize';
import { useMobile } from '../../hooks/useMobile';
import SingleInputDateRangePicker from '../SingleInputDateRangeField/SingleInputDateRangeField';

import { ReactComponent as SearchIconSvg } from '../../assets/search.svg';
import { ReactComponent as FilterIconSvg } from '../../assets/filter.svg';
import { ReactComponent as CheckboxIconSvg } from '../../assets/checkbox.svg';
import { useLocation } from 'react-router-dom';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '30px',
  flexWrap: 'wrap',
  gap: '10px 20px',
  [theme.breakpoints.down('md')]: {
    gap: '10px',
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  borderRadius: '8px',
  border: '1px solid #DFDDF9',
  '&.active': {
    backgroundColor: '#7B75CB',
  },
  '&:hover, &:focus': {
    border: '1px solid #7B75CB',
  },
}));

interface SearchBarProps {
  onSearch: (searchQuery: string) => void;
  onFilterButtonClick: () => void;
  anyFiltersApplied?: boolean;
  onCheckboxButtonClick?: () => void;
  onChangeWithPhoto?: (isWithPhoto: boolean) => void;
  onSearchByDateRange?: (value: { from?: number; to?: number }) => void;
  dateRange?: { from?: number; to?: number; internal_name?: string };
  isWithPhoto?: boolean;
  isCheckbox?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  onFilterButtonClick,
  anyFiltersApplied,
  onCheckboxButtonClick,
  onChangeWithPhoto,
  onSearchByDateRange,
  dateRange,
  isCheckbox,
  isWithPhoto,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const mobile = useMobile();
  const location = useLocation();
  const locationSlug = location.pathname.split('/')[2];

  useEffect(() => {
    return () => {
      onSearch('');
      setSearchQuery('');
    };
  }, []);

  useEffect(() => {
    !anyFiltersApplied && setSearchQuery('');
  }, [anyFiltersApplied]);

  return (
    <Root>
      <Paper
        data-cy='search-bar'
        component='form'
        sx={{
          height: 40,
          display: 'flex',
          alignItems: 'center',
          width: mobile ? '100%' : 228,
          backgroundColor: '#fff',
          borderRadius: '8px',
          border: '1px solid #DFDDF9',
          '&:hover, &:focus': {
            border: '1px solid #AFABEF',
          },
        }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <IconButton
          type='button'
          aria-label='search'
          data-cy='search-icon-btn'
          sx={{ p: '8px' }}
        >
          <SearchIconSvg />
        </IconButton>
        <InputBase
          sx={{ flex: 1 }}
          placeholder={localize.sidebar.search}
          data-cy={`search-bar-input-${locationSlug}`}
          name={localize.sidebar.search}
          value={searchQuery}
          onChange={(event) => {
            setSearchQuery(event.target.value);
            onSearch(event.target.value);
          }}
        />
      </Paper>
      <StyledIconButton
        type='button'
        aria-label='filter'
        onClick={onFilterButtonClick}
        className={anyFiltersApplied ? 'active' : ''}
      >
        <FilterIconSvg
          id='filter_btn'
          data-cy='filter-button'
          fill={anyFiltersApplied ? '#fff' : '#464646'}
        />
      </StyledIconButton>
      {/* <StyledIconButton
        type='button'
        aria-label='checkbox'
        sx={{ mr: 'auto' }}
        onClick={onCheckboxButtonClick}
        className={isCheckbox ? 'active' : ''}
      >
        <CheckboxIconSvg fill={isCheckbox ? '#fff' : '#464646'} />
      </StyledIconButton>
      <FormControlLabel
        control={
          <Switch
            checked={isWithPhoto}
            onChange={() => {
              onChangeWithPhoto && onChangeWithPhoto(!isWithPhoto);
            }}
          />
        }
        label={
          isWithPhoto
            ? localize.products.general.withPhoto
            : localize.products.general.withoutPhoto
        }
      /> */}
      {onSearchByDateRange && dateRange && (
        <SingleInputDateRangePicker
          data-cy={`search-bar-date-range-${locationSlug}`}
          onCheckDateRange={(value) => onSearchByDateRange(value)}
          dateRange={dateRange}
        />
      )}
    </Root>
  );
};

export default SearchBar;

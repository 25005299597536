import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

import Page from '../../components/Page/Page';
import { NotificationContext } from '../../contexts/NotificationContext';
import { CommonContext } from '../../contexts/CommonContext';
import { TemplateApi } from '../../api/TemplateApi';
import Loading from '../../components/Loading/Loading';
import { AnyObject } from '../../api/anyObjectTypes';
import localize from '../../localize';
import TemplateForm from '../../components/TemplateForm/TemplateForm';
import HorizontalScrollWrapper from '../../components/HorizontalScrollWrapper/HorizontalScrollWrapper';

const ContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
}));

export const TemplateEditPage: FC = () => {
  const { type } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setNotification } = useContext(NotificationContext);
  const [template, setTemplate] = useState<AnyObject>({});
  const { currentProject } = useContext(CommonContext);
  const navigate = useNavigate();
  const templateType = useMemo(() => {
    switch (type) {
      case 'product':
        return 'Product';
      case 'product_modification':
        return 'Product modification';
      case 'order':
        return 'Order';
      case 'order_item':
        return 'Order item';
    }
  }, [type]);
  const tabsData = useMemo(() => {
    switch (type) {
      case 'product':
      case 'product_modification':
        return [
          { title: 'Product', key: 'product' },
          { title: 'Product modification', key: 'product_modification' },
        ];
      case 'order':
      case 'order_item':
        return [
          { title: 'Order', key: 'order' },
          { title: 'Order item', key: 'order_item' },
        ];
    }
  }, [type]);

  const onSubmit = (customFields: AnyObject[]) => {
    setSubmitting(true);
    TemplateApi.update(
      currentProject.id,
      {
        template: {
          custom_fields_attributes: customFields.map((field) => {
            if (typeof field.options === 'string')
              field.options = field.options?.split('\n');
            return field;
          }),
        },
      },
      template?.id
    )
      .then(() => {
        setNotification({
          severity: 'success',
          message: localize.general.successUpdateMessage,
        });
        setSubmitting(false);
        window.location.href = '/cp/products';
      })
      .catch((error) => {
        setSubmitting(false);
        setNotification({
          severity: 'error',
          message: localize.general.unexpectedError,
        });
      });
  };

  useEffect(() => {
    if (!currentProject?.id) return;
    setLoading(true);
    TemplateApi.getIndex(currentProject?.id)
      .then((res) => {
        setTemplate(res.filter((el) => el.template_type === templateType)[0]);
      })
      .finally(() => setLoading(false));
  }, [currentProject?.id, templateType]);

  if (loading)
    return (
      <Page>
        <Loading />
      </Page>
    );

  return (
    <Page title={localize.templates.edit.title} actions={[]}>
      <ContainerInner>
        <HorizontalScrollWrapper className='MuiTabs-scroller'>
          <Tabs value={templateType}>
            {tabsData?.map((tab) => (
              <Tab
                key={tab.key}
                value={tab.title}
                label={localize.templates.tabs[tab.key]}
                onClick={() => navigate(`/cp/templates/edit/${tab.key}`)}
              />
            ))}
          </Tabs>
        </HorizontalScrollWrapper>
        <br />
        <br />
        <TemplateForm
          template={template}
          submitting={submitting}
          description={localize.templates.edit.description[type || '']}
          onSubmit={onSubmit}
        />
      </ContainerInner>
    </Page>
  );
};

export default TemplateEditPage;

import React, { FC, Fragment, useMemo } from 'react';
import {
  Image,
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  Font,
  Svg,
} from '@react-pdf/renderer';
import logo from '../../assets/blobBigMain.png';
import { Order } from '../../api/OrderApiClient';
import { formatDate } from '../../utils/time';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf' },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf',
      fontWeight: 600,
    },
  ],
  format: 'truetype',
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  section: {},
  column: { flexDirection: 'column' },
  row: { flexDirection: 'row' },
  block: { minWidth: '50%' },
  pt: { paddingTop: '20px' },
  text: { fontSize: 11 },
  emptyLine: { minHeight: '30px' },

  spaceBetween: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: { flexDirection: 'row', marginTop: 24 },
  logo: { width: 90 },
  reportTitle: { fontSize: 16, textAlign: 'center' },
  addressTitle: { fontSize: 11 },
  invoice: { fontWeight: 600, fontSize: 20 },
  invoiceNumber: { fontSize: 11, fontWeight: 600 },
  address: { fontSize: 10 },
  theader: {
    marginTop: 20,
    fontSize: 10,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    height: 20,
    backgroundColor: '#DEDEDE',
    borderColor: 'whitesmoke',
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  theader2: { flex: 2, borderBottomWidth: 1 },
  theader3: { maxWidth: '70px', borderBottomWidth: 1 },
  theader4: { minWidth: '180px', borderBottomWidth: 1 },

  tbody: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    borderColor: 'whitesmoke',
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  total: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1.5,
    borderColor: 'whitesmoke',
    borderBottomWidth: 1,
  },
  tbody2: { flex: 2, borderRightWidth: 1 },
  tbody3: { maxWidth: '70px', borderRightWidth: 1 },
  tbody4: { minWidth: '180px', borderRightWidth: 1 },
});

export const CompletedOrders: FC<{ orders: Order[] }> = ({ orders }) => {
  const data = useMemo(() => {
    const sortedOrders = [...orders].sort((a, b) =>
      b.user_name.localeCompare(a.user_name)
    );

    const groupedOrders = sortedOrders.reduce(
      (acc, order) => {
        if (!acc[order.user_name]) {
          acc[order.user_name] = [];
        }
        acc[order.user_name].push(order);
        return acc;
      },
      {} as { [key: string]: Order[] }
    );

    return Object.entries(groupedOrders).map(([name, orders]) => ({
      totalSum: orders.reduce(
        (sum, order) =>
          sum + parseFloat(order.fixed_recommended_sum.toString()),
        0
      ),
      totalQty: orders.reduce(
        (sum, order) =>
          sum + order.order_items.reduce((a, b) => a + b.count, 0),
        0
      ),
      name,
      orders: orders
        .map((order) => ({
          id: order.id,
          date: formatDate(new Date(order.updated_at).toString()),
          items: order.order_items.map(
            ({
              id,
              count,
              product,
              product_modification_title,
              fixed_price,
            }) => ({
              id,
              desc: [
                product?.additional_fields?.secondaryid,
                product?.additional_fields?.n_collection,
                product?.title,
                product_modification_title,
              ]
                .filter((f) => !!f)
                .join(' - '),
              qty: count,
              price: parseInt(fixed_price.toString()),
            })
          ),
          sum: parseFloat(`${order.fixed_recommended_sum}`).toFixed(2),
          discount: (order.fixed_recommended_sum - order.sum).toFixed(2),
          last_updated_at: formatDate(new Date(order.updated_at).toString()),
        }))
        .sort(
          (a, b) =>
            new Date(a.last_updated_at).getTime() -
            new Date(b.last_updated_at).getTime()
        ),
    }));
  }, [orders]);

  const TableHead = () => (
    <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
      <View style={[styles.theader, styles.theader2]}>
        <Text>Дропшиппер</Text>
      </View>
      <View style={styles.theader}>
        <Text>Номер</Text>
      </View>
      <View style={[styles.theader, styles.theader3]}>
        <Text>Дата</Text>
      </View>
      <View style={[styles.theader, styles.theader4]}>
        <Text>Назва продукту та модифікації</Text>
      </View>
      <View style={styles.theader}>
        <Text>Кількість</Text>
      </View>
      <View style={styles.theader}>
        <Text>Ціна</Text>
      </View>
      <View style={styles.theader}>
        <Text>Сума</Text>
      </View>
    </View>
  );

  const TableBody = () => (
    <>
      {data.map(({ name, orders, totalSum, totalQty }) => (
        <Fragment key={name}>
          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={[styles.tbody, styles.tbody2]}>
              <Text>{name}</Text>
            </View>
            <View style={styles.tbody}>
              <Text></Text>
            </View>
            <View style={[styles.tbody, styles.tbody3]}>
              <Text></Text>
            </View>
            <View style={[styles.tbody, styles.tbody4]}>
              <Text></Text>
            </View>
            <View style={styles.tbody}>
              <Text></Text>
            </View>
            <View style={styles.tbody}>
              <Text></Text>
            </View>
            <View style={styles.tbody}>
              <Text></Text>
            </View>
          </View>
          {orders.map((or, index) => (
            <Fragment key={or.id}>
              {or.items.map((item, idx) => (
                <View
                  style={{ width: '100%', flexDirection: 'row' }}
                  key={item.id}
                >
                  <View style={{ width: '100%', flexDirection: 'row' }}>
                    <View style={[styles.tbody, styles.tbody2]}>
                      <Text></Text>
                    </View>
                    <View style={styles.tbody}>
                      <Text>{idx === 0 ? or.id : ''}</Text>
                    </View>
                    <View style={[styles.tbody, styles.tbody3]}>
                      <Text>{idx === 0 ? or.last_updated_at : ''}</Text>
                    </View>
                    <View style={[styles.tbody, styles.tbody4]}>
                      <Text>{item.desc}</Text>
                    </View>
                    <View style={styles.tbody}>
                      <Text>{item.qty}</Text>
                    </View>
                    <View style={styles.tbody}>
                      <Text>{item.price}</Text>
                    </View>
                    <View style={styles.tbody}>
                      <Text></Text>
                    </View>
                  </View>
                </View>
              ))}
              <View style={{ width: '100%', flexDirection: 'row' }}>
                <View style={[styles.tbody, styles.tbody2]}>
                  <Text></Text>
                </View>
                <View style={styles.tbody}>
                  <Text></Text>
                </View>
                <View style={[styles.tbody, styles.tbody3]}>
                  <Text></Text>
                </View>
                <View style={[styles.tbody, styles.tbody4]}>
                  <Text></Text>
                </View>
                <View style={styles.tbody}>
                  <Text></Text>
                </View>
                <View style={styles.tbody}>
                  <Text></Text>
                </View>
                <View style={styles.tbody}>
                  <Text>{parseInt(or.sum.toString())}</Text>
                </View>
              </View>
            </Fragment>
          ))}
          <View style={{ width: '100%', flexDirection: 'row' }}>
            <View style={[styles.tbody, styles.tbody2]}>
              <Text></Text>
            </View>
            <View style={styles.tbody}>
              <Text>Загалом</Text>
            </View>
            <View style={[styles.tbody, styles.tbody3]}>
              <Text></Text>
            </View>
            <View style={[styles.tbody, styles.tbody4]}>
              <Text></Text>
            </View>
            <View style={styles.tbody}>
              <Text>{totalQty}</Text>
            </View>
            <View style={styles.tbody}>
              <Text></Text>
            </View>
            <View style={styles.tbody}>
              <Text>{totalSum}</Text>
            </View>
          </View>
        </Fragment>
      ))}
    </>
  );

  const EmptyLine = () => <View style={styles.emptyLine}></View>;

  return (
    <Document>
      <Page size='A4' orientation='landscape' style={styles.page}>
        <TableHead />
        <TableBody />
      </Page>
    </Document>
  );
};

export default CompletedOrders;

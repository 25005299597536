import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { styled } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Divider,
} from '@mui/material';
import FormField from '../FormField/FormField';
import { ReactComponent as DeleteIcon } from '../../assets/delete-item.svg';
import { ReactComponent as InfoIconSvg } from '../../assets/info-circle.svg';
import { AnyObject } from '../../api/anyObjectTypes';
import AddIcon from '@mui/icons-material/Add';
import localize from '../../localize';
import { getRandomHexSegment } from '../../utils/strings';
import { isDeepEqual } from '../../utils/isDeepEqual';
import { useMobile } from '../../hooks/useMobile';

const Container = styled('form')(({ theme }) => ({
  width: '100%',
}));

const SubItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',

  '& + &': {
    paddingTop: '20px',
  },
}));

const SubItemInnerContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  borderRadius: '10px',
  padding: '30px',
  border: '1px solid #DFDDF9',
  [theme.breakpoints.down('md')]: {
    padding: '30px 10px',
  },
}));

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '20px',
  padding: '10px 30px 10px 10px',
  gap: '20px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '10px',
  },
}));

const AddSubItemContainer = styled('div')(({ theme }) => ({
  paddingTop: '40px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
}));

const SubItemTopContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  flex: '1 1 auto',

  '&>div': {
    '&.actions': {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '33%',
      minWidth: '200px',
      '&.actions': {
        width: 'fit-content',
      },
    },
  },
}));

const SubItemBottomContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  '&>div': {
    '&.actions': {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '33%',
      minWidth: '200px',
      '&.actions': {
        width: 'fit-content',
      },
    },
  },
}));

type TemplateFormProps = {
  template: AnyObject;
  submitting?: boolean;
  description?: ReactNode;
  onSubmit(customFields: AnyObject[]): void;
};

const FIELD_TYPES = [
  'text',
  'string',
  'number',
  'boolean',
  'select',
  'static',
  'files',
];
const defaultFieldsInfo = [
  {
    internal_name: 'field_type',
    field_type: 'string',
    required: true,
    label: localize.templates.form.labels.type,
    options: FIELD_TYPES,
  },
  {
    internal_name: 'label',
    field_type: 'string',
    required: true,
    label: localize.templates.form.labels.name,
  },
  {
    internal_name: 'internal_name',
    field_type: 'string',
    required: true,
    label: localize.templates.form.labels.key,
  },
  {
    internal_name: 'required',
    field_type: 'boolean',
    required: false,
    label: localize.templates.form.labels.required,
  },
];

export const TemplateForm: FC<TemplateFormProps> = ({
  submitting,
  template,
  description,
  onSubmit,
}) => {
  const [defaultFields, setDefaultFields] = useState<AnyObject[]>([]);
  const [items, setItems] = useState<AnyObject[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const mobile = useMobile();

  useEffect(() => {
    if (!template) return;

    setItems(template.custom_fields);

    setDefaultFields(
      defaultFieldsInfo.map((el) => ({
        ...el,
        editable: true,
        hidden: false,
        parsable: false,
      }))
    );
  }, [template]);

  const validateForm = useCallback((fields: AnyObject[]): boolean => {
    for (const field of fields) {
      if (field.field_type === 'boolean' && !field.required) continue;

      if (field['_destroy']) continue;

      if (field.field_type === 'select') {
        if (!Array.isArray(field.options) || field.options.length === 0) {
          return false;
        }
      }
      if (FIELD_TYPES.includes(field.field_type) && field.label.trim() === '') {
        return false;
      }
      if (field.internal_name) {
        const isValid = /^[a-z][a-z0-9]*(?:_[a-z0-9]+)*$/.test(
          field.internal_name
        );
        if (!isValid) {
          return false;
        }
      }
    }

    return true;
  }, []);

  useEffect(() => {
    const validForm = validateForm(items);
    setIsFormValid(validForm);
  }, [items, validateForm]);

  const getActiveItems = (fields: AnyObject[]) =>
    fields.filter((item) => !item._destroy);

  const hasFormChanged = useMemo(() => {
    const activeTemplateFields = getActiveItems(template.custom_fields || []);
    const activeItems = getActiveItems(items);

    return !isDeepEqual(activeTemplateFields, activeItems);
  }, [template.custom_fields, items]);

  const handleItemInputChange = (
    e: { target: { name: any; value: any } },
    itemIndex: number
  ) => {
    const { name, value } = e.target;
    const newSubItems = items.slice();
    newSubItems[itemIndex] = { ...newSubItems[itemIndex], [name]: value };
    setItems(newSubItems);
    console.log(value, name, itemIndex);
  };

  const deleteSubItem = (itemIndex: number) => {
    const newSubItems = items.map((item, index) =>
      index === itemIndex ? { ...item, _destroy: true } : item
    );
    setItems(newSubItems);
  };

  const duplicateItem = (itemIndex: number) => {
    const newItem = {
      ...items[itemIndex],
      label: '',
      internal_name: generateRandomInternalKey(),
      id: null,
      required: false,
      hidden: false,
      editable: true,
    };

    const newItems = [...items];
    newItems.splice(itemIndex + 1, 0, newItem);

    setItems(newItems);
  };

  const generateRandomInternalKey = () => {
    return `access_key_${getRandomHexSegment().slice(0, 4)}`;
  };

  const isValidKeyInput = useCallback((value: string) => {
    const regex = /^[a-z][a-z0-9]*(?:_[a-z0-9]+)*$/;
    return regex.test(value);
  }, []);

  const internal_name_tooltip = (
    <>
      {
        <Tooltip title={localize.templates.form.keyTooltip} placement='top'>
          <InfoIconSvg
            width='24px'
            height='24px'
            fill='#9B96EB'
            style={{
              margin: '0 5px -5px 0',
              position: 'absolute',
              right: '0',
              top: '0',
            }}
          />
        </Tooltip>
      }
      {localize.templates.form.labels.key}
    </>
  );

  return (
    <Container
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(items);
      }}
    >
      <Alert
        severity='info'
        sx={{ mb: '32px', width: '100%', fontSize: '14px', lineHeight: '17px' }}
        icon={<InfoIconSvg width='24px' height='24px' fill='#292D32' />}
      >
        {description}
      </Alert>
      {items.map((item, i) => {
        if (item['_destroy']) return null;

        const fieldsWithoutBoolean = defaultFields.filter(
          (df) => df.field_type !== 'boolean'
        );

        const fieldsWithBoolean = defaultFields.filter(
          (df) => df.field_type === 'boolean'
        );

        return (
          <SubItemContainer key={`${item.id}-${i}`}>
            <SubItemInnerContainer>
              <SubItemTopContainer>
                {fieldsWithoutBoolean.map((df) => {
                  const editKeyFieldDisabled =
                    df.internal_name === 'internal_name' &&
                    (template?.custom_fields || []).some(
                      (field: AnyObject) => field.id === item.id
                    );
                  return (
                    <FormField
                      key={`${item.id}-${i}-${df.internal_name}`}
                      value={item[df.internal_name] || ''}
                      label={
                        editKeyFieldDisabled ? internal_name_tooltip : df.label
                      }
                      name={df.internal_name}
                      required={df.required}
                      error={
                        df.internal_name === 'internal_name' &&
                        !isValidKeyInput(item.internal_name)
                          ? localize.templates.form.keyError
                          : ''
                      }
                      fieldType={df.field_type}
                      options={df.options}
                      localizedField={df.localized_field}
                      disabled={item.hidden || editKeyFieldDisabled}
                      onChange={(e) => handleItemInputChange(e, i)}
                    />
                  );
                })}
                {item.field_type === 'select' ? (
                  <FormField
                    value={item['options'] || []}
                    hasChips={true}
                    fieldType='select'
                    label={localize.templates.form.labels.options}
                    required={true}
                    name={'options'}
                    onChange={(e) => handleItemInputChange(e, i)}
                  />
                ) : null}
                {item.field_type === 'static' ? (
                  <FormField
                    key={`${item.id}-${i}-${'default_value'}`}
                    value={item['default_value'] || ''}
                    label={localize.templates.form.labels.defaultValue}
                    name={'default_value'}
                    required={true}
                    fieldType={'string'}
                    onChange={(e) => handleItemInputChange(e, i)}
                  />
                ) : null}
              </SubItemTopContainer>
              <SubItemBottomContainer>
                {fieldsWithBoolean.map((df) => (
                  <FormField
                    key={`${item.id}-${i}-${df.internal_name}`}
                    value={item[df.internal_name] || ''}
                    disabled={item.hidden}
                    label={df.label}
                    name={df.internal_name}
                    required={df.required}
                    fieldType={df.field_type}
                    onChange={(e) => handleItemInputChange(e, i)}
                  />
                ))}
                <Actions
                  className='actions'
                  sx={{
                    justifyContent: mobile ? 'flex-start' : 'flex-end',
                    margin: mobile ? '0' : '20px 0 0 auto',
                    gap: mobile ? '0' : '20px',
                  }}
                >
                  <Button
                    aria-label='copy row'
                    data-cy='template-form-copy-row-btn'
                    variant='transparent'
                    fullWidth={mobile}
                    onClick={(): void => {
                      duplicateItem(i);
                    }}
                    startIcon={<AddIcon />}
                  >
                    {localize.templates.form.duplicate}
                  </Button>

                  {items.filter((item) => !item['_destroy']).length > 1 ? (
                    <Button
                      variant='danger'
                      fullWidth={mobile}
                      data-cy='template-form-delete-row-btn'
                      startIcon={<DeleteIcon fill='#fff' />}
                      disabled={item.internal_name === 'title'}
                      onClick={(): void => {
                        deleteSubItem(i);
                      }}
                    >
                      {localize.general.delete}
                    </Button>
                  ) : null}
                </Actions>
              </SubItemBottomContainer>
            </SubItemInnerContainer>
          </SubItemContainer>
        );
      })}

      <AddSubItemContainer>
        <Button
          aria-label='expand-row'
          data-cy='template-form-expand-row-btn'
          fullWidth={mobile}
          variant='transparent'
          onClick={() =>
            setItems([
              ...items,
              {
                internal_name: generateRandomInternalKey(),
                label: '',
                field_type: 'string',
                id: null,
                required: false,
                hidden: false,
                editable: true,
              },
            ])
          }
          startIcon={<AddIcon />}
        >
          {localize.general.add}
        </Button>
      </AddSubItemContainer>
      {mobile && <Divider sx={{ mt: '20px', borderColor: '#DFDDF9' }} />}
      <Actions>
        <Button
          type='submit'
          data-cy='template-form-submit-btn'
          variant='rounded'
          fullWidth={mobile}
          disabled={submitting || !isFormValid || !hasFormChanged}
        >
          {submitting ? (
            <CircularProgress size={26} thickness={6} color='primary' />
          ) : (
            localize.general.submit
          )}
        </Button>
        <Button
          variant='transparent'
          data-cy='template-form-cancel-btn'
          fullWidth={mobile}
          onClick={() => {
            setItems(template.custom_fields || []);
          }}
          disabled={submitting || !hasFormChanged}
        >
          {localize.general.cancel}
        </Button>
      </Actions>
    </Container>
  );
};

export default TemplateForm;
